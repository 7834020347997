import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_CHARGES,
  LOAD_CHARGES,
  END_LOADING_CHARGES,
  START_ADD_CHARGE,
  ADD_CHARGE_SUCCESS,
  END_ADD_CHARGE,
  START_LOADING_CHARGE_GROUPS,
  LOAD_CHARGE_GROUPS,
  END_LOADING_CHARGE_GROUPS,
  START_ADD_CHARGE_GROUP,
  ADD_CHARGE_GROUP_SUCCESS,
  END_ADD_CHARGE_GROUP,
  START_EDIT_CHARGE_GROUP,
  EDIT_CHARGE_GROUP_SUCCESS,
  END_EDIT_CHARGE_GROUP,
  START_EDIT_CHARGE,
  END_EDIT_CHARGE,
  EDIT_CHARGE_SUCCESS
} from '../constants/chargeConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingCharges = () => ({
  type: START_LOADING_CHARGES
});

const endLoadingCharges = () => ({
  type: END_LOADING_CHARGES
});

const startAddCharge = () => ({
  type: START_ADD_CHARGE
});

const addChargeSuccess = () => ({
  type: ADD_CHARGE_SUCCESS
});

const endAddCharge = () => ({
  type: END_ADD_CHARGE
});

const startEditCharge = () => ({
  type: START_EDIT_CHARGE
});

const EditChargeSuccess = () => ({
  type: EDIT_CHARGE_SUCCESS
});

const endEditCharge = () => ({
  type: END_EDIT_CHARGE
});

const loadCharges = (payload) => ({
  type: LOAD_CHARGES,
  payload
});

const startLoadingChargeGroups = () => ({
  type: START_LOADING_CHARGE_GROUPS
});

const endLoadingChargeGroups = () => ({
  type: END_LOADING_CHARGE_GROUPS
});

const addChargeGroup = () => ({
  type: START_ADD_CHARGE_GROUP
});

const addChargeGroupSuccess = () => ({
  type: ADD_CHARGE_GROUP_SUCCESS
});

const endAddChargeGroup = () => ({
  type: END_ADD_CHARGE_GROUP
});

const loadChargeGroups = (payload) => ({
  type: LOAD_CHARGE_GROUPS,
  payload
});

const startEditChargeGroup = () => ({
  type: START_EDIT_CHARGE_GROUP
});

const EditChargeGroupSuccess = () => ({
  type: EDIT_CHARGE_GROUP_SUCCESS
});

const endEditChargeGroup = () => ({
  type: END_EDIT_CHARGE_GROUP
});

// get charges list
export const getCharges = ({ dispatch }) => {
  dispatch(startLoadingCharges());
  const url = `/core/v1/charges`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingCharges());
      if (!data.data.data) {
        dispatch(loadCharges(data?.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadCharges(data?.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingCharges());
      // eslint-disable-next-line no-unused-expressions
      message?.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create charge
export const addNewCharge = ({ values, dispatch }) => {
  dispatch(startAddCharge());
  const url = `/core/v1/charges/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddCharge());
      dispatch(addSuccessMessage(`Charge added successfully`));
      dispatch(addChargeSuccess());
      getCharges({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddCharge());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update charge
export const updateCharge = ({ values, dispatch, chargeId }) => {
  dispatch(startEditCharge());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId };
  const url = `/core/v1/charges/update/${chargeId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditCharge());
      dispatch(addSuccessMessage('Charge updated successfully'));
      dispatch(EditChargeSuccess());
      getCharges({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditCharge());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get Charge groups
export const getChargeGroups = ({ dispatch }) => {
  dispatch(startLoadingChargeGroups());
  const url = `/core/v1/charge-groups`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingChargeGroups());
      if (!data.data.data) {
        dispatch(loadChargeGroups(data.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadChargeGroups(data.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingChargeGroups());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create charge group
export const addNewChargeGroup = ({ values, dispatch }) => {
  dispatch(addChargeGroup());
  const url = `/core/v1/charge-groups/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddChargeGroup());
      dispatch(addSuccessMessage(`Charge added successfully`));
      dispatch(addChargeGroupSuccess());
      getChargeGroups({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddChargeGroup());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update charge group
export const updateChargeGroup = ({ dispatch, values, chargeGroupId }) => {
  dispatch(startEditChargeGroup());
  const url = `/core/v1/charge-groups/update/${chargeGroupId}`;
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId };
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditChargeGroup());
      dispatch(addSuccessMessage('Charge Group updated successfully'));
      dispatch(EditChargeGroupSuccess());
      getChargeGroups({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditChargeGroup());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
