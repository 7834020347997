import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import FormDialog from '../components/user/Create';
//
import AccessControl from '../components/AccessControl';
import { AntTab, AntTabs, TabPanel } from '../components/CustomTab';
import ActiveUsers from '../components/user/activeUsers';
import InActiveUsers from '../components/user/inActiveUsers';
import PendingApprovalUsers from '../components/user/pendingApprovalUsers';

// ----------------------------------------------------------------------

export default function User() {
  // handle tab toggling
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="User | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <AccessControl resource="FAQS" allowedPermissions="cancreate">
            <FormDialog />
          </AccessControl>
        </Stack>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Active Users" />
          <AntTab label="Inactive Users" />
          <AntTab label="User Updates pending Approval" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <ActiveUsers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InActiveUsers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PendingApprovalUsers />
        </TabPanel>
      </Container>
    </Page>
  );
}
