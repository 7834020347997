import { secureapi } from '../../config/secureapi';
import {
  ADD_ROLE_SUCCESS,
  EDIT_ROLE_SUCCESS,
  START_ADD_ROLE,
  END_ADD_ROLE,
  START_EDIT_ROLE,
  END_EDIT_ROLE,
  START_DELETE_ROLE,
  END_DELETE_ROLE,
  START_LOADING_ROLES,
  LOAD_ROLES,
  END_LOADING_ROLES,
  DELETE_ROLE_SUCCESS
} from '../constants/roleConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startAddRole = () => ({
  type: START_ADD_ROLE
});

const endAddRole = () => ({
  type: END_ADD_ROLE
});

const startEditRole = () => ({
  type: START_EDIT_ROLE
});

const endEditRole = () => ({
  type: END_EDIT_ROLE
});

const startDeleteRole = () => ({
  type: START_DELETE_ROLE
});

const endDeleteRole = () => ({
  type: END_DELETE_ROLE
});
const addRoleSuccess = () => ({
  type: ADD_ROLE_SUCCESS
});
const editRoleSuccess = () => ({
  type: EDIT_ROLE_SUCCESS
});
const deleteRoleSuccess = () => ({
  type: DELETE_ROLE_SUCCESS
});
const startLoadingRoles = () => ({
  type: START_LOADING_ROLES
});

const loadRoles = (payload) => ({
  type: LOAD_ROLES,
  payload
});

const endLoadingRoles = () => ({
  type: END_LOADING_ROLES
});

// get roles list
export const getRoles = ({ dispatch }) => {
  dispatch(startLoadingRoles());
  const url = '/roles/all';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingRoles());
      if (!data.data) {
        dispatch(loadRoles(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadRoles(data.data));
        // dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingRoles());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// adds a new role
export const addNewRole = ({ values, dispatch }) => {
  dispatch(startAddRole());
  const url = '/roles/create';
  const createdBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, isApproved: false, creatorId: createdBy };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddRole());
      dispatch(addSuccessMessage(`Role added successfully`));
      dispatch(addRoleSuccess());
      getRoles({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddRole());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing role

export const updateRole = ({ values, dispatch, roleId }) => {
  dispatch(startEditRole());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy };
  const url = `/roles/update/${roleId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditRole());
      dispatch(addSuccessMessage(`Role updated successfully`));
      dispatch(editRoleSuccess());
      getRoles({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditRole());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const deleteRole = ({ dispatch, roleId }) => {
  dispatch(startDeleteRole());
  const url = `/roles/delete/${roleId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteRole());
      dispatch(addSuccessMessage(`Role Deleted successfully`));
      dispatch(deleteRoleSuccess());
      getRoles({
        dispatch
      });
    })
    .catch(({ message }) => {
      dispatch(endDeleteRole());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
