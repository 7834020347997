import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Grid, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { approveConfigUpdate } from '../../redux/actions/configs';

import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

// to check the correct data types and data is passed
ApproveConfigUpdate.propTypes = {
  configId: PropTypes.string,
  configData: PropTypes.object
};

export default function ApproveConfigUpdate({ configId, configData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateConfigUpdateSchema = Yup.object().shape({
    configName: Yup.string().required('Config Name is required')
  });

  const { isActive, isApproved, configName, value } = configData.pendingUpdateJson;

  const formik = useFormik({
    initialValues: {
      configName,
      value: typeof value === 'string' ? JSON.parse(value) : value,
      isApproved,
      isActive
    },
    validationSchema: UpdateConfigUpdateSchema,
    onSubmit: async (values) => {
      values.value = value;
      values.isApproved = true;
      const response = await approveConfigUpdate({ values, dispatch, configId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckBoxIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Approve Config Update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    fullWidth
                    autoComplete="configName"
                    type="text"
                    margin="normal"
                    label="Config Name"
                    {...getFieldProps('configName')}
                    error={Boolean(touched.configName && errors.configName)}
                    helperText={touched.configName && errors.configName}
                  />
                  <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
                  <JSONInput
                    id="user_json"
                    colors={lightMitsuketaTribute}
                    placeholder={typeof value === 'string' ? JSON.parse(value) : value}
                    confirmGood={false}
                    locale={localeEn}
                    style={{
                      outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                      container: { width: '85%', height: '95%' },
                      contentBox: { color: '7E7E7E' }
                    }}
                    height="250px"
                    onChange={(input) => {
                      setInputValues(input.json);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
