import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_TICKETS,
  LOAD_TICKETS,
  END_LOADING_TICKETS,
  END_EDIT_TICKET,
  START_STATUS_TICKET,
  STATUS_TICKET_SUCCESS,
  END_STATUS_TICKET
} from '../constants/ticketsConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingTickets = () => ({
  type: START_LOADING_TICKETS
});

const loadTickets = (payload, page, pageSize) => ({
  type: LOAD_TICKETS,
  payload: { payload, page, pageSize }
});

const endLoadingTickets = () => ({
  type: END_LOADING_TICKETS
});

const endEditTicket = () => ({
  type: END_EDIT_TICKET
});

const startStatusTicket = () => ({
  type: START_STATUS_TICKET
});

const endStatusTicket = () => ({
  type: END_STATUS_TICKET
});

const statusTicketSuccess = () => ({
  type: STATUS_TICKET_SUCCESS
});

const getRequestParams = ({
  page,
  pageSize,
  ticketGroup,
  sortOrder,
  sortField,
  assignedTo,
  assignedBy
}) => {
  const params = {};

  if (ticketGroup) {
    params.ticketGroup = ticketGroup;
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (pageSize) {
    params.sortOrder = sortOrder;
  }
  if (pageSize) {
    params.sortField = sortField;
  }
  if (assignedTo) {
    params.assignedTo = assignedTo;
  }
  if (assignedBy) {
    params.assignedBy = assignedBy;
  }
  return params;
};
export const getTickets = async ({
  dispatch,
  ticketGroup,
  page,
  pageSize,
  assignedTo,
  assignedBy
}) => {
  dispatch(startLoadingTickets());
  const url = '/tickets';
  const params = getRequestParams({
    ticketGroup,
    page,
    pageSize,
    assignedTo,
    assignedBy
  });

  try {
    const data = await secureapi.get(url, { params });
    dispatch(endLoadingTickets());
    if (!data.data.ticket) {
      dispatch(loadTickets(data.data, page, pageSize));
      dispatch(addWarningMessage('No results found.'));
    } else {
      dispatch(loadTickets(data.data, page, pageSize));
    }
  } catch ({ message }) {
    dispatch(endLoadingTickets());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};

// eslint-disable-next-line consistent-return
export const updateTicketsStatus = async ({ values, dispatch, ticketId }) => {
  dispatch(startStatusTicket());
  const url = `/tickets/update/${ticketId}`;

  try {
    const data = await secureapi.put(url, values);
    dispatch(endStatusTicket());
    dispatch(addSuccessMessage('Ticket updated successfully'));
    dispatch(statusTicketSuccess());
    getTickets({ dispatch, page: 1, pageSize: 10 });
    return data;
  } catch ({ message }) {
    dispatch(endEditTicket());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};

// eslint-disable-next-line consistent-return
export const assignTicket = async ({ values, dispatch, ticketId }) => {
  dispatch(startStatusTicket());
  const url = `/tickets/assign/${ticketId}`;
  values.assignedBy = JSON.parse(localStorage.getItem('profile')).id;
  try {
    const data = await secureapi.put(url, values);
    dispatch(endStatusTicket());
    dispatch(addSuccessMessage('Ticket assigned successfully'));
    dispatch(statusTicketSuccess());
    getTickets({ dispatch, page: 1, pageSize: 10 });
    return data;
  } catch ({ message }) {
    dispatch(endEditTicket());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};
// d9b4e9f3-7033-4533-9dfb-7c314677e20e
