import axios from 'axios';
import authConfig from './authHeader';

// const token = localStorage.getItem('token');
// const config = {
//   baseURL: process.env.REACT_APP_FORTUNE,
//   headers: {
//     'Content-type': 'application/json',
//     Accept: 'application/json'
//   }
// };

const instance = axios.create(authConfig);

class APIError extends Error {
  constructor(statusCode, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.statusCode = statusCode;
    this.message = message;
  }
}

const errorHandler = (err) => {
  try {
    if (err.response) {
      throw new APIError(err.response.status, err.response.data);
    } else if (err.request) {
      throw new APIError(503, err.request.data);
    } else throw APIError(400, err.data.errors);
  } catch (e) {
    return e;
  }
};

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(errorHandler(error))
);

export const openapi = instance;
