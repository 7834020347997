import { Navigate } from 'react-router';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Faq from './pages/Faq';
import User from './pages/User';
import AccessManagement from './pages/AccessManagement';
import Loans from './pages/Loans';
import MemberManagement from './pages/MemberManagement';
import Tickets from './pages/Tickets';
import TransactionManagement from './pages/TransactionManagement';
import NotFound from './pages/Page404';
import PublicFaq from './pages/PublicFaq';
import Settings from './pages/Settings';
import Authorization from './pages/Authorization';
import PasswordReset from './pages/PasswordReset';
import Reports from './pages/Reports';
import Support from './pages/Support';
import ConfigsManagement from './pages/Configs';
import MessageManagement from './pages/MessageManagement';
import BasicReports from './components/reports/BasicReports';
import AdvancedReports from './components/reports/AdvancedReports';
import Merchants from './pages/Merchants';
import PendingResolution from './components/transactions/pending-resolution/pendingResolution';
import AllTransactions from './components/transactions/allTransactions';
import PendingTransactions from './components/transactions/pendingTransactions';

// ----------------------------------------------------------------------

const routes = (isLoggedIn) => [
  {
    path: '/dashboard',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { element: <Navigate to="/dashboard" replace /> },
      { path: '', element: <DashboardApp /> },
      { path: 'transaction-management', element: <TransactionManagement /> },
      { path: 'transaction-management/pending-resolution', element: <PendingResolution /> },
      { path: 'transaction-management/all-transactions', element: <AllTransactions /> },
      { path: 'transaction-management/pending-transactions', element: <PendingTransactions /> },
      { path: 'loans', element: <Loans /> },
      { path: 'member-management', element: <MemberManagement /> },
      { path: 'user', element: <User /> },
      { path: 'access-management', element: <AccessManagement /> },
      { path: 'tickets', element: <Tickets /> },
      { path: 'settings', element: <Settings /> },
      { path: 'products', element: <Products /> },
      { path: 'support-tickets', element: <Support /> },
      { path: 'configs', element: <ConfigsManagement /> },
      { path: 'messages', element: <MessageManagement /> },
      { path: 'faq', element: <Faq /> },
      { path: 'reports', element: <Reports /> },
      { path: 'reports/basic', element: <BasicReports /> },
      { path: 'reports/advanced', element: <AdvancedReports /> },
      { path: 'merchants', element: <Merchants /> }
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'verify-login', element: <Authorization /> },
      { path: 'users/password-reset', element: <PasswordReset /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/public-faq', element: <PublicFaq /> }
    ]
  },
  { path: '*', element: <Navigate to="/404" replace /> }
];

export default routes;
