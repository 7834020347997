export const START_LOADING_PESALINKS = 'START_LOADING_PESALINKS';
export const LOAD_PESALINKS = 'LOAD_PESALINKS';
export const END_LOADING_PESALINKS = 'END_LOADING_PESALINKS';

export const START_EDIT_PESALINK = 'START_EDIT_PESALINK';
export const EDIT_PESALINK_SUCCESS = 'EDIT_PESALINK_SUCCESS';
export const END_EDIT_PESALINK = 'END_EDIT_PESALINK';

export const START_ADD_PESALINK = 'START_ADD_PESALINK';
export const ADD_PESALINK_SUCCESS = 'ADD_PESALINK_SUCCESS';
export const END_ADD_PESALINK = 'END_ADD_PESALINK';

export const START_DELETE_PESALINK = 'START_DELETE_PESALINK';
export const END_DELETE_PESALINK = 'END_DELETE_PESALINK';
export const DELETE_PESALINK_SUCCESS = 'DELETE_PESALINK_SUCCESS';
