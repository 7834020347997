export const START_LOADING_BILLERS = 'START_LOADING_BILLERS';
export const LOAD_BILLERS = 'LOAD_BILLERS';
export const END_LOADING_BILLERS = 'END_LOADING_BILLERS';

export const START_LOADING_BILLER_TYPES = 'START_LOADING_BILLER_TYPES';
export const END_LOADING_BILLER_TYPES = 'END_LOADING_BILLER_TYPES';
export const LOAD_BILLER_TYPES = 'LOAD_BILLER_TYPES';

export const START_ADD_BILLER = 'START_ADD_BILLER';
export const ADD_BILLER_SUCCESS = 'ADD_BILLER_SUCCESS';
export const END_ADD_BILLER = 'END_ADD_BILLER';
