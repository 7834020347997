import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
//
// import { getUsers } from '../redux/actions/user';
import { sentenceCase } from 'change-case';
import { getMembers } from '../../redux/actions/members';
import { getInitials } from '../../utils/get-initials';
import EmptyState from '../EmptyState';
import MemberMoreMenu from '../_dashboard/members/MemberMoreMenu';
import { fDate } from '../../utils/formatTime';
import { MemberListHead, MemberListToolbar } from '../_dashboard/members';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'firstname', label: 'Name', alignRight: false },
  { id: 'phonenumber', label: 'Phone Number', alignRight: false },
  { id: 'nationalid', label: 'National ID', alignRight: false },
  { id: 'customerbranch', label: 'Branch', alignRight: false },
  { id: 'isloanqualified', label: 'Loan Qualified', alignRight: false },
  { id: 'createdat', label: 'Joined On', alignRight: false },
  { id: 'issuspended', label: 'Status', alignRight: false },
  { id: 'devicecheck', label: 'Device Check', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_member) =>
        _member.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.phonenumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.customerbranch.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FailedDeviceCheckMembers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstname');
  // eslint-disable-next-line no-unused-vars
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.membersReducer.isLoading);
  const members = useSelector((state) => state.reducer.membersReducer.membersList);
  const transactions = useSelector((state) => state.reducer.membersReducer.transactionsList);
  const totalMembers = useSelector((state) => state.reducer.membersReducer.count);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = members.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getMembers({
      dispatch,
      deviceCheck: 'FAILED',
      page: newPage + 1,
      pageSize: rowsPerPage
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getMembers({
      dispatch,
      deviceCheck: 'FAILED',
      page: page + 1,
      pageSize: parseInt(event.target.value, 10)
    });
  };
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  const filteredMembers = applySortFilter(members, getComparator(order, orderBy), filterName);

  const isMemberNotFound = filteredMembers.length === 0;

  // to get member list
  useEffect(() => {
    getMembers({
      dispatch,
      deviceCheck: 'FAILED',
      page: 1,
      pageSize: rowsPerPage
    });
  }, [dispatch, rowsPerPage]);

  return (
    <Box>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : members.length < 1 ? (
        <Card>
          <MemberListToolbar
            numSelected={selected.length}
            filterName={filterName}
            pendingUpdateApproval=""
            isSuspended=""
            deviceCheck="FAILED"
          />
          <EmptyState message="No failed device check members found" />
        </Card>
      ) : (
        <Card>
          <MemberListToolbar
            numSelected={selected.length}
            pendingUpdateApproval=""
            isSuspended="true"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MemberListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={members.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredMembers.map((row) => {
                    const {
                      customerid,
                      firstname,
                      lastname,
                      phonenumber,
                      nationalid,
                      createdat,
                      issuspended,
                      customerbranch,
                      isloanqualified,
                      devicecheck
                    } = row;
                    const isItemSelected = selected.indexOf(customerid) !== -1;
                    return (
                      <TableRow
                        hover
                        key={customerid}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, customerid)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={firstname}>{`${getInitials(firstname)}${getInitials(
                              lastname
                            )}`}</Avatar>
                            <Typography variant="subtitle2" noWrap>
                              {`${sentenceCase(firstname)} ${sentenceCase(lastname)}`}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{phonenumber}</TableCell>
                        <TableCell align="left">{nationalid}</TableCell>
                        <TableCell align="left">{customerbranch}</TableCell>
                        <TableCell align="center">
                          {isloanqualified ? (
                            <CheckCircleOutlineIcon fontSize="large" color="success" />
                          ) : (
                            <CancelOutlinedIcon fontSize="large" color="error" />
                          )}
                        </TableCell>
                        {/* <TableCell align="left"> */}
                        {/*  {!issuspended ? ( */}
                        {/*    <CancelOutlinedIcon fontSize="large" color="error" /> */}
                        {/*  ) : ( */}
                        {/*    <CheckCircleOutlineOutlinedIcon */}
                        {/*      fontSize="large" */}
                        {/*      color="secondary" */}
                        {/*    /> */}
                        {/*  )} */}
                        {/* </TableCell> */}
                        <TableCell align="left">{createdat && fDate(createdat)}</TableCell>
                        <TableCell align="left">
                          <Typography color={issuspended ? 'error' : 'secondary'}>
                            {issuspended ? 'Inactive' : 'Active'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{devicecheck}</TableCell>
                        <TableCell align="right">
                          <MemberMoreMenu
                            memberId={customerid}
                            memberData={row}
                            transactions={transactions}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && ( */}
                  {/*  <TableRow style={{ height: 53 * emptyRows }}> */}
                  {/*    <TableCell colSpan={6} /> */}
                  {/*  </TableRow> */}
                  {/* )} */}
                </TableBody>
                {isMemberNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalMembers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Box>
  );
}
