import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import InputAdornment from '@mui/material/InputAdornment';

import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { addNewLoanConfig, getChargeGroups } from '../../redux/actions/loans';

export default function CreateLoanConfig() {
  const dispatch = useDispatch();
  const chargeGroups = useSelector((state) => state.reducer.chargesReducer.chargeGroupList);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const SORT_TYPE = [
    { value: 'MOBILE', label: 'MOBILE' },
    { value: 'SALARY_ADVANCE', label: 'SALARY ADVANCE' },
    { value: 'SHORT_TERM', label: 'SHORT TERM' },
    { value: 'MEDIUM_TERM', label: 'MEDIUM TERM' },
    { value: 'LONG_TERM', label: 'LONG TERM' }
  ];
  const SORT_REPAYMENTPERIOD = [
    { value: 'DAILY', label: 'DAILY' },
    { value: 'WEEKLY', label: 'WEEKLY' },
    { value: 'MONTHLY', label: 'MONTHLY' },
    { value: 'YEARLY', label: 'YEARLY' }
  ];
  const CreateLoanConfigSchema = Yup.object().shape({
    type: Yup.string().required('Loan type is required'),
    name: Yup.string().required('Loan name is required'),
    minAmountAllowed: Yup.number().required('Minimum Amount is required'),
    maxAmountAllowed: Yup.number().required('maximum Amount is required'),
    chargeGroupId: Yup.string().required('charge group is required'),
    repaymentPeriod: Yup.string().required('Repayment period is required'),
    installments: Yup.number().required('installments is required'),
    interestRate: Yup.number().required('Interest Rate is required')
  });

  const formik = useFormik({
    initialValues: {
      type: '',
      name: '',
      minAmountAllowed: 0,
      maxAmountAllowed: 0,
      chargeGroupId: '',
      repaymentPeriod: '',
      installments: 0,
      interestRate: 0
    },
    validationSchema: CreateLoanConfigSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewLoanConfig({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  useEffect(() => {
    getChargeGroups({
      dispatch
    });
  }, []);

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Loan Config
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new Loan Type</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">Loan Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Loan type"
                  {...getFieldProps('type')}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                >
                  {SORT_TYPE.map((type) => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Loan Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                autoComplete="minAmountAllowed"
                type="number"
                margin="normal"
                label="Minimum Limit"
                {...getFieldProps('minAmountAllowed')}
                error={Boolean(touched.minAmountAllowed && errors.minAmountAllowed)}
                helperText={touched.minAmountAllowed && errors.minAmountAllowed}
              />
              <TextField
                fullWidth
                autoComplete="maxAmountAllowed"
                type="number"
                margin="normal"
                label="Maximum Limit"
                {...getFieldProps('maxAmountAllowed')}
                error={Boolean(touched.maxAmountAllowed && errors.maxAmountAllowed)}
                helperText={touched.maxAmountAllowed && errors.maxAmountAllowed}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Charge Group</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('chargeGroupId')}
                  name="chargeGroupId"
                  label="Charge Group"
                >
                  {chargeGroups.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">Repayment Period</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Repayment Period"
                  {...getFieldProps('repaymentPeriod')}
                  error={Boolean(touched.repaymentPeriod && errors.repaymentPeriod)}
                  helperText={touched.repaymentPeriod && errors.repaymentPeriod}
                >
                  {SORT_REPAYMENTPERIOD.map((repaymentPeriod) => (
                    <MenuItem value={repaymentPeriod.value} key={repaymentPeriod.value}>
                      {repaymentPeriod.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="interestRate"
                type="number"
                margin="normal"
                label="Interest Rate"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                {...getFieldProps('interestRate')}
                error={Boolean(touched.interestRate && errors.interestRate)}
                helperText={touched.interestRate && errors.interestRate}
              />
              <TextField
                fullWidth
                autoComplete="installments"
                type="number"
                margin="normal"
                label="Installments"
                {...getFieldProps('installments')}
                error={Boolean(touched.installments && errors.installments)}
                helperText={touched.installments && errors.installments}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
