import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { fDate } from '../../utils/formatTime';
import { secureapi } from '../../config/secureapi';
import EmptyState from '../EmptyState';

TransactionsDetails.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxwidth: '40%',
  margin: 'auto'
}));

export default function TransactionsDetails({ memberId, memberData }) {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let results;

  async function getMemberTransactions() {
    const response = await secureapi(`/core/v1/portal/transfers/transaction/${memberId}`, {});
    if (response) {
      results = response.data.transfers;
      setTransactions(results);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }

  useEffect(() => {
    getMemberTransactions();
  }, [memberId]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account From
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account To
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Amount
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Transaction Description
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Created At
              </TableCell>
            </TableRow>
          </TableHead>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <LinearProgressStyle />
          ) : transactions < 1 ? (
            <EmptyState message="No transactions found" />
          ) : (
            <TableBody>
              {transactions &&
                transactions.map((transaction) => (
                  <TableRow key={transaction?.transferId}>
                    <TableCell align="left">{transaction?.accountFrom}</TableCell>
                    <TableCell align="left">{transaction?.accountTo}</TableCell>
                    <TableCell align="left">{transaction?.amount}</TableCell>
                    <TableCell align="left">{transaction?.transferDescription}</TableCell>
                    <TableCell align="left">{fDate(transaction?.createdAt)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
