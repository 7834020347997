import {
  START_LOADING_TICKETS,
  LOAD_TICKETS,
  END_LOADING_TICKETS,
  START_STATUS_TICKET,
  STATUS_TICKET_SUCCESS,
  END_STATUS_TICKET
} from '../constants/ticketsConstants';

const initialState = {
  isLoading: false,
  isAddTicketLoading: false,
  isEditTicketLoading: false,
  isEditTicketSuccess: false,
  isStatusTicketSuccess: false,
  ticketList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_STATUS_TICKET:
      return { ...state, isStatusTicketLoading: true };
    case END_STATUS_TICKET:
      return { ...state, isStatusTicketLoading: false };
    case STATUS_TICKET_SUCCESS:
      return { ...state, isStatusTicketSuccess: true };
    case START_LOADING_TICKETS:
      return { ...state, isLoading: true };
    case END_LOADING_TICKETS:
      return { ...state, isLoading: false };
    case LOAD_TICKETS:
      return {
        ...state,
        ticketList: action.payload.payload.ticket,
        count: action.payload.payload.ticketCount,
        page: action.payload.page,
        pageSize: action.payload.pageSize
      };
    default:
      return state;
  }
};

export default ticketsReducer;
