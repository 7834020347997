import { secureapi } from '../../config/secureapi';
import {
  ADD_FAQ_SUCCESS,
  EDIT_FAQ_SUCCESS,
  START_ADD_FAQ,
  END_ADD_FAQ,
  START_EDIT_FAQ,
  END_EDIT_FAQ,
  START_DELETE_FAQ,
  END_DELETE_FAQ,
  START_LOADING_FAQ,
  LOAD_FAQ,
  END_LOADING_FAQ,
  DELETE_FAQ_SUCCESS
} from '../constants/faqConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startAddFaq = () => ({
  type: START_ADD_FAQ
});

const endAddFaq = () => ({
  type: END_ADD_FAQ
});

const startEditFaq = () => ({
  type: START_EDIT_FAQ
});

const endEditFaq = () => ({
  type: END_EDIT_FAQ
});

const startDeleteFaq = () => ({
  type: START_DELETE_FAQ
});

const endDeleteFaq = () => ({
  type: END_DELETE_FAQ
});
const addFaqSuccess = () => ({
  type: ADD_FAQ_SUCCESS
});
const editFaqSuccess = () => ({
  type: EDIT_FAQ_SUCCESS
});
const deleteFaqSuccess = () => ({
  type: DELETE_FAQ_SUCCESS
});

const startLoadingFaqs = () => ({
  type: START_LOADING_FAQ
});

const loadFaqs = (payload) => ({
  type: LOAD_FAQ,
  payload
});

const endLoadingFaqs = () => ({
  type: END_LOADING_FAQ
});

const getRequestParams = ({ page, pageSize, category, sortOrder, sortField }) => {
  const params = {};

  if (category) {
    params.category = category;
  }

  if (page) {
    params.pageNumber = page;
  }

  if (pageSize) {
    params.pageLimit = pageSize;
  }
  if (pageSize) {
    params.sortOrder = sortOrder;
  }
  if (pageSize) {
    params.sortField = sortField;
  }
  return params;
};

// get users list
export const getFaqs = ({ dispatch, category }) => {
  dispatch(startLoadingFaqs());
  const params = getRequestParams({
    category
  });
  const url = '/faq';
  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingFaqs());
      if (!data.data) {
        dispatch(loadFaqs(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadFaqs(data.data));
        // dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingFaqs());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// adds a new faq
export const addNewFaq = ({ values, dispatch }) => {
  dispatch(startAddFaq());
  const url = '/faq/create';
  const userId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: userId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddFaq());
      dispatch(addSuccessMessage(`Faq added successfully`));
      dispatch(addFaqSuccess());
      getFaqs({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddFaq());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing faq

export const updateFaq = ({ values, dispatch, faqId }) => {
  dispatch(startEditFaq());
  const url = `/faq/update/${faqId}`;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditFaq());
      dispatch(addSuccessMessage(`Faq updated successfully`));
      dispatch(editFaqSuccess());
      getFaqs({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditFaq());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const deleteFaq = ({ dispatch, faqId }) => {
  dispatch(startDeleteFaq());
  const url = `/faq/delete/${faqId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteFaq());
      dispatch(addSuccessMessage(`Faq Deleted successfully`));
      dispatch(deleteFaqSuccess());
      getFaqs({
        dispatch
      });
    })
    .catch(({ message }) => {
      dispatch(endDeleteFaq());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get faqs list
export const getFaqsByField =
  ({ page, pageSize, searchTitle, sortOrder, sortField, permissionId, superUser, orgId }) =>
  (dispatch) => {
    dispatch(startLoadingFaqs());
    const params = getRequestParams({
      page,
      pageSize,
      searchTitle,
      sortOrder,
      sortField
    });
    let url;
    const isOrgUser = JSON.parse(localStorage.getItem('isOrgFaq'));
    if (isOrgUser) {
      const orgId = JSON.parse(localStorage.getItem('faqProfile')).orgid;
      url = `/users?deleted_at=null&orgid=${orgId}`;
    } else if (superUser) {
      url = '/users?superUser=true';
    } else if (orgId) {
      url = `/users?deleted_at=null&orgid=${orgId}`;
    } else url = '/users?deleted_at=null';
    return secureapi
      .get(url, { params, headers: { permission: permissionId } })
      .then((data) => {
        dispatch(endLoadingFaqs());
        if (!data.data.data) {
          dispatch(loadFaqs(data.data));
          dispatch(addErrorMessage('No results found.'));
        } else {
          dispatch(loadFaqs(data.data));
          dispatch(addSuccessMessage('Successful'));
        }
      })
      .catch(({ message }) => {
        dispatch(endLoadingFaqs());
        // eslint-disable-next-line no-unused-expressions
        message.errorMessage
          ? dispatch(addErrorMessage(message.errorMessage))
          : dispatch(addErrorMessage(message));
      });
  };
