import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
//
import { AntTab, AntTabs, TabPanel } from '../components/CustomTab';
import ActiveMembers from '../components/member/activeMembers';
import PendingApprovalMembers from '../components/member/pendingApprovalMembers';
import InActiveMembers from '../components/member/inActiveMembers';
import FailedDeviceCheckMembers from '../components/member/failedDeviceCheckMembers';
import BlockedMembers from '../components/member/blockedMembers';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function Member() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Members | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Member Management
          </Typography>
        </Stack>
        <AntTabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab label="Active Members" />
          <AntTab label="Inactive Members" />
          <AntTab label="Blocked Members" />
          <AntTab label="Failed Device Check Members" />
          <AntTab label="Member Updates pending Approval" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <ActiveMembers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InActiveMembers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BlockedMembers />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FailedDeviceCheckMembers />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PendingApprovalMembers />
        </TabPanel>
      </Container>
    </Page>
  );
}
