import { useState } from 'react';
// material
import { Container, Typography } from '@mui/material';
// components
import Page from '../Page';

//
import { SecondaryTab, SecondaryTabs, TabPanel } from '../CustomTab';
import MemberReports from './MemberReports';
import TransactionReports from './TransactionReports';

// ----------------------------------------------------------------------

export default function BasicReports() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Basic Reports | Fortune Sacco">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Basic Reports
        </Typography>

        <SecondaryTabs value={value} onChange={handleChange} aria-label="ant example">
          <SecondaryTab label="Transaction Management" />
          <SecondaryTab label="Member Management" />
        </SecondaryTabs>
        <TabPanel value={value} index={0}>
          <TransactionReports />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MemberReports />
        </TabPanel>
      </Container>
    </Page>
  );
}
