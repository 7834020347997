import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ChangeSupportStatus from '../../support/ChangeSupportStatus';
import ViewSupport from '../../support/View';
import AccessControl from '../../AccessControl';
import AssignSupport from '../../support/AssignSupport';
import DeleteSupport from '../../support/Delete';

SupportMoreMenu.propTypes = {
  supportId: PropTypes.string,
  supportData: PropTypes.object,
  profileId: PropTypes.string
};

export default function SupportMoreMenu({ supportId, supportData, profileId }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 250, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="SUPPORT" allowedPermissions="canread">
          <ViewSupport supportId={supportId} supportData={supportData} />
        </AccessControl>
        {(supportData && !supportData.assignedto) || supportData.assignedby === profileId ? (
          <AssignSupport supportId={supportId} supportData={supportData} profileId={profileId} />
        ) : (
          ''
        )}
        <AccessControl resource="SUPPORT" allowedPermissions="canupdate">
          {/* eslint-disable-next-line no-nested-ternary */}
          {supportData?.resolved ? (
            ''
          ) : supportData?.assignedto === profileId ? (
            <ChangeSupportStatus supportId={supportId} supportData={supportData} />
          ) : (
            ''
          )}
        </AccessControl>
        <AccessControl resource="SUPPORT" allowedPermissions="candelete">
          <DeleteSupport supportId={supportId} />
        </AccessControl>
      </Menu>
    </>
  );
}
