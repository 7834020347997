import { useState, useEffect } from 'react';
// material
import { LinearProgress, Box, Chip, Stack, IconButton } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
//
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { fDate } from '../../utils/formatTime';
import Scrollbar from '../Scrollbar';
import { getDownloadMemberReports, getMemberReports } from '../../redux/actions/reports';

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

const StyledTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  // height: 400,
  border: '1px solid',
  borderColor: `${theme.palette.primary.lighter} !important`,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));
// ----------------------------------------------------------------------
export default function MemberReports() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.reportsReducer.isLoading);
  const membersReportList = useSelector((state) => state.reducer.reportsReducer.membersReportList);
  const reportCount = useSelector((state) => state.reducer.reportsReducer.memberReportCount);
  const [createDateFrom, setCreateDateFrom] = useState(null);
  const [createDateTo, setCreateDateTo] = useState(null);
  const [updateDateFrom, setUpdateDateFrom] = useState(null);
  const [updateDateTo, setUpdateDateTo] = useState(null);
  const [downloadFilters, setDownloadFilters] = useState({});
  const handleChangePage = (newPage) => {
    setPage(newPage);
    getMemberReports({
      dispatch,
      page: newPage + 1,
      pageSize: rowsPerPage,
      ...downloadFilters
    });
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(parseInt(rowsPerPage, 10));
    getMemberReports({
      dispatch,
      page: page + 1,
      pageSize: parseInt(rowsPerPage, 10),
      ...downloadFilters
    });
  };
  //-------------------------------------------------
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    const filterList = {};
    // eslint-disable-next-line array-callback-return
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === 'createdAt') {
        newFilter = { createDateFrom: item[0], createDateTo: item[1] };
      } else if (key === 'updatedAt') {
        newFilter = { updateDateFrom: item[0], updateDateTo: item[1] };
      } else {
        newFilter = { [key]: item[0] };
      }
      Object.assign(filterList, newFilter);
    });
    setDownloadFilters(filterList);
    getMemberReports({
      dispatch,
      page: 1,
      pageSize: rowsPerPage,
      ...filterList
    });
  };
  // ---------------------------handle file download --------------------
  const handleReportDownload = async () => {
    await getDownloadMemberReports({ dispatch, ...downloadFilters });
  };
  const DownloadAllButton = () => (
    <>
      <IconButton
        color="primary"
        aria-label="Download all"
        component="label"
        onClick={handleReportDownload}
      >
        <CloudDownloadOutlinedIcon fontSize="medium" />
      </IconButton>
    </>
  );
  // ----------------------------------------------------------------------
  const columns = [
    { name: 'firstName', label: 'First Name' },
    {
      name: 'lastName',
      label: 'Last Name'
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number'
    },
    {
      name: 'nationalId',
      label: 'National ID'
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        filter: true,
        filterType: 'dropdown'
      }
    },
    {
      name: 'isRegistrationComplete',
      label: 'Completed Registration',
      options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['true', 'false'],
          logic(isRegistrationComplete, filterVal) {
            const show =
              (filterVal.indexOf('true') >= 0 &&
                (isRegistrationComplete === true || isRegistrationComplete === 'true')) ||
              (filterVal.indexOf('false') >= 0 &&
                (isRegistrationComplete === false || isRegistrationComplete === 'false'));
            return !show;
          }
        },

        customBodyRenderLite: (dataIndex) =>
          membersReportList[dataIndex].isRegistrationComplete ? (
            <Chip label="Complete" color="success" />
          ) : (
            <Chip label="InComplete" color="error" />
          )
      }
    },
    {
      name: 'isSuspended',
      label: 'Is Suspended',
      options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['true', 'false'],
          logic(isSuspended, filterVal) {
            const show =
              (filterVal.indexOf('true') >= 0 &&
                (isSuspended === true || isSuspended === 'true')) ||
              (filterVal.indexOf('false') >= 0 &&
                (isSuspended === false || isSuspended === 'false'));
            return !show;
          }
        },

        customBodyRenderLite: (dataIndex) =>
          membersReportList[dataIndex].isSuspended ? (
            <Chip label="Suspended" color="error" />
          ) : (
            <Chip label="Active" color="success" />
          )
      }
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`Created From: ${v[0]}`, `Created To: ${v[1]}`];
            }
            if (v[0]) {
              return `Created From: ${v[0]}`;
            }
            if (v[1]) {
              return `Created To: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log('customFilterListOnDelete: ', filterList, filterPos, index);

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          names: [],
          logic(createdAt, filters) {
            if (filters[0] && filters[1]) {
              return createdAt < filters[0] || createdAt > filters[1];
            }
            if (filters[0]) {
              return createdAt < filters[0];
            }
            if (filters[1]) {
              return createdAt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created From:"
                  value={createDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setCreateDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created To:"
                  value={createDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setCreateDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          )
        },
        customBodyRenderLite: (dataIndex) => fDate(membersReportList[dataIndex].createdAt)
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`Updated From: ${v[0]}`, `Updated To: ${v[1]}`];
            }
            if (v[0]) {
              return `Updated From: ${v[0]}`;
            }
            if (v[1]) {
              return `Updated To: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log('customFilterListOnDelete: ', filterList, filterPos, index);

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          names: [],
          logic(updatedAt, filters) {
            if (filters[0] && filters[1]) {
              return updatedAt < filters[0] || updatedAt > filters[1];
            }
            if (filters[0]) {
              return updatedAt < filters[0];
            }
            if (filters[1]) {
              return updatedAt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated From:"
                  value={updateDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setUpdateDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated To:"
                  value={updateDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setUpdateDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          )
        },
        customBodyRenderLite: (dataIndex) => fDate(membersReportList[dataIndex].updatedAt)
      }
    }
  ];
  const options = {
    filter: true, // show the filter icon in the toolbar (true by default)
    filterType: 'textField',
    responsive: 'standard',
    serverSide: true,
    count: reportCount,
    rowsPerPage,
    elevation: 4,
    rowsPerPageOptions: [15, 30, 60],
    confirmFilters: true,
    download: false,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: '40px' }}>
        <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
          Apply Filters
        </Button>
      </Box>
    ),

    customToolbar: () => <DownloadAllButton />,
    // callback that gets executed when filters are confirmed
    onFilterConfirm: () => {
      console.log('onFilterConfirm');
    },

    onFilterDialogOpen: () => {
      console.log('filter dialog opened');
    },
    onFilterDialogClose: () => {
      console.log('filter dialog closed');
    },
    onFilterChange: (column, filterList, type) => {
      if (type === 'chip') {
        console.log('updating filters via chip');
        // handleFilters(newFilters);
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log('action not handled.');
      }
    }
  };
  // to get transaction list
  useEffect(() => {
    getMemberReports({
      dispatch,
      page: 1,
      pageSize: rowsPerPage
    });
  }, [dispatch, rowsPerPage]);

  return (
    <Box style={{ marginTop: 60 }}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : membersReportList && membersReportList.length < 1 ? (
        <Scrollbar>
          <StyledTableContainer>
            <MUIDataTable
              title="Member Reports"
              data={membersReportList}
              columns={columns}
              options={options}
            />
          </StyledTableContainer>
        </Scrollbar>
      ) : (
        membersReportList && (
          <Scrollbar>
            <StyledTableContainer>
              <MUIDataTable
                title="Member Reports"
                data={membersReportList}
                columns={columns}
                options={options}
              />
            </StyledTableContainer>
          </Scrollbar>
        )
      )}
    </Box>
  );
}
