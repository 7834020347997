import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import UpdateUser from '../../user/Update';
import DeleteUser from '../../user/Delete';
import ApproveUser from '../../user/Approve';
import AccessControl from '../../AccessControl';
import ViewUser from '../../user/viewUser';
import RequestPasswordResetForm from '../../user/RequestPasswordReset';

// ----------------------------------------------------------------------
UserMoreMenu.propTypes = {
  userData: PropTypes.object,
  id: PropTypes.string,
  activeUsers: PropTypes.bool
};

export default function UserMoreMenu({ userData, id, activeUsers }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="USERS" allowedPermissions="canread">
          <ViewUser userData={userData} />
        </AccessControl>
        <AccessControl resource="USERS" allowedPermissions="canupdate">
          <UpdateUser id={id} userData={userData} />
          <RequestPasswordResetForm id={id} userData={userData} />
          <ApproveUser id={id} userData={userData} activeUsers={activeUsers} />
        </AccessControl>
        <AccessControl resource="USERS" allowedPermissions="candelete">
          <DeleteUser id={id} userData={userData} />
        </AccessControl>
      </Menu>
    </>
  );
}
