import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import PropTypes from 'prop-types';
import { processTransaction } from '../../redux/actions/transactions';

ProcessFloatTransaction.propTypes = {
  transferId: PropTypes.string,
  transferData: PropTypes.object
};

export default function ProcessFloatTransaction({ transferData, transferId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isLoading = useSelector(
    (state) => state.reducer.transactionsReducer.isProcessingTransaction
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: [],
    onSubmit: async () => {
      console.log('SUBMIT TRXNSSFSF');
      const response = await processTransaction({ transferId, dispatch });
      if (response.status === 200) {
        handleClose();
      }
    }
  });
  const { handleSubmit } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          {transferData.payment.status !== 'SUCCESS' ? (
            <MemoryOutlinedIcon width={24} height={24} />
          ) : null}
        </ListItemIcon>
        <ListItemText primary="Process" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Process Float Transaction</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                Float transaction
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {' '}
                  {transferData.transferReference}{' '}
                </span>
                will be processed manually.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button type="submit" color="success" disabled={isLoading}>
                Process
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
