import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EmptyState from '../EmptyState';
import { getAccounts, getCorporateAccounts } from '../../redux/actions/members';
import AccountsMoreMenu from './AccountsMoreMenu';

AccountsDetails.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxwidth: '40%',
  margin: 'auto'
}));

export default function AccountsDetails({ memberId, memberData }) {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.reducer.membersReducer.accountsList) || [];
  const corporateAccounts = useSelector(
    (state) => state.reducer.membersReducer.corporateAccountsList
  );
  const isLoadingAccounts = useSelector((state) => state.reducer.membersReducer.isAccounts);

  const filteredCorpAccounts = corporateAccounts.filter((acc) => acc.pendingUpdateJson !== null);

  const allAccounts = accounts.concat(filteredCorpAccounts);

  useEffect(() => {
    getAccounts({ dispatch, memberId });
    getCorporateAccounts({ dispatch, memberId });
  }, [dispatch, memberId]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Number
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Description
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Branch
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Type
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Status
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Withdrawable
              </TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoadingAccounts ? (
            <LinearProgressStyle />
          ) : allAccounts.length < 1 ? (
            <EmptyState message="No accounts available" />
          ) : (
            <TableBody>
              {allAccounts &&
                allAccounts.map((account) => (
                  <TableRow key={account?.accountNumber}>
                    <TableCell align="left">{account?.accountNumber}</TableCell>
                    <TableCell align="left">{account?.accountDescription}</TableCell>
                    <TableCell align="left">{account?.branch}</TableCell>
                    <TableCell align="left">{account?.accountType}</TableCell>
                    <TableCell align="left">{account?.accountStatus}</TableCell>
                    <TableCell align="left">
                      {account?.withDrawableFlag === 'W' ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell align="left">
                      {account?.pendingUpdateJson ? (
                        <AccountsMoreMenu
                          accountNumber={account?.accountNumber}
                          memberData={memberData}
                          accountData={account}
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
