import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Checkbox,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { LoanListHead, LoanListToolbar } from '../_dashboard/loans';
//
// import { getUsers } from '../redux/actions/user';
import { getLoans } from '../../redux/actions/loans';
import EmptyState from '../EmptyState';
import LoanMoreMenu from '../_dashboard/loans/LoanMoreMenu';
import { fDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'recipientPhone', label: 'Phone Number', alignRight: false },
  { id: 'principalAmount', label: 'Principal Amount', alignRight: false },
  { id: 'interest', label: 'Interest', alignRight: false },
  { id: 'amountRepaid', label: 'Amount Repaid', alignRight: false },
  { id: 'expectedRepaymentDate', label: 'Repayment Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'isOverdue', label: 'Overdue', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_loan) =>
        _loan.recipientPhone || _loan.amountRepaid || _loan.principalAmount || _loan.lastRepaid
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function Loans() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstname');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.loansReducer.isLoadingLoans);
  const loans = useSelector((state) => state.reducer.loansReducer.loansList);
  const loansCount = useSelector((state) => state.reducer.loansReducer.count);

  console.log('LOANS >>> ', loans);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(0);

    fetchFilteredLoans({
      page: 0,
      pageSize: rowsPerPage,
      searchValue: filterName,
      status: event.target.value
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = loans.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    fetchFilteredLoans({
      page: newPage,
      pageSize: rowsPerPage,
      searchValue: filterName,
      status: selectedStatus
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);

    setRowsPerPage(perPage);
    setPage(0);

    fetchFilteredLoans({
      page: 0,
      pageSize: perPage,
      searchValue: filterName,
      status: selectedStatus
    });
  };

  const handleSearchChange = (event, status) => {
    setFilterName(event);
    setPage(0);

    fetchFilteredLoans({
      page: 0,
      pageSize: rowsPerPage,
      searchValue: event,
      status
    });
  };

  const handleClearFilters = () => {
    setSelectedStatus('');
    setFilterName('');
    setPage(0);

    fetchFilteredLoans({
      page: 0,
      pageSize: rowsPerPage,
      searchValue: '',
      status: ''
    });
  };

  const fetchFilteredLoans = ({ page, pageSize, searchValue, status }) => {
    getLoans({
      dispatch,
      page: page + 1,
      pageSize,
      type: 'd11b9c2a-354a-427c-877b-3ee03037b70b',
      status,
      searchValue
    });
  };

  const filteredLoans = applySortFilter(loans, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getLoans({
      dispatch,
      type: 'd11b9c2a-354a-427c-877b-3ee03037b70b',
      status: selectedStatus,
      pageSize: rowsPerPage
    });
  }, []);

  return (
    <Page title="Loans | Fortune Sacco">
      <Container maxWidth={false}>
        <LoanListToolbar
          numSelected={selected.length}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
          handleSearchChange={handleSearchChange}
          handleClearFilters={handleClearFilters}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : loans?.length < 1 ? (
          <EmptyState message="No loans available" />
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <LoanListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={loans?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredLoans.map((row) => {
                      const {
                        id,
                        recipientPhone,
                        principalAmount,
                        interest,
                        amountRepaid,
                        expectedRepaymentDate,
                        status,
                        createdAt
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell align="left">{recipientPhone}</TableCell>
                          <TableCell align="left">{principalAmount}</TableCell>
                          <TableCell align="left">{interest}</TableCell>
                          <TableCell align="left">{amountRepaid}</TableCell>
                          <TableCell align="left">
                            {expectedRepaymentDate && fDate(expectedRepaymentDate)}
                          </TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="left">
                            {expectedRepaymentDate &&
                            status !== 'FULLY_PAID' &&
                            new Date().getTime() > new Date(expectedRepaymentDate).getTime() ? (
                              <Typography color="error">Yes</Typography>
                            ) : (
                              <Typography color="secondary">No</Typography>
                            )}
                          </TableCell>
                          <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                          <TableCell align="right">
                            <LoanMoreMenu loanId={id} loanData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {isLoanNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={loansCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
