import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import EmptyState from '../EmptyState';
import { getAccounts, getTransactions } from '../../redux/actions/members';
import { fDate } from '../../utils/formatTime';
import { secureapi } from '../../config/secureapi';

DeviceInformation.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxwidth: '40%',
  margin: 'auto'
}));

export default function DeviceInformation({ memberId, memberData }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.reducer.membersReducer.accountsList);
  const isLoadingAccounts = useSelector((state) => state.reducer.membersReducer.isAccounts);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [transactions, setTransactions] = useState([]);
  let results;
  async function getMemberTransactions() {
    const response = await secureapi(`/core/v1/portal/transfers/transaction/${memberId}`, {});
    results = response.data.transfers;
    setTransactions(results);
  }
  useEffect(() => {
    getAccounts({ dispatch, memberId });
    getMemberTransactions();
  }, [dispatch, memberId]);

  return (
    <>
      <Paper
        sx={{ width: '70%', paddingLeft: 3, marginRight: 4, paddingBottom: '1rem' }}
        elevation={4}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
            Member Device Information
          </Typography>
        </Box>
        <Box display="flex">
          <Typography variant="body2">
            {(memberData.deviceinfo && memberData.deviceinfo.replaceAll(',', '\n')) || ''}
          </Typography>
        </Box>
      </Paper>
    </>
  );
}
