import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { updateConfig } from '../../redux/actions/configs';

import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

UpdateConfig.propTypes = {
  configId: PropTypes.string,
  configData: PropTypes.object
};

export default function UpdateConfig({ configId, configData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateConfigSchema = Yup.object().shape({
    configName: Yup.string().required('Config Name is required')
  });

  const formik = useFormik({
    initialValues: {
      configName: configData.configName,
      value: typeof configData.value === 'string' ? JSON.parse(configData.value) : configData.value,
      isApproved: configData.isApproved,
      isActive: configData.isActive
    },
    validationSchema: UpdateConfigSchema,
    onSubmit: async (values, { resetForm }) => {
      values.value = inputValues;
      const response = await updateConfig({ values, dispatch, configId });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update Config</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="configName"
                type="text"
                margin="normal"
                label="Config Name"
                {...getFieldProps('configName')}
                error={Boolean(touched.configName && errors.configName)}
                helperText={touched.configName && errors.configName}
              />
              <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
              <JSONInput
                id="user_json"
                colors={lightMitsuketaTribute}
                placeholder={
                  typeof configData.value === 'string'
                    ? JSON.parse(configData.value)
                    : configData.value
                }
                confirmGood={false}
                locale={localeEn}
                style={{
                  outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                  container: { width: '85%', height: '95%' },
                  contentBox: { color: '7E7E7E' }
                }}
                height="250px"
                onChange={(input) => {
                  setInputValues(input.json);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
