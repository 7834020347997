import {
  START_LOADING_ADVANCES,
  LOAD_ADVANCES,
  END_LOADING_ADVANCES,
  START_EDIT_ADVANCE,
  EDIT_ADVANCE_SUCCESS,
  END_EDIT_ADVANCE
} from '../constants/salaryAdvanceConstants';

const initialState = {
  isLoadingAdvanceLoans: false,
  isEditAdvanceLoanLoading: false,
  isEditAdvanceLoanSuccess: false,
  advanceLoansList: [],
  sortOrder: 1,
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  searchValue: 'Search Advance Loan...'
};

const salaryAdvanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_ADVANCES:
      return { ...state, isLoadingAdvanceLoans: true };
    case END_LOADING_ADVANCES:
      return { ...state, isLoadingAdvanceLoans: false };
    case LOAD_ADVANCES:
      return { ...state, advanceLoansList: action.payload.data, count: action.payload.count };
    default:
      return state;
  }
};

export default salaryAdvanceReducer;
