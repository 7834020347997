import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, Box, Modal, Typography } from '@mui/material';
import { fDate } from '../../utils/formatTime';

ViewCharge.propTypes = {
  chargeData: PropTypes.object
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  borderRadius: '7px',
  boxShadow: 24,
  backgroundColor: 'white',
  paddingBottom: '1.5rem'
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: '1.5rem'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

export default function ViewCharge({ chargeData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Modal open={open} onClose={handleClose} sx={{ width: '90%' }}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: 'center', marginTop: '1rem' }}
          >
            View Charge
          </Typography>
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Charge Name:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.chargeGroupId.name}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Amount From:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.amountFrom}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Amount To:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.amountTo}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Sacco Charge:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.saccoCharge}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Excise Duty:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.exciseDutyCharge}</p>
            </div>
          </div>
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Created At:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{fDate(chargeData?.createdAt)}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Approved:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.isApproved ? 'Yes' : 'No'}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Updated At:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{fDate(chargeData?.updatedAt)}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Deleted:</Typography>
              <p style={{ paddingLeft: '1rem' }}>{chargeData?.isDeleted ? 'Yes' : 'No'}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
