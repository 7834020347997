import {
  START_LOADING,
  END_LOADING,
  ADD_FAQ_SUCCESS,
  START_ADD_FAQ,
  END_ADD_FAQ,
  START_EDIT_FAQ,
  EDIT_FAQ_SUCCESS,
  END_EDIT_FAQ,
  START_DELETE_FAQ,
  END_DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  START_LOADING_FAQ,
  LOAD_FAQ,
  END_LOADING_FAQ,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_SUCCESS
} from '../constants/faqConstants';

const initialState = {
  isLoading: false,
  isAddFaqSuccess: false,
  isAddFaqLoading: false,
  isEditFaqLoading: false,
  isEditFaqSuccess: false,
  isDeleteFaqSuccess: false,
  faqsList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const faqsReducer = (state = initialState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case START_ADD_FAQ:
      return { ...state, isAddFaqLoading: true };
    case END_ADD_FAQ:
      return { ...state, isAddFaqLoading: false };
    case START_EDIT_FAQ:
      return { ...state, isEditFaqLoading: true };
    case END_EDIT_FAQ:
      return { ...state, isEditFaqLoading: false };
    case START_DELETE_FAQ:
      return { ...state, isDeleteFaqLoading: true };
    case END_DELETE_FAQ:
      return { ...state, isDeleteFaqLoading: false };
    case START_LOADING:
      return { ...state, isLoading: true };
    case ADD_FAQ_SUCCESS:
      return { ...state, isAddFaqSuccess: true };
    case EDIT_FAQ_SUCCESS:
      return { ...state, isEditFaqSuccess: true };
    case DELETE_FAQ_SUCCESS:
      return { ...state, isDeleteFaqSuccess: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_FAQ:
      return { ...state, isLoading: true };

    case LOAD_FAQ:
      return {
        ...state,
        faqsList: action.payload.faq
        // count: action.payload.data.length
        // page: action.payload.pagination.pageNumber,
        // pageSize: action.payload.pagination.pageLimit,
        // totalPages: action.payload.pagination.pageCount
        // sortOrder: action.payload.sort.order,
        // sortField: action.payload.sort.field
      };

    case END_LOADING_FAQ:
      return { ...state, isLoading: false };

    case RESET_PASSWORD_EMAIL_SENT:
      return { ...state, isResetEmailSent: true };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, isPassReset: true };
    default:
      return state;
  }
};

export default faqsReducer;
