import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Paper
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import {
  ChargeGroupsListHead,
  ChargeGroupsListToolbar,
  ChargeGroupsMoreMenu
} from '../_dashboard/chargeGroups';
// import FormDialog from './Create';
//
import { getChargeGroups } from '../../redux/actions/charges';
import EmptyState from '../EmptyState';
import { fDate } from '../../utils/formatTime';
import AccessControl from '../AccessControl';
import FormDialog from './Create';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'createdby', label: 'Created By', alignRight: false },
  { id: 'createdat', label: 'Created At', alignRight: false },
  { id: 'isapproved', label: 'Approved', alignRight: false },
  { id: 'approvedby', label: 'Approved By', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_chargeGroup) => _chargeGroup.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const ChargeGroups = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.chargesReducer.isLoadingChargeGroups);
  const chargeGroups = useSelector((state) => state.reducer.chargesReducer.chargeGroupList);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = chargeGroups.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - chargeGroups.length) : 0;

  const filteredChargeGroups = applySortFilter(
    chargeGroups,
    getComparator(order, orderBy),
    filterName
  );

  const isRoleNotFound = filteredChargeGroups.length === 0;
  useEffect(() => {
    getChargeGroups({
      dispatch
    });
  }, []);
  return (
    <Paper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} mt={3}>
        <Typography variant="h4" gutterBottom />
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="cancreate">
          <FormDialog />
        </AccessControl>
      </Stack>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : chargeGroups.length < 1 ? (
        <EmptyState message="No charge groups available" />
      ) : (
        <Card>
          <ChargeGroupsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ChargeGroupsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={chargeGroups.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredChargeGroups
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, createdby, name, approvedby, isapproved, createdat } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{createdby}</TableCell>
                          <TableCell align="left">{createdat && fDate(createdat)}</TableCell>
                          <TableCell align="left">
                            <Typography color={isapproved ? 'secondary' : 'error'}>
                              {isapproved ? 'Yes' : 'No'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{approvedby}</TableCell>
                          <TableCell align="right">
                            <ChargeGroupsMoreMenu chargeGroupId={id} chargeGroupData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isRoleNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={chargeGroups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Paper>
  );
};
export default ChargeGroups;
