import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fDate } from '../../utils/formatTime';

ViewSupportTicket.propTypes = {
  supportData: PropTypes.object
};

export default function ViewSupportTicket({ supportData }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>View Support Ticket</DialogTitle>
        <DialogContent>
          <Typography>
            Ticket Reference:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>{supportData.code}</span>{' '}
          </Typography>
          <Typography>
            Ticket Group:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>{supportData.ticketgroup}</span>{' '}
          </Typography>
          <Typography>
            Raised by:
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${supportData.createdby && sentenceCase(supportData.createdby)} of phone number ${
                supportData.creatorphone && supportData.creatorphone
              }`}
            </span>{' '}
          </Typography>
          <Typography>
            Description:
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${sentenceCase(supportData.ticketdescription)}`}
            </span>{' '}
          </Typography>
          <Typography>
            Resolved By:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${sentenceCase(
                supportData.resolvedby ? supportData.resolvedby : 'Not resolved yet'
              )}`}
            </span>{' '}
          </Typography>
          <Typography>
            Assigned By:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${sentenceCase(
                supportData.assignedby ? supportData.assignername : 'Not Assigned yet'
              )}`}
            </span>{' '}
          </Typography>
          <Typography>
            Assigned To:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${sentenceCase(
                supportData.assignedto ? supportData.assigneename : 'Not Assigned yet'
              )}`}
            </span>{' '}
          </Typography>
          <Typography>
            Assigned At:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {supportData.assignedto ? fDate(supportData.assignedat) : 'Not Assigned yet'}
            </span>{' '}
          </Typography>
          <Typography>
            Comments:{' '}
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {`${sentenceCase(supportData.comments ? supportData.comments : 'Not resolved yet')}`}
            </span>{' '}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
