import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import UpdateExternalBiller from '../../externalBillers/updateExternalBillers';
import DeleteExternalBiller from '../../externalBillers/deleteExternalBiller';

// ----------------------------------------------------------------------
ExternalBillersMoreMenu.propTypes = {
  externalBillerId: PropTypes.string,
  externalBillerData: PropTypes.object
};

export default function ExternalBillersMoreMenu({ externalBillerId, externalBillerData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canupdate">
          <UpdateExternalBiller
            externalBillerId={externalBillerId}
            externalBillerData={externalBillerData}
          />
          <DeleteExternalBiller
            externalBillerId={externalBillerId}
            externalBillerData={externalBillerData}
          />
        </AccessControl>
      </Menu>
    </>
  );
}
