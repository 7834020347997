import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormControl } from '@mui/material';
import { addNewSysConfig } from '../../redux/actions/system-configs';

export default function CreateSysConfig() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const user = localStorage.getItem('userId');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CreateSysConfigSchema = Yup.object().shape({
    key: Yup.string().required('Config key is required'),
    value: Yup.string().required('Config value is required'),
    description: Yup.string().required('Config description is required')
  });

  const formik = useFormik({
    initialValues: {
      key: '',
      value: '',
      description: ''
    },
    validationSchema: CreateSysConfigSchema,
    onSubmit: async (values, { resetForm }) => {
      values.createdBy = user;
      values.createdAt = new Date();
      values.type = '';

      const response = await addNewSysConfig({ values, dispatch });
      console.log('RESPONSE >>> ', response);

      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New System Config
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new system config</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  fullWidth
                  autoComplete="key"
                  type="text"
                  margin="normal"
                  label="Config Key"
                  {...getFieldProps('key')}
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                />
              </FormControl>
              <TextField
                fullWidth
                autoComplete="value"
                type="text"
                margin="normal"
                label="Config Value"
                {...getFieldProps('value')}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />
              <TextField
                fullWidth
                autoComplete="description"
                type="text"
                margin="normal"
                label="Config Description"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
