export const START_LOADING_BRANCHES = 'START_LOADING_BRANCHES';
export const LOAD_BRANCHES = 'LOAD_BRANCHES';
export const END_LOADING_BRANCHES = 'END_LOADING_BRANCHES';

export const START_EDIT_BRANCH = 'START_EDIT_BRANCH';
export const EDIT_BRANCH_SUCCESS = 'EDIT_BRANCH_SUCCESS';
export const END_EDIT_BRANCH = 'END_EDIT_BRANCH';

export const START_ADD_BRANCH = 'START_ADD_BRANCH';
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS';
export const END_ADD_BRANCH = 'END_ADD_BRANCH';
