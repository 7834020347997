import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Checkbox,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
//
import EmptyState from '../EmptyState';
import { getLoansConfig } from '../../redux/actions/loans';
import { LoanListHead, LoanListToolbar, LoanMenu } from '../_dashboard/loanConfigs';
import CreateLoanConfig from './Create';
import AccessControl from '../AccessControl';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'type', label: 'Type of Loan', alignRight: false },
  { id: 'name', label: 'Loan Name', alignRight: false },
  { id: 'minAmountAllowed', label: 'Minimum Limit', alignRight: false },
  { id: 'maxAmountAllowed', label: 'Maximum Limit', alignRight: false },
  { id: 'chargeGroupId', label: 'Charge Type', alignRight: false },
  { id: 'repaymentPeriod', label: 'Repayment Period', alignRight: false },
  { id: 'interestRate', label: 'Interest Rate', alignRight: false },
  { id: 'penalty Rate', label: 'Penalty Rate', alignRight: false },
  { id: 'installments', label: 'Installments', alignRight: false },
  { id: 'isActive', label: 'Status', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_loan) => _loan.type.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const LoanConfigs = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('type');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.loanConfigsReducer.isLoadingLoanTypes);
  const loans = useSelector((state) => state.reducer.loanConfigsReducer.loanTypesList);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  console.log('LOANS CONF:::', loans);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = loans.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loans.length) : 0;

  const isLoanNotFound = loans.length === 0;
  useEffect(() => {
    getLoansConfig({
      dispatch
    });
  }, [dispatch]);
  return (
    <Paper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} mt={3}>
        <Typography variant="h4" gutterBottom />
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="cancreate">
          <CreateLoanConfig />
        </AccessControl>
      </Stack>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : loans.length < 1 ? (
        <EmptyState message="No loans available" />
      ) : (
        <Card>
          <LoanListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LoanListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={loans.length}
                  numSelected={selected.length}
                  M
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {loans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      type,
                      name,
                      minAmountAllowed,
                      maxAmountAllowed,
                      chargeGroupId,
                      repaymentPeriod,
                      interestRate,
                      penaltyRate,
                      installments,
                      isActive
                    } = row;
                    const isItemSelected = selected.indexOf(type) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, type)}
                          />
                        </TableCell>
                        <TableCell align="left">{type}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{minAmountAllowed}</TableCell>
                        <TableCell align="left">{maxAmountAllowed}</TableCell>
                        <TableCell align="left">{chargeGroupId?.name}</TableCell>
                        <TableCell align="left">{repaymentPeriod}</TableCell>
                        <TableCell align="left">{`${interestRate} %`}</TableCell>
                        <TableCell align="left">{penaltyRate ? `${penaltyRate} %` : 0}</TableCell>
                        <TableCell align="left">{installments}</TableCell>
                        <TableCell align="left">
                          <Typography color={isActive ? 'secondary' : 'error'}>
                            {isActive ? 'Active' : 'Inactive'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <LoanMenu loanId={id} loanData={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isLoanNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={loans.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Paper>
  );
};
export default LoanConfigs;
