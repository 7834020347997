import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import PropTypes from 'prop-types';
import { updateMessageStatus } from '../../redux/actions/messages';

ChangeMessageStatus.propTypes = {
  messageId: PropTypes.string,
  messageData: PropTypes.object
};

export default function ChangeMessageStatus({ messageId, messageData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: messageData.name,
      message: messageData.message,
      repeate: messageData.repeate,
      scheduleDetails: messageData.scheduleDetails,
      isApproved: messageData.isApproved
    },
    onSubmit: async (values) => {
      values.isActive = !values.isActive;
      const response = await updateMessageStatus({ values, dispatch, messageId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          {messageData.isActive ? (
            <ToggleOnOutlinedIcon width={24} height={24} />
          ) : (
            <ToggleOffOutlinedIcon width={24} height={24} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={messageData.isActive ? 'Suspend' : 'Activate'}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>{messageData.isActive ? 'Suspend' : 'Activate'}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                The
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${sentenceCase(messageData.name)} `}
                </span>{' '}
                will be {messageData.isActive ? 'Suspended' : 'Activated'} .
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="error">
                {messageData.isActive ? 'Suspend' : 'Activate'}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
