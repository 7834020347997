import {
  ADD_RESOURCE_SUCCESS,
  START_ADD_RESOURCE,
  END_ADD_RESOURCE,
  START_EDIT_RESOURCE,
  EDIT_RESOURCE_SUCCESS,
  END_EDIT_RESOURCE,
  START_DELETE_RESOURCE,
  END_DELETE_RESOURCE,
  DELETE_RESOURCE_SUCCESS,
  START_LOADING_RESOURCES,
  LOAD_RESOURCES,
  END_LOADING_RESOURCES
} from '../constants/resourceConstants';

const initialState = {
  isLoading: false,
  isAddResourceSuccess: false,
  isAddResourceLoading: false,
  isEditResourceLoading: false,
  isEditResourceSuccess: false,
  isDeleteResourceSuccess: false,
  resourcesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_RESOURCE:
      return { ...state, isAddResourceLoading: true };
    case END_ADD_RESOURCE:
      return { ...state, isAddResourceLoading: false };
    case START_EDIT_RESOURCE:
      return { ...state, isEditResourceLoading: true };
    case END_EDIT_RESOURCE:
      return { ...state, isEditResourceLoading: false };
    case START_DELETE_RESOURCE:
      return { ...state, isDeleteResourceLoading: true };
    case END_DELETE_RESOURCE:
      return { ...state, isDeleteResourceLoading: false };
    case ADD_RESOURCE_SUCCESS:
      return { ...state, isAddResourceSuccess: true };
    case EDIT_RESOURCE_SUCCESS:
      return { ...state, isEditResourceSuccess: true };
    case DELETE_RESOURCE_SUCCESS:
      return { ...state, isDeleteResourceSuccess: true };
    case START_LOADING_RESOURCES:
      return { ...state, isLoading: true };

    case LOAD_RESOURCES:
      return {
        ...state,
        resourcesList: action.payload.data
        // count: action.payload.data.length
        // page: action.payload.pagination.pageNumber,
        // pageSize: action.payload.pagination.pageLimit,
        // totalPages: action.payload.pagination.pageCount
        // sortOrder: action.payload.sort.order,
        // sortField: action.payload.sort.field
      };

    case END_LOADING_RESOURCES:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default resourcesReducer;
