import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';
import { updateFaq } from '../../redux/actions/faq';

UpdateFaq.propTypes = {
  faqId: PropTypes.number,
  faqData: PropTypes.object
};

const categories = [
  { value: 'general', label: 'General' },
  { value: 'mobileMoney', label: 'Mobile Money Transfer' },
  { value: 'internalTransfer', label: 'Internal Transfer' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'security', label: 'Security' },
  { value: 'loanApplication', label: 'Loan Application' }
];
export default function UpdateFaq({ faqId, faqData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const UpdateFaqSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
    category: Yup.string().required('Category is required')
  });

  const formik = useFormik({
    initialValues: {
      question: faqData.question,
      answer: faqData.answer,
      category: faqData.category,
      approved: faqData.approved
    },
    validationSchema: UpdateFaqSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await updateFaq({ values, dispatch, faqId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update FAQ</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="Question"
                type="text"
                margin="normal"
                label="Question"
                {...getFieldProps('question')}
                error={Boolean(touched.question && errors.question)}
                helperText={touched.question && errors.question}
              />
              <TextField
                fullWidth
                autoComplete="Answer"
                type="text"
                margin="normal"
                label="answer"
                {...getFieldProps('answer')}
                error={Boolean(touched.answer && errors.answer)}
                helperText={touched.answer && errors.answer}
              />

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={role}
                  // onChange={handleRoleChange}
                  {...getFieldProps('category')}
                  name="category"
                  label="Category"
                >
                  {categories.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
