import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  Checkbox,
  Container,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getMerchants } from '../redux/actions/merchants';

import { MerchantsListHead } from '../components/_dashboard/merchants';
import Page from '../components/Page';
import EmptyState from '../components/EmptyState';
import Scrollbar from '../components/Scrollbar';
import { fDate } from '../utils/formatTime';
import SearchNotFound from '../components/SearchNotFound';
import PayBillTxnListToolbar from '../components/_dashboard/merchants/merchantsListToolbar';

const TABLE_HEAD = [
  { id: 'customerId', label: 'Phone Number', alignRight: false },
  { id: 'transferReference', label: 'Transfer Reference', alignRight: false },
  { id: 'transferType', label: 'Transfer Type', alignRight: false },
  { id: 'payment.customerReceipt', label: 'MPESA Code', alignRight: false },
  { id: 'payment.responseBody', label: 'MPESA Acc No', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  // { id: 'accountFrom', label: 'Account From', alignRight: false },
  { id: 'accountTo', label: 'Account To', alignRight: false },
  { id: 'transferStatus', label: 'Transfer Status', alignRight: false },
  { id: 'payment.status', label: 'Mpesa Status', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

function Merchants() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('transactionId');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.merchantsReducer.isLoadingMerchants);
  const merchants = useSelector((state) => state.reducer.merchantsReducer.merchantsList);
  const totalTxn = useSelector((state) => state.reducer.merchantsReducer.count);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = merchants.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getMerchants({
      dispatch,
      transferType: selectedGroup,
      status: selectedStatus,
      page: newPage + 1,
      pageSize: rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getMerchants({
      dispatch,
      status: selectedStatus,
      transferType: selectedGroup,
      page: page + 1,
      pageSize: parseInt(event.target.value, 10)
    });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - merchants.length) : 0;
  useEffect(() => {
    getMerchants({
      dispatch,
      // status: selectedStatus,
      page: 1,
      pageSize: rowsPerPage
    });
  }, [dispatch]);

  return (
    <div>
      <Page title="Paybill Deposits | Fortune Sacco">
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Paybill Deposits Management
            </Typography>
          </Stack>
          <PayBillTxnListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setGroup={handleGroupChange}
            setStatus={handleStatusChange}
          />
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <LinearProgressStyle />
          ) : merchants.length < 1 ? (
            <EmptyState message="No paybill deposits available" />
          ) : (
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <MerchantsListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={merchants.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {merchants &&
                        merchants.map((row) => {
                          const {
                            id,
                            customerId,
                            transferReference,
                            transferType,
                            amount,
                            accountTo,
                            transferStatus,
                            createdAt,
                            payment
                          } = row;
                          const mpesaAccNo = row.payment
                            ? JSON.parse(row.payment.responseBody).BillRefNumber
                            : '';
                          const isItemSelected = selected.indexOf(id) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell align="left">{customerId}</TableCell>
                              <TableCell align="left">{transferReference}</TableCell>
                              <TableCell align="left">{transferType}</TableCell>
                              <TableCell align="left">
                                {payment && payment.customerReceipt}
                              </TableCell>
                              <TableCell align="left">{mpesaAccNo}</TableCell>
                              <TableCell align="left">{amount}</TableCell>
                              <TableCell align="left">{accountTo}</TableCell>
                              <TableCell align="left">{transferStatus}</TableCell>
                              <TableCell align="left">{payment && payment.status}</TableCell>
                              <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    {merchants.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={totalTxn}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </Container>
      </Page>
    </div>
  );
}

export default Merchants;
