import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
// import ApproveConfigUpdate from '../../configs/approveConfigUpdate';
import AccessControl from '../../AccessControl';
import UpdateSysConfig from '../../system-configs/updateSysConfig';

// ----------------------------------------------------------------------
SysConfigsMoreMenu.propTypes = {
  sysConfigId: PropTypes.string,
  sysConfigData: PropTypes.object
};

export default function SysConfigsMoreMenu({ sysConfigId, sysConfigData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="CONFIGS" allowedPermissions="canupdate">
          <UpdateSysConfig sysConfigId={sysConfigId} sysConfigData={sysConfigData} />
        </AccessControl>
      </Menu>
    </>
  );
}
