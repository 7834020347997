export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN';

export const START_LOADING_LOGIN = 'START_LOADING_LOGIN';
export const END_LOADING_LOGIN = 'END_LOADING_LOGIN';

export const START_LOADING_USERS = 'START_LOADING_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const END_LOADING_USERS = 'END_LOADING_USERS';

export const START_ADD_USER = 'START_ADD_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const END_ADD_USER = 'END_ADD_USER';

export const START_EDIT_USER = 'START_EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const END_EDIT_USER = 'END_EDIT_USER';

export const START_DELETE_USER = 'START_DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const END_DELETE_USER = 'END_DELETE_USER';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const START_LOADING_USER_PERMISSIONS = 'START_LOADING_USER_PERMISSIONS';
export const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
export const END_LOADING_USER_PERMISSIONS = 'END_LOADING_USER_PERMISSIONS';

export const START_PASSWORD_RESET = 'START_PASSWORD_RESET';
export const END_PASSWORD_RESET = 'END_PASSWORD_RESET';
export const RESET_PASSWORD_EMAIL_SENT = 'RESET_PASSWORD_EMAIL_SENT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
