import { secureapi } from '../../config/secureapi';
import {
  ADD_LOAN_SUCCESS,
  DELETE_LOAN_SUCCESS,
  DELETE_LOANCONFIG_SUCCESS,
  EDIT_LOAN_SUCCESS,
  END_ADD_LOAN,
  END_DELETE_LOAN,
  END_DELETE_LOANCONFIG,
  END_EDIT_LOAN,
  END_LOADING_CHARGE_GROUPS,
  END_LOADING_LOAN_TYPES,
  END_LOADING_LOANS,
  END_STATUS_LOANCONFIG,
  LOAD_CHARGE_GROUPS,
  LOAD_LOAN_TYPES,
  LOAD_LOANS,
  START_ADD_LOAN,
  START_DELETE_LOAN,
  START_DELETE_LOANCONFIG,
  START_EDIT_LOAN,
  START_LOADING_CHARGE_GROUPS,
  START_LOADING_LOAN_TYPES,
  START_LOADING_LOANS,
  START_STATUS_LOANCONFIG,
  STATUS_LOANCONFIG_SUCCESS
} from '../constants/loansConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingLoans = () => ({
  type: START_LOADING_LOANS
});

const endLoadingLoans = () => ({
  type: END_LOADING_LOANS
});

const startAddLoan = () => ({
  type: START_ADD_LOAN
});

const addLoanSuccess = () => ({
  type: ADD_LOAN_SUCCESS
});

const endAddLoan = () => ({
  type: END_ADD_LOAN
});

const startEditLoan = () => ({
  type: START_EDIT_LOAN
});

const editLoanSuccess = () => ({
  type: EDIT_LOAN_SUCCESS
});

const startDeleteLoan = () => ({
  type: START_DELETE_LOAN
});
const endDeleteLoan = () => ({
  type: END_DELETE_LOAN
});

const deleteLoanSuccess = () => ({
  type: DELETE_LOAN_SUCCESS
});

const endEditLoan = () => ({
  type: END_EDIT_LOAN
});

const loadLoans = (payload, searchValue) => ({
  type: LOAD_LOANS,
  data: { payload, searchValue }
});

const loadChargeGroups = (payload) => ({
  type: LOAD_CHARGE_GROUPS,
  payload
});

const startLoadingChargeGroups = () => ({
  type: START_LOADING_CHARGE_GROUPS
});

const endLoadingChargeGroups = () => ({
  type: END_LOADING_CHARGE_GROUPS
});

const startDeleteLoanConfig = () => ({
  type: START_DELETE_LOANCONFIG
});
const endDeleteLoanConfig = () => ({
  type: END_DELETE_LOANCONFIG
});

const deleteLoanConfigSuccess = () => ({
  type: DELETE_LOANCONFIG_SUCCESS
});

const startStatusLoanConfig = () => ({
  type: START_STATUS_LOANCONFIG
});

const endStatusLoanConfig = () => ({
  type: END_STATUS_LOANCONFIG
});
const statusLoanConfigSuccess = () => ({
  type: STATUS_LOANCONFIG_SUCCESS
});

const starLoadingtLoanTypes = () => ({
  type: START_LOADING_LOAN_TYPES
});

const endLoadingLoanTypes = () => ({
  type: END_LOADING_LOAN_TYPES
});

const loadLoanConfigs = (payload) => ({
  type: LOAD_LOAN_TYPES,
  payload
});

const getRequestParams = ({ page, type, pageSize, status, searchValue }) => {
  const params = {};

  if (status) {
    params.status = status;
  }

  if (searchValue) {
    params.searchValue = searchValue;
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }

  if (type) {
    params.type = type;
  }

  return params;
};

// get loans list
export const getLoans = ({ dispatch, status, page, pageSize, searchValue, type }) => {
  dispatch(startLoadingLoans());
  const params = getRequestParams({
    status,
    page,
    pageSize,
    searchValue,
    type
  });
  const url = '/api/v1/mobile/loan';
  console.log('search value: ', searchValue);
  secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingLoans());
      if (!data.data.response.data) {
        dispatch(loadLoans(data.data.response && data.data.response));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadLoans(data.data.response && data.data.response, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingLoans());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing loan
export const updateLoan = async ({ values, dispatch, loanId }) => {
  dispatch(startEditLoan());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/mobile/loan/${loanId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .patch(url, values)
    .then((data) => {
      dispatch(endEditLoan());
      dispatch(addSuccessMessage('Loan updated pending Approval'));
      dispatch(editLoanSuccess());
      getLoans({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// delete a loan
export const deleteLoan = ({ values, dispatch, loanId }) => {
  dispatch(startDeleteLoan());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/mobile/loan/${loanId}`;
  values.updatedBy = updatedBy;

  return secureapi
    .delete(url, values)
    .then((data) => {
      dispatch(endDeleteLoan());
      dispatch(addSuccessMessage('Loan Deleted'));
      dispatch(deleteLoanSuccess());
      getLoans({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endDeleteLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create loan Config
export const addNewLoanConfig = ({ values, dispatch }) => {
  dispatch(startAddLoan());
  const url = `/loanconfig/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId, isActive: 'false' };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddLoan());
      dispatch(addSuccessMessage(`Loan added successfully`));
      dispatch(addLoanSuccess());
      getLoansConfig({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get loansConfig list
export const getLoansConfig = ({ dispatch }) => {
  dispatch(starLoadingtLoanTypes());
  const url = `/loanconfig/all`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingLoanTypes());
      if (!data.data) {
        dispatch(loadLoanConfigs(data?.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadLoanConfigs(data?.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingLoanTypes());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update loanConfig
export const updateLoanConfig = ({ values, dispatch, loanId }) => {
  dispatch(startEditLoan());
  const updaterId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, updatedBy: updaterId };
  const url = `/loanconfig/update/${loanId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditLoan());
      dispatch(addSuccessMessage('Loan updated successfully'));
      dispatch(editLoanSuccess());
      getLoansConfig({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// Delete Loan Config
export const deleteLoanConfig = ({ dispatch, loanId }) => {
  dispatch(startDeleteLoanConfig());
  const url = `/loanconfig/delete/{userId}/${loanId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteLoanConfig());
      dispatch(addSuccessMessage(`Loan Configuration Deleted successfully`));
      dispatch(deleteLoanConfigSuccess());
      getLoansConfig({
        dispatch
      });
    })
    .catch(({ message }) => {
      dispatch(endDeleteLoanConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// approve an updated existing loanConfig
export const ApproveUpdateLoan = async ({ values, dispatch, loanId }) => {
  dispatch(startEditLoan());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/loanconfig/approve/update/${loanId}`;
  values.approvedBy = approvedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditLoan());
      dispatch(addSuccessMessage('Loan configuration updated successfully'));
      dispatch(editLoanSuccess());
      getLoansConfig({ dispatch, pendingUpdateApproval: 'true' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update LoanConfig status
export const updateLoanConfigStatus = ({ values, dispatch, memberId }) => {
  dispatch(startStatusLoanConfig());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/customers/status/${memberId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endStatusLoanConfig());
      dispatch(addSuccessMessage('Loan Configuration updated pending approval has been completed'));
      dispatch(statusLoanConfigSuccess());
      if (values.isActive) {
        getLoansConfig({ dispatch, isActive: 'false' });
      } else {
        getLoansConfig({ dispatch, isActive: 'true' });
      }
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditLoan());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get Charge groups
export const getChargeGroups = ({ dispatch }) => {
  dispatch(startLoadingChargeGroups());
  const url = `/core/v1/charge-groups`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingChargeGroups());
      if (!data.data.data) {
        dispatch(loadChargeGroups(data.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadChargeGroups(data.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingChargeGroups());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
