import { secureapi } from '../../config/secureapi';
import {
  END_LOADING_TRANSACTION,
  END_LOADING_TRANSACTIONS,
  END_PROCESSING_TRANSACTION,
  LOAD_TRANSACTION,
  LOAD_TRANSACTIONS,
  START_LOADING_TRANSACTION,
  START_LOADING_TRANSACTIONS,
  START_PROCESSING_TRANSACTION,
  START_LOADING_PAYMENTS,
  LOAD_PAYMENTS,
  END_LOADING_PAYMENTS
} from '../constants/transactionConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingTransactions = () => ({
  type: START_LOADING_TRANSACTIONS
});

const endLoadingTransactions = () => ({
  type: END_LOADING_TRANSACTIONS
});

const startProcessingTransaction = () => ({
  type: START_PROCESSING_TRANSACTION
});

const endProcessingTransaction = () => ({
  type: END_PROCESSING_TRANSACTION
});

const loadTransactions = (payload, searchValue) => ({
  type: LOAD_TRANSACTIONS,
  data: { payload, searchValue }
});
const startLoadingTransaction = () => ({
  type: START_LOADING_TRANSACTION
});

const endLoadingTransaction = () => ({
  type: END_LOADING_TRANSACTION
});

const startLoadingPayments = () => ({
  type: START_LOADING_PAYMENTS
});

const loadPayments = (payload) => ({
  type: LOAD_PAYMENTS,
  payload
});

const endLoadingPayments = () => ({
  type: END_LOADING_PAYMENTS
});

const loadTransaction = (payload) => ({
  type: LOAD_TRANSACTION,
  payload
});
const getRequestParams = ({
  page,
  pageSize,
  transferType,
  sortOrder,
  sortField,
  searchValue,
  status,
  provider
}) => {
  const params = {};

  if (transferType) {
    params.transferType = transferType;
  }

  if (provider) {
    params.provider = provider;
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (searchValue) {
    params.searchValue = searchValue;
  }
  if (status) {
    params.status = status;
  }
  return params;
};
// Get a Transaction
export const getTransactionById = ({ dispatch, id }) => {
  dispatch(startLoadingTransaction());
  const url = `/core/v1/transfer/${id}`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingTransaction());
      if (!data.data) {
        dispatch(loadTransaction(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadTransaction(data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingTransaction());
      dispatch(loadTransaction({ transfer: {}, customerDetails: {} }));
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get Transactions list
export const getAllTransactions = ({
  dispatch,
  transferType,
  page,
  pageSize,
  searchValue,
  status
}) => {
  dispatch(startLoadingTransactions());
  const params = getRequestParams({
    transferType,
    page,
    pageSize,
    searchValue,
    status
  });
  const url = `/core/v1/transfers`;
  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingTransactions());
      if (!data.data.transfers) {
        dispatch(loadTransactions(data?.data));
        dispatch(addWarningMessage('No transactions found.'));
      } else {
        dispatch(loadTransactions(data?.data, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingTransactions());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const processTransaction = ({ transferId, dispatch }) => {
  dispatch(startProcessingTransaction());
  console.log('IN THE PROCESS ACTION');
  const processedBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { transferId, processedBy };
  const url = `/v1/core/transfer/process`;
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endProcessingTransaction());
      dispatch(addSuccessMessage(`Transaction processed successfully`));
      dispatch(endProcessingTransaction());
      getAllTransactions({
        dispatch,
        transferType: 'BUY_FLOAT',
        page: 1,
        pageSize: 10
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endProcessingTransaction());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get payments
export const getPayments = ({
  dispatch,
  provider,
  transferType,
  page,
  pageSize,
  searchValue,
  status
}) => {
  dispatch(startLoadingPayments());
  const params = getRequestParams({
    transferType,
    provider,
    page,
    pageSize,
    searchValue,
    status
  });
  const url = `/core/v1/payments`;
  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingPayments());
      if (!data.data) {
        dispatch(loadPayments(data?.data));
        dispatch(addWarningMessage('No payments found.'));
      } else {
        dispatch(loadPayments(data?.data, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingPayments());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// check payment status
export const checkStatus = ({ dispatch, paymentId, paymentData, userId }) => {
  const url = `/payments/v1/transaction_status`;
  const data = {
    transferType: paymentData.provider,
    paymentId,
    processedBy: userId
  };

  return secureapi
    .post(url, data)
    .then((data) => data.data.data.isSuccess)
    .catch(({ message }) => {
      dispatch(endLoadingPayments());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// close a payment
export const closePayment = ({ dispatch, paymentId, userId, paymentExists }) => {
  const url = `/core/v1/payments/processmanually`;
  const data = {
    action: 'CLOSE',
    isSuccess: paymentExists,
    paymentId,
    processedBy: userId
  };

  return secureapi
    .post(url, data)
    .then((data) => {
      console.log('CANCELLED RESPONSE >>> ', data);
      dispatch(addSuccessMessage('Payment updated successfully!'));
    })
    .catch(({ message }) => {
      dispatch(endLoadingPayments());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// process a payment
export const processPayment = ({ dispatch, paymentId, userId, paymentExists }) => {
  const url = `/core/v1/payments/processmanually`;
  const data = {
    action: 'PROCESS',
    isSuccess: paymentExists,
    paymentId,
    processedBy: userId
  };

  return secureapi
    .post(url, data)
    .then((data) => {
      console.log('PROCESSED RESPONSE >>> ', data);
      dispatch(addSuccessMessage('Payment updated successfully!'));
    })
    .catch(({ message }) => {
      dispatch(endLoadingPayments());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
