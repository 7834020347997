import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers';
// TODO: Figure out why reload clears the blacklist items including user
const persistConfig = {
  key: 'user',
  storage,
  blacklist: [
    'notification',
    'systemNotif',
    'rolesReducer',
    'resourcesReducer',
    'permissionsReducer',
    'dashboardReducer',
    'faqsReducer',
    'membersReducer',
    'ticketsReducer',
    'usersReducer',
    'chargesReducer',
    'branchesReducer',
    'glReducer',
    'billersReducer',
    'transactionsReducer',
    'loansReducer',
    'loanConfigsReducer',
    'supportReducer',
    'configsReducer',
    'externalBillersReducer',
    'messagesReducer',
    'loanMetaReducer',
    'salaryAdvanceReducer',
    'sysConfigsReducer'
  ]
};

const rootReducer = combineReducers({ reducer });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);

export default store;
