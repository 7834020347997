import {
  ADD_NOTIF_MESSAGE,
  ADD_NOTIF_WARNING_MESSAGE,
  ADD_NOTIF_SUCCESS_MESSAGE,
  ADD_NOTIF_ERROR_MESSAGE,
  CLEAR_NOTIF_MESSAGE,
  START_LOADING_NOTIF_MESSAGE,
  END_LOADING_NOTIF_MESSAGE,
  LOAD_NOTIF_MESSAGE,
  START_UPDATE_NOTIF_MESSAGE,
  END_UPDATE_NOTIF_MESSAGE
} from '../constants/systemNotifications';
import { secureapi } from '../../config/secureapi';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

export const addNotifMessage = (message) => ({
  type: ADD_NOTIF_MESSAGE,
  message
});
export const addNotifSuccessMessage = (message) => ({
  type: ADD_NOTIF_SUCCESS_MESSAGE,
  message
});

export const addNotifErrorMessage = (message) => ({
  type: ADD_NOTIF_ERROR_MESSAGE,
  message
});

export const addNotifWarningMessage = (message) => ({
  type: ADD_NOTIF_WARNING_MESSAGE,
  message
});

export const clearMessage = () => ({
  type: CLEAR_NOTIF_MESSAGE
});

const startLoadingNotifications = () => ({
  type: START_LOADING_NOTIF_MESSAGE
});

const endLoadingNotifications = () => ({
  type: END_LOADING_NOTIF_MESSAGE
});

const loadNotifications = (data) => ({
  type: LOAD_NOTIF_MESSAGE,
  payload: data
});

const startUpdateNotifications = () => ({
  type: START_UPDATE_NOTIF_MESSAGE
});

const endUpdateNotifications = () => ({
  type: END_UPDATE_NOTIF_MESSAGE
});
// get notifications list
export const getNotifications = ({ dispatch }) => {
  dispatch(startLoadingNotifications());
  const userId = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/notifications/all?readBy=${userId}`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingNotifications());
      if (!data.data) {
        dispatch(loadNotifications(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadNotifications(data.data));
        // dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingNotifications());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// mark all notifications as read
export const updateNotification = ({ dispatch, id }) => {
  dispatch(startUpdateNotifications());
  const userId = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/notifications/update/${id}`;
  return secureapi
    .put(url, { readBy: userId })
    .then(() => {
      dispatch(endUpdateNotifications());
      dispatch(addSuccessMessage(`Notifications updated successfully`));
      getNotifications({ dispatch });
    })
    .catch(({ message }) => {
      dispatch(endUpdateNotifications());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
