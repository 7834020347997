import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { sentenceCase } from 'change-case';
import { deletePermission } from '../../redux/actions/permission';

DeletePermission.propTypes = {
  permissionId: PropTypes.string,
  permissionData: PropTypes.object
};

export default function DeletePermission({ permissionId, permissionData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      await deletePermission({ dispatch, permissionId }).then(() => {
        handleClose();
      });
    }
  });

  const { handleSubmit } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <DeleteOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Delete system permission</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                Permission for the role{' '}
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {sentenceCase(permissionData.rolename)}
                </span>{' '}
                and resource{' '}
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {sentenceCase(permissionData.resourcename)}
                </span>{' '}
                will be deleted.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="error">
                Delete
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
