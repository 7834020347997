import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
//

import { SecondaryTab, SecondaryTabs, TabPanel } from '../components/CustomTab';
import AllTickets from '../components/tickets/AllTickets';
import AssignedTickets from '../components/tickets/AssignedTickets';
import AssignedByMeTickets from '../components/tickets/AssignedByMeTickets';

export default function Tickets() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Tickets | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tickets
          </Typography>
        </Stack>
        <SecondaryTabs value={value} onChange={handleChange} aria-label="ant example">
          <SecondaryTab label="All Tickets" />
          <SecondaryTab label="Tickets Assigned to me" />
          <SecondaryTab label="Tickets Assigned by me" />
        </SecondaryTabs>
        <TabPanel value={value} index={0}>
          <AllTickets />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AssignedTickets />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AssignedByMeTickets />
        </TabPanel>
      </Container>
    </Page>
  );
}
