import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { styled } from '@mui/material/styles';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { getPayments } from '../../../redux/actions/transactions';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PendingResolutionListToolbar.propTypes = {
  numSelected: PropTypes.number,
  isPending: PropTypes.bool
};

export default function PendingResolutionListToolbar({ numSelected, isPending }) {
  const dispatch = useDispatch();
  const timeout = process.env.REACT_APP_SEARCH_TIMEOUT || 1000;
  let filterValue = '';
  filterValue = useSelector((state) => state.reducer.transactionsReducer.searchValue);

  const debounceSearch = useCallback(
    debounce(async (e) => {
      const filters = isPending
        ? {
            dispatch,
            searchValue: e,
            page: 1,
            pageSize: 10,
            status: 'SENT_EXTERNAL'
          }
        : {
            dispatch,
            searchValue: e,
            page: 1,
            pageSize: 10
          };
      await getPayments(filters);
    }, timeout),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const handleClear = async () => {
    filterValue = '';
    const filters = isPending
      ? {
          dispatch,
          page: 1,
          pageSize: 10,
          status: 'SENT_EXTERNAL'
        }
      : {
          dispatch,
          page: 1,
          pageSize: 10
        };
    await getPayments(filters);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterValue}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Clear Search">
          <IconButton onClick={handleClear}>
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
