// routes
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import JwtDecode from 'jwt-decode';
import routes from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------

const App = () => {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  const checkLoggedIn = () => {
    if (token) {
      const decodedToken = JwtDecode(token);
      const currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  };

  const isLoggedIn = checkLoggedIn();
  // const isLoggedIn = true;
  const routing = useRoutes(routes(isLoggedIn));
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <AnimatePresence exitBeforeEnter>{routing}</AnimatePresence>
    </ThemeConfig>
  );
};

export default App;
