import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import { approveUpdateMember } from '../../redux/actions/members';

// to check the correct data types and data is passed
ApproveUpdateMember.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};

export default function ApproveUpdateMember({ memberId, memberData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // phone number and id validator regex
  const numberRegex = /^(?:254|\+254|0)?(7|1?[0-9]{9})$/;

  const UpdateMemberSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string()
      .matches(numberRegex, 'Phone Number is not valid')
      .required('Phone Number is required'),
    nationalId: Yup.string()
      .required()
      .min(7, 'National ID must have at least 7 characters')
      .max(9, 'National ID must have at most 9 characters'),
    isSuspended: Yup.string().required('Status is required')
  });

  const { firstName, lastName, phoneNumber, nationalId, isSuspended, updatedAt, lastUpdatedBy } =
    memberData.pendingupdatejson;
  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      phoneNumber,
      nationalId,
      isSuspended,
      updatedAt,
      lastUpdatedBy
    },
    validationSchema: UpdateMemberSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await approveUpdateMember({ values, dispatch, memberId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Approve Member Update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <Grid item lg={6}>
                  <Typography color="error">Previous Data</Typography>
                  <TextField
                    disabled
                    margin="normal"
                    id="prevFirstName"
                    label="FirstName"
                    variant="outlined"
                    defaultValue={memberData.firstname}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevFirstName"
                    label="FirstName"
                    variant="outlined"
                    defaultValue={memberData.lastname}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevFirstName"
                    label="FirstName"
                    variant="outlined"
                    defaultValue={memberData.phonenumber}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevFirstName"
                    label="FirstName"
                    variant="outlined"
                    defaultValue={memberData.nationalid}
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={memberData.issuspended === false}
                        name="isSuspended"
                        color="primary"
                      />
                    }
                    label="Is Active Member"
                  />
                </Grid>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="firstName"
                    type="text"
                    margin="normal"
                    label="First Name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="lastName"
                    type="text"
                    margin="normal"
                    label="Last Name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="phoneNumber"
                    type="text"
                    margin="normal"
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="nationalId"
                    type="text"
                    margin="normal"
                    label="National ID"
                    {...getFieldProps('nationalId')}
                    error={Boolean(touched.nationalId && errors.nationalId)}
                    helperText={touched.nationalId && errors.nationalId}
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox checked={isSuspended === false} name="isActive" color="primary" />
                    }
                    label="Is Active Member"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
