import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_BILLERS,
  LOAD_BILLERS,
  END_LOADING_BILLERS,
  START_LOADING_BILLER_TYPES,
  END_LOADING_BILLER_TYPES,
  LOAD_BILLER_TYPES,
  START_ADD_BILLER,
  ADD_BILLER_SUCCESS,
  END_ADD_BILLER
} from '../constants/billerConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingBillers = () => ({
  type: START_LOADING_BILLERS
});

const endLoadingBillers = () => ({
  type: END_LOADING_BILLERS
});

const startAddBiller = () => ({
  type: START_ADD_BILLER
});

const addBillerSuccess = () => ({
  type: ADD_BILLER_SUCCESS
});

const endAddBiller = () => ({
  type: END_ADD_BILLER
});

const loadBillers = (payload) => ({
  type: LOAD_BILLERS,
  payload
});

const startLoadingBillerTypes = () => ({
  type: START_LOADING_BILLER_TYPES
});

const endLoadingBillerTypes = () => ({
  type: END_LOADING_BILLER_TYPES
});

const loadBillerTypes = (payload) => ({
  type: LOAD_BILLER_TYPES,
  payload
});

// get Billers list
export const getBillers = ({ dispatch }) => {
  dispatch(startLoadingBillers());
  const url = `/accounts/v1/account/billers`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingBillers());
      if (!data.data.billers) {
        dispatch(loadBillers(data?.data.billers));
        dispatch(addWarningMessage('No billers found.'));
      } else {
        dispatch(loadBillers(data?.data.billers));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingBillers());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get Billers list
export const getBillerTypes = ({ dispatch }) => {
  dispatch(startLoadingBillerTypes());
  const url = `/accounts/v1/account/biller/categories`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingBillerTypes());
      if (!data.data.billerCategories) {
        dispatch(loadBillerTypes(data?.data.billerCategories));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadBillerTypes(data?.data.billerCategories));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingBillerTypes());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
// create biller
export const addNewBiller = ({ values, dispatch }) => {
  dispatch(startAddBiller());
  const url = `/accounts/v1/account/biller/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddBiller());
      dispatch(addSuccessMessage(`Branch added successfully`));
      dispatch(addBillerSuccess());
      getBillers({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddBiller());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
