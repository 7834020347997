import {
  START_LOADING_SYS_CONFIGS,
  LOAD_SYS_CONFIGS,
  END_LOADING_SYS_CONFIGS,
  START_EDIT_SYS_CONFIG,
  EDIT_SYS_CONFIG_SUCCESS,
  END_EDIT_SYS_CONFIG,
  START_ADDING_SYS_CONFIGS,
  END_ADDING_SYS_CONFIGS,
  ADD_SYS_CONFIG_SUCCESS
} from '../constants/systemConfigsConstants';

const initialState = {
  isLoading: false,
  isEditSysConfigsLoading: false,
  isAddSysConfigsLoading: false,
  isAddSysConfigsSuccess: false,
  isEditSysConfigsSuccess: false,
  sysConfigsList: [],
  sortOrder: 1,
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const sysConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_SYS_CONFIGS:
      return { ...state, isLoading: true };
    case LOAD_SYS_CONFIGS:
      return { ...state, sysConfigsList: action.payload };
    case END_LOADING_SYS_CONFIGS:
      return { ...state, isLoading: false };
    case START_ADDING_SYS_CONFIGS:
      return { ...state, isAddSysConfigsLoading: true };
    case ADD_SYS_CONFIG_SUCCESS:
      return { ...state, isAddSysConfigsSuccess: true };
    case END_ADDING_SYS_CONFIGS:
      return { ...state, isAddSysConfigsLoading: false };
    case START_EDIT_SYS_CONFIG:
      return { ...state, isEditSysConfigsLoading: true };
    case EDIT_SYS_CONFIG_SUCCESS:
      return { ...state, isEditSysConfigsSuccess: true };
    case END_EDIT_SYS_CONFIG:
      return { ...state, isEditSysConfigsLoading: false };
    default:
      return state;
  }
};

export default sysConfigsReducer;
