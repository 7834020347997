import {
  END_LOADING_TRANSACTION,
  END_LOADING_TRANSACTIONS,
  END_PROCESSING_TRANSACTION,
  LOAD_TRANSACTION,
  LOAD_TRANSACTIONS,
  START_LOADING_TRANSACTION,
  START_LOADING_TRANSACTIONS,
  START_PROCESSING_TRANSACTION,
  START_LOADING_PAYMENTS,
  LOAD_PAYMENTS,
  END_LOADING_PAYMENTS
} from '../constants/transactionConstants';

const initialState = {
  isLoadingTransactions: false,
  isLoadingPayments: false,
  isLoadingTransaction: false,
  isProcessingTransaction: false,
  transactionData: {},
  transactionsList: [],
  paymentsList: [],
  sortOrder: 1,
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  searchValue: 'Search Transaction ...'
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_TRANSACTIONS:
      return { ...state, isLoadingTransactions: true };
    case END_LOADING_TRANSACTIONS:
      return { ...state, isLoadingTransactions: false };
    case LOAD_TRANSACTIONS:
      return {
        ...state,
        transactionsList: action.data.payload.transfers,
        count: action.data.payload.count,
        searchValue: action.data.searchValue
      };
    case START_LOADING_PAYMENTS:
      return { ...state, isLoadingPayments: true };
    case LOAD_PAYMENTS:
      return { ...state, paymentsList: action.payload.payments, count: action.payload.count };
    case END_LOADING_PAYMENTS:
      return { ...state, isLoadingPayments: false };
    case START_LOADING_TRANSACTION:
      return { ...state, isLoadingTransaction: true };
    case END_LOADING_TRANSACTION:
      return { ...state, isLoadingTransaction: false };
    case LOAD_TRANSACTION:
      return { ...state, transactionData: action.payload };

    case START_PROCESSING_TRANSACTION:
      return { ...state, isProcessingTransaction: true };
    case END_PROCESSING_TRANSACTION:
      return { ...state, isProcessingTransaction: false };
    default:
      return state;
  }
};

export default transactionsReducer;
