import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { connect, useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { clearMessage } from '../redux/actions/notification';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />
));
const SnackbarStyle = styled(Snackbar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(5)
  }
}));
// eslint-disable-next-line react/prop-types
const Main = ({ clearMessage }) => {
  const [open, setOpen] = useState(false);
  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
  const type = useSelector((state) => state.reducer.notification.type);
  const message = useSelector((state) => state.reducer.notification.message);

  const duration = 10000;
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    message && setOpen(true);
  }, [type, message]);
  const handleClose = () => {
    clearMessage();
    setOpen(false);
  };
  return (
    message && (
      <SnackbarStyle
        open={open}
        autoHideDuration={type === 'error' ? 30000 : duration}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert onClose={handleClose} severity={type}>
          {message?.message || message}
        </Alert>
      </SnackbarStyle>
    )
  );
};
const mapState = () => ({});
const mapDispatch = {
  clearMessage
};
export default connect(mapState, mapDispatch)(Main);
