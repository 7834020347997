import {
  START_LOADING_CONFIGS,
  LOAD_CONFIGS,
  END_LOADING_CONFIGS,
  START_ADD_CONFIG,
  ADD_CONFIG_SUCCESS,
  END_ADD_CONFIG,
  START_EDIT_CONFIG,
  EDIT_CONFIG_SUCCESS,
  END_EDIT_CONFIG
} from '../constants/configsConstants';

const initialState = {
  isLoadingConfigs: false,
  isAddConfigLoading: false,
  isAddConfigSuccess: false,
  isEditConfigLoading: false,
  isEditConfigSuccess: false,
  configsList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const configsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_CONFIGS:
      return { ...state, isLoadingConfigs: true };
    case END_LOADING_CONFIGS:
      return { ...state, isLoadingConfigs: false };
    case LOAD_CONFIGS:
      return { ...state, configsList: action.payload };
    case START_ADD_CONFIG:
      return { ...state, isAddConfigLoading: true };
    case ADD_CONFIG_SUCCESS:
      return { ...state, isAddConfigSuccess: true };
    case END_ADD_CONFIG:
      return { ...state, isAddConfigLoading: false };
    case START_EDIT_CONFIG:
      return { ...state, isEditConfigLoading: true };
    case EDIT_CONFIG_SUCCESS:
      return { ...state, isEditConfigSuccess: true };
    case END_EDIT_CONFIG:
      return { ...state, isEditConfigLoading: false };
    default:
      return state;
  }
};

export default configsReducer;
