export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN';
export const START_LOADING = 'START_LOADING';

export const END_LOADING = 'END_LOADING';
export const START_LOADING_FAQ = 'START_LOADING_FAQ';
export const LOAD_FAQ = 'LOAD_FAQ';
export const END_LOADING_FAQ = 'END_LOADING_FAQ';
export const RESET_PASSWORD_EMAIL_SENT = 'RESET_PASSWORD_EMAIL_SENT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const START_ADD_FAQ = 'START_ADD_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const ADD_FAQ_SUCCESS = 'ADD__SUCCESS';
export const END_ADD_FAQ = 'END_ADD_FAQ';

export const START_EDIT_FAQ = 'START_EDIT_FAQ';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ = 'EDIT_FAQ';
export const END_EDIT_FAQ = 'END_EDIT_FAQ';

export const START_DELETE_FAQ = 'START_DELETE_FAQ';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ = 'DELETE_FAQ';
export const END_DELETE_FAQ = 'END_DELETE_FAQ';
