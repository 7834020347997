import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { secureapi } from '../../config/secureapi';
import { fDate } from '../../utils/formatTime';
import EmptyState from '../EmptyState';

MobileLoans.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxwidth: '40%',
  margin: 'auto'
}));

// eslint-disable-next-line camelcase
export default function MobileLoans({ memberId, memberData }) {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let results;

  async function getMemberMobileLoans() {
    // eslint-disable-next-line camelcase
    const response = await secureapi(`/api/v1/loan/${memberId}`, {});
    if (response) {
      results = response.data.data;
      setTransactions(results);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }

  console.log('TRANSACTIONS', transactions);

  useEffect(() => {
    getMemberMobileLoans();
  }, [memberId]);

  return (
    <>
      {memberData.loandisqualificationreason !== null ? (
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Reason For Disqualification: </Typography>
              <Typography sx={{ marginLeft: '0.5rem' }}>
                {memberData.loandisqualificationreason}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ) : (
        ''
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Name
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Account Number
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Arrears
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Disbursed Amount
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Last Repayment Date
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Defaulted
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Loan Balance
              </TableCell>
            </TableRow>
          </TableHead>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <LinearProgressStyle />
          ) : transactions < 1 ? (
            <EmptyState message="No mobile Loans found" />
          ) : (
            <TableBody>
              {transactions &&
                transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{transaction?.accountName}</TableCell>
                    <TableCell align="left">{transaction?.accountNumber}</TableCell>
                    <TableCell align="left">{transaction?.arrears}</TableCell>
                    <TableCell align="left">{transaction?.disbursedAmount}</TableCell>
                    <TableCell align="left">
                      {typeof transaction?.lastRepaymentDate === 'string'
                        ? transaction?.lastRepaymentDate
                        : fDate(transaction?.lastRepaymentDate)}
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.inDefault === true ? (
                        <Typography sx={{ color: 'red' }}>Yes</Typography>
                      ) : (
                        <Typography sx={{ color: 'green' }}>No</Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">{transaction?.loanBalance}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
