export const START_ADD_SUPPORT = 'START_ADD_SUPPORT';
export const END_ADD_SUPPORT = 'END_ADD_SUPPORT';
export const ADD_SUPPORT_SUCCESS = 'ADD_SUPPORT_SUCCESS';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const START_LOADING_SUPPORT = 'START_LOADING_SUPPORT';
export const LOAD_SUPPORT = 'LOAD_SUPPORT';
export const END_LOADING_SUPPORT = 'END_LOADING_SUPPORT';

export const END_EDIT_SUPPORT = 'END_EDIT_SUPPORT';
export const START_STATUS_SUPPORT = 'START_STATUS_SUPPORT';
export const STATUS_SUPPORT_SUCCESS = 'STATUS_SUPPORT_SUCCESS';
export const STATUS_SUPPORT = 'STATUS_SUPPORT';
export const END_STATUS_SUPPORT = 'END_STATUS_SUPPORT';

export const START_DELETE_SUPPORT = 'START_DELETE_SUPPORT';
export const END_DELETE_SUPPORT = 'END_DELETE_SUPPORT';
export const DELETE_SUPPORT_SUCCESS = 'DELETE_SUPPORT_SUCCESS';
