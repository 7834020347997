import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// material
import { styled } from '@mui/material/styles';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { getMerchants } from '../../../redux/actions/merchants';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PayBillTxnListToolbar.propTypes = {
  numSelected: PropTypes.number,
  setGroup: PropTypes.func,
  setStatus: PropTypes.func
};

const TRANSFER_TYPE = [
  { value: 'MERCHANT_DEPOSIT', label: 'MERCHANT DEPOSIT' },
  { value: 'MPESA_C2B', label: 'MPESA C2B' },
  { value: 'ACCOUNT_DEPOSIT', label: 'ACCOUNT DEPOSIT' },
  { value: 'BILLER_DEPOSIT', label: 'BILLER DEPOSIT' }
];
const TRANSFER_STATUS = [
  { value: 'SUCCESS', label: 'SUCCESS' },
  { value: 'FAILED', label: 'FAILED' },
  { value: 'INITIATED', label: 'INITIATED' }
];

export default function PayBillTxnListToolbar({ numSelected, setGroup, setStatus }) {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const timeout = process.env.REACT_APP_SEARCH_TIMEOUT || 1000;
  let filterValue = '';
  filterValue = useSelector((state) => state.reducer.merchantsReducer.searchValue);
  const debounceSearch = useCallback(
    debounce(async (e) => {
      await getMerchants({
        dispatch,
        searchValue: e,
        page: 1,
        pageSize: 10
      });
    }, timeout),
    []
  );
  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };
  const handleClear = async () => {
    setSelectedGroup('');
    setSelectedStatus('');
    filterValue = '';
    await getMerchants({
      dispatch,
      page: 1,
      pageSize: 10
    });
  };
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    setGroup(event.target.value);
    getMerchants({
      dispatch,
      transferType: event.target.value,
      status: selectedStatus,
      page: 1,
      pageSize: 10
    });
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setStatus(event.target.value);
    getMerchants({
      dispatch,
      transferType: selectedGroup,
      status: event.target.value,
      page: 1,
      pageSize: 10
    });
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterValue}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      <FormControl size="small" sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGroup}
          label="Transfer Type"
          onChange={handleGroupChange}
        >
          {TRANSFER_TYPE.map((group) => (
            <MenuItem value={group.value} key={group.value}>
              {group.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size="small" sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Transaction Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedStatus}
          label="Transfer Status"
          onChange={handleStatusChange}
        >
          {TRANSFER_STATUS.map((group) => (
            <MenuItem value={group.value} key={group.value}>
              {group.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Clear Search">
          <IconButton onClick={handleClear}>
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
