import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_DASHBOARD_SUMMARY,
  LOAD_DASHBOARD_SUMMARY,
  END_LOADING_DASHBOARD_SUMMARY
} from '../constants/dashboardConstants';
import { addErrorMessage, addWarningMessage } from './notification';

const startLoadingDashboardSummary = () => ({
  type: START_LOADING_DASHBOARD_SUMMARY
});

const loadDashboardSummary = (payload) => ({
  type: LOAD_DASHBOARD_SUMMARY,
  payload
});

const endLoadingDashboardSummary = () => ({
  type: END_LOADING_DASHBOARD_SUMMARY
});

// get transaction count
export const getDashboardSummary = ({ dispatch }) => {
  dispatch(startLoadingDashboardSummary());
  const url = '/portal/v1/dashboard/summaries/';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingDashboardSummary());
      if (!data.data) {
        dispatch(loadDashboardSummary(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadDashboardSummary(data.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingDashboardSummary());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
