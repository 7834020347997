export const START_LOADING_RESOURCES = 'START_LOADING_RESOURCES';
export const LOAD_RESOURCES = 'LOAD_RESOURCES';
export const END_LOADING_RESOURCES = 'END_LOADING_RESOURCES';

export const START_ADD_RESOURCE = 'START_ADD_RESOURCE';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_RESOURCE_SUCCESS = 'ADD_RESOURCE_SUCCESS';
export const END_ADD_RESOURCE = 'END_ADD_RESOURCE';

export const START_EDIT_RESOURCE = 'START_EDIT_RESOURCE';
export const EDIT_RESOURCE_SUCCESS = 'EDIT_RESOURCE_SUCCESS';
export const EDIT_RESOURCE = 'EDIT_RESOURCE';
export const END_EDIT_RESOURCE = 'END_EDIT_RESOURCE';

export const START_DELETE_RESOURCE = 'START_DELETE_RESOURCE';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const END_DELETE_RESOURCE = 'END_DELETE_RESOURCE';
