import fileDownload from 'js-file-download';
import { secureapi } from '../../config/secureapi';
import {
  END_LOADING_ADVANCED_REPORT,
  END_LOADING_MEMBERS_REPORT,
  END_LOADING_TRANSACTIONS_REPORT,
  LOAD_ADVANCED_REPORT,
  LOAD_MEMBERS_REPORT,
  LOAD_TRANSACTIONS_REPORT,
  START_LOADING_ADVANCED_REPORT,
  START_LOADING_MEMBERS_REPORT,
  START_LOADING_TRANSACTIONS_REPORT
} from '../constants/reportsConstants';
import { addErrorMessage, addWarningMessage } from './notification';

const startLoadingTransactionReports = () => ({
  type: START_LOADING_TRANSACTIONS_REPORT
});

const loadTransactionsReport = (payload, searchValue) => ({
  type: LOAD_TRANSACTIONS_REPORT,
  data: { payload, searchValue }
});

const endLoadingTransactionsReport = () => ({
  type: END_LOADING_TRANSACTIONS_REPORT
});

const startLoadingMembersReports = () => ({
  type: START_LOADING_MEMBERS_REPORT
});

const loadMembersReport = (payload, searchValue) => ({
  type: LOAD_MEMBERS_REPORT,
  data: { payload, searchValue }
});

const endLoadingMembersReport = () => ({
  type: END_LOADING_MEMBERS_REPORT
});

const startLoadingAdvancedReport = () => ({
  type: START_LOADING_ADVANCED_REPORT
});
const loadAdvancedReport = (payload) => ({
  type: LOAD_ADVANCED_REPORT,
  data: { payload }
});
const endLoadingAdvancedReport = () => ({
  type: END_LOADING_ADVANCED_REPORT
});
const today = new Date();
const getRequestParams = ({
  firstName,
  lastName,
  phoneNumber,
  nationalId,
  branch,
  isSuspended,
  isRegistrationComplete,
  accountFrom,
  accountTo,
  createDateFrom,
  createDateTo,
  updateDateFrom,
  updateDateTo,
  searchValue,
  page,
  pageSize,
  // eslint-disable-next-line camelcase
  t_transfer_reference,
  // eslint-disable-next-line camelcase
  t_amount, // eslint-disable-next-line camelcase
  t_transfer_type,
  // eslint-disable-next-line camelcase
  t_transfer_status
}) => {
  const params = {};
  if (firstName) {
    params.firstName = firstName;
  }
  if (lastName) {
    params.lastName = lastName;
  }
  if (phoneNumber) {
    params.phoneNumber = phoneNumber;
  }
  if (nationalId) {
    params.nationalId = nationalId;
  }
  if (isSuspended) {
    params.isSuspended = isSuspended;
  }
  if (isRegistrationComplete) {
    params.isRegistrationComplete = isRegistrationComplete;
  }
  if (branch) {
    params.branch = branch;
  }
  if (accountFrom) {
    params.accountFrom = accountFrom;
  }
  if (accountTo) {
    params.accountTo = accountTo;
  }
  if (createDateFrom) {
    params.createDateFrom = createDateFrom;
  }
  if (createDateTo) {
    params.createDateTo = createDateTo;
  }
  if (updateDateFrom) {
    params.updateDateFrom = updateDateFrom;
  }
  if (updateDateTo) {
    params.updateDateTo = updateDateTo;
  }
  if (searchValue) {
    params.searchValue = searchValue;
  }
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  // eslint-disable-next-line camelcase
  if (t_transfer_reference) {
    // eslint-disable-next-line camelcase
    params.transferReference = t_transfer_reference;
  }
  // eslint-disable-next-line camelcase
  if (t_amount) {
    // eslint-disable-next-line camelcase
    params.amount = t_amount;
  }
  // eslint-disable-next-line camelcase
  if (t_transfer_type) {
    // eslint-disable-next-line camelcase
    params.transferType = t_transfer_type;
  }
  // eslint-disable-next-line camelcase
  if (t_transfer_status) {
    // eslint-disable-next-line camelcase
    params.transferStatus = t_transfer_status;
  }
  return params;
};
// fetching transactional reports
export const getTransactionReports = async ({
  dispatch,
  // eslint-disable-next-line camelcase
  t_transfer_reference,
  // eslint-disable-next-line camelcase
  t_amount,
  accountFrom,
  accountTo,
  // eslint-disable-next-line camelcase
  t_transfer_type,
  // eslint-disable-next-line camelcase
  t_transfer_status,
  createDateFrom,
  createDateTo,
  updateDateFrom,
  updateDateTo,
  searchValue,
  page,
  pageSize
}) => {
  dispatch(startLoadingTransactionReports());
  const params = getRequestParams({
    t_transfer_reference,
    t_amount,
    accountFrom,
    accountTo,
    t_transfer_type,
    t_transfer_status,
    createDateFrom,
    createDateTo,
    updateDateFrom,
    updateDateTo,
    searchValue,
    page,
    pageSize
  });
  const url = '/reports/transactions';

  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingTransactionsReport());
      if (!data.data.data) {
        dispatch(loadTransactionsReport(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadTransactionsReport(data.data, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingTransactionsReport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// fetching transactional reports for donwload
export const getDownloadTransactionReports = async ({
  dispatch,
  // eslint-disable-next-line camelcase
  t_transfer_reference,
  // eslint-disable-next-line camelcase
  t_amount,
  accountFrom,
  accountTo,
  // eslint-disable-next-line camelcase
  t_transfer_type,
  // eslint-disable-next-line camelcase
  t_transfer_status,
  createDateFrom,
  createDateTo,
  updateDateFrom,
  updateDateTo
}) => {
  dispatch(startLoadingTransactionReports());
  const params = getRequestParams({
    t_transfer_reference,
    t_amount,
    accountFrom,
    accountTo,
    t_transfer_type,
    t_transfer_status,
    createDateFrom,
    createDateTo,
    updateDateFrom,
    updateDateTo
  });
  const url = '/reports/transactions/csv';

  return secureapi
    .get(url, { params, responseType: 'blob' })
    .then((res) => {
      dispatch(endLoadingTransactionsReport());
      fileDownload(res.data, `transaction-report-${today.getTime()}.csv`);
    })
    .catch(({ message }) => {
      dispatch(endLoadingTransactionsReport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
// fetching transactional reports
export const getMemberReports = async ({
  dispatch,
  firstName,
  lastName,
  phoneNumber,
  nationalId,
  branch,
  isSuspended,
  isRegistrationComplete,
  createDateFrom,
  createDateTo,
  updateDateFrom,
  updateDateTo,
  searchValue,
  page,
  pageSize
}) => {
  dispatch(startLoadingMembersReports());
  const params = getRequestParams({
    firstName,
    lastName,
    phoneNumber,
    nationalId,
    branch,
    isSuspended: isSuspended === false ? 'false' : isSuspended,
    isRegistrationComplete: isRegistrationComplete === false ? 'false' : isRegistrationComplete,
    createDateFrom,
    createDateTo,
    updateDateFrom,
    updateDateTo,
    searchValue,
    page,
    pageSize
  });
  const url = '/reports/members';

  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingMembersReport());
      if (!data.data.data) {
        dispatch(loadMembersReport(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadMembersReport(data.data, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingMembersReport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
// fetching downloadable transactional reports
export const getDownloadMemberReports = async ({
  dispatch,
  firstName,
  lastName,
  phoneNumber,
  nationalId,
  branch,
  isSuspended,
  isRegistrationComplete,
  createDateFrom,
  createDateTo,
  updateDateFrom,
  updateDateTo,
  searchValue,
  page,
  pageSize
}) => {
  dispatch(startLoadingMembersReports());
  const params = getRequestParams({
    firstName,
    lastName,
    phoneNumber,
    nationalId,
    branch,
    isSuspended: isSuspended === false ? 'false' : isSuspended,
    isRegistrationComplete: isRegistrationComplete === false ? 'false' : isRegistrationComplete,
    createDateFrom,
    createDateTo,
    updateDateFrom,
    updateDateTo,
    searchValue,
    page,
    pageSize
  });
  const url = '/reports/members/csv';

  return secureapi
    .get(url, { params, responseType: 'blob' })
    .then((res) => {
      dispatch(endLoadingMembersReport());
      fileDownload(res.data, `MemberReport-${today.getTime()}.csv`);
    })
    .catch(({ message }) => {
      dispatch(endLoadingMembersReport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const getDbConfig = ({ dispatch }) => {
  dispatch(startLoadingAdvancedReport());
  const url = `/portal/reports/adhoc`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingAdvancedReport());
      if (!data.data) {
        dispatch(loadAdvancedReport(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadAdvancedReport(data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingAdvancedReport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
