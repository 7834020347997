import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { Checkbox, FormControlLabel, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { updateRole } from '../../redux/actions/role';

UpdateUser.propTypes = {
  roleId: PropTypes.string,
  roleData: PropTypes.object
};

export default function UpdateUser({ roleId, roleData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(roleData.isapproved);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const UpdateRoleSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    isSuperRole: Yup.boolean(),
    isApproved: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      name: roleData.name,
      isSuperRole: roleData.isSuperRole,
      isApproved: roleData.isapproved
    },
    validationSchema: UpdateRoleSchema,
    onSubmit: async (values, { resetForm }) => {
      values.isApproved = isChecked;
      const response = await updateRole({ values, dispatch, roleId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update system role</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheck}
                    name="isApproved"
                    color="primary"
                  />
                }
                label="Approve"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
