import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { updatePesalink } from '../../redux/actions/pesalink';

UpdatePesalink.propTypes = {
  pesalinkId: PropTypes.string,
  pesalinkData: PropTypes.object
};

export default function UpdatePesalink({ pesalinkId, pesalinkData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdatePesalinkSchema = Yup.object().shape({
    bankName: Yup.string().required('Bank name is required'),
    bankAlias: Yup.string().required('Bank alias is required'),
    bankCode: Yup.string().required('Bank code is required')
  });

  const formik = useFormik({
    initialValues: {
      bankName: pesalinkData.bankName,
      bankCode: pesalinkData.bankCode,
      bankAlias: pesalinkData.bankAlias,
      updatedBy: pesalinkData.approvedBy,
      isApproved: false
    },
    validationSchema: UpdatePesalinkSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await updatePesalink({ values, dispatch, pesalinkId });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update Pesalink</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="bankName"
                type="text"
                margin="normal"
                label="Bank Name"
                {...getFieldProps('bankName')}
                error={Boolean(touched.bankName && errors.bankName)}
                helperText={touched.bankName && errors.bankName}
              />
              <TextField
                fullWidth
                autoComplete="bankAlias"
                type="text"
                margin="normal"
                label="Bank Alias"
                {...getFieldProps('bankAlias')}
                error={Boolean(touched.bankAlias && errors.bankAlias)}
                helperText={touched.bankAlias && errors.bankAlias}
              />
              <TextField
                fullWidth
                autoComplete="bankCode"
                type="text"
                margin="normal"
                label="Bank Code"
                {...getFieldProps('bankCode')}
                error={Boolean(touched.bankCode && errors.bankCode)}
                helperText={touched.bankCode && errors.bankCode}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
