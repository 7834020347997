import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { assignSupport } from '../../redux/actions/support';
import { secureapi } from '../../config/secureapi';

AssignSupport.propTypes = {
  supportId: PropTypes.string,
  supportData: PropTypes.object,
  profileId: PropTypes.string
};

export default function AssignSupport({ supportId, supportData, profileId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const AssignSchema = Yup.object().shape({
    assignedTo: Yup.string().required('You have to assign a user')
  });
  let results;
  // handle resources selection
  const [users, setUsers] = useState([]);
  async function getUsersList() {
    const response = await secureapi(`/users/all`, {});
    results = response.data;
    setUsers(
      results.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id
      }))
    );
  }
  const formik = useFormik({
    initialValues: {
      assignedTo: supportData.assignedto ? supportData.assignedto : ''
    },
    validationSchema: AssignSchema,
    onSubmit: async (values) => {
      const response = await assignSupport({ values, dispatch, supportId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });
  const { handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary={
            supportData.assignedby === profileId
              ? 'ReAssign Support Ticket'
              : 'Assign Support Ticket'
          }
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>{`Assign Support Ticket number: ${supportData.code}`}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                Support
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${supportData.code} by 
                  ${supportData.createdby && sentenceCase(supportData.createdby)} of phone number ${
                    supportData.creatorphone && supportData.creatorphone
                  }`}
                </span>{' '}
                will be assigned.
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Assignee</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('assignedTo')}
                  name="assignedTo"
                  label="Assignee"
                >
                  {users.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button type="submit" color="success">
                Assign
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
