import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import * as _ from 'lodash';
import { updateMember } from '../../redux/actions/members';
import { addWarningMessage } from '../../redux/actions/notification';

// to check the correct data types and data is passed
UpdateMember.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};

export default function UpdateMember({ memberId, memberData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const initialData = {
    firstName: memberData.firstname,
    lastName: memberData.lastname,
    phoneNumber: memberData.phonenumber,
    isSuspended: memberData.issuspended
  };

  // phone number and id validator regex
  const numberRegex = /^(?:254|\+254|0)?(7|1?[0-9]{9})$/;

  const UpdateMemberSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string()
      .matches(numberRegex, 'Phone Number is not valid')
      .required('Phone Number is required'),
    nationalId: Yup.string()
      .required()
      .min(7, 'National ID must have at least 7 characters')
      .max(9, 'National ID must have at most 9 characters'),
    isSuspended: Yup.string().required('Status is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: memberData.firstname,
      lastName: memberData.lastname,
      phoneNumber: memberData.phonenumber,
      nationalId: memberData.nationalid,
      isSuspended: memberData.issuspended
    },
    validationSchema: UpdateMemberSchema,
    onSubmit: async (values, { resetForm }) => {
      if (_.isEqual(values, initialData)) {
        dispatch(addWarningMessage(`Please make a change first`));
      } else {
        const response = await updateMember({ values, dispatch, memberId });
        if (response.status === 200) {
          resetForm();
          handleClose();
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Member</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="firstName"
                type="text"
                margin="normal"
                label="First Name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <TextField
                fullWidth
                autoComplete="lastName"
                type="text"
                margin="normal"
                label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
              <TextField
                fullWidth
                autoComplete="phoneNumber"
                type="text"
                margin="normal"
                label="Phone Number"
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />
              <TextField
                fullWidth
                disabled="true"
                autoComplete="nationalId"
                type="text"
                margin="normal"
                label="National ID"
                {...getFieldProps('nationalId')}
                error={Boolean(touched.nationalId && errors.nationalId)}
                helperText={touched.nationalId && errors.nationalId}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
