import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box
} from '@mui/material';
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
//
// import { getUsers } from '../redux/actions/user';
import EmptyState from '../EmptyState';
import { fDate } from '../../utils/formatTime';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { TicketListHead, TicketListToolbar } from '../_dashboard/tickets';
import TicketsMoreMenu from '../_dashboard/tickets/TicketMoreMenu';
import { getTickets } from '../../redux/actions/tickets';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: 'Ticket Code', alignRight: false },
  { id: 'createdby', label: 'Customer', alignRight: false },
  { id: 'creatorphone', label: 'Phone Number', alignRight: false },
  { id: 'ticketGroup', label: 'Ticket Group', alignRight: false },
  { id: 'ticketdescription', label: 'Description', alignRight: false },
  { id: 'createat', label: 'Created At', alignRight: false },
  { id: 'resolved', label: 'Resolved', alignRight: false },
  { id: '', label: 'More', alignRight: true }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_ticket) =>
        _ticket.creatorphone.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _ticket.createdby.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function AssignedTickets() {
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.ticketsReducer.isLoading);
  const tickets = useSelector((state) => state.reducer.ticketsReducer.ticketList);
  const totalTickets = useSelector((state) => state.reducer.ticketsReducer.count);
  const page = useSelector((state) => state.reducer.ticketsReducer.page);
  const pageSize = useSelector((state) => state.reducer.ticketsReducer.pageSize);
  const profileId = JSON.parse(localStorage.getItem('profile')).id;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tickets.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, page) => {
    getTickets({
      dispatch,
      page: page + 1,
      pageSize,
      assignedTo: profileId
    });
  };

  const handleChangeRowsPerPage = (event) => {
    getTickets({
      dispatch,
      page,
      pageSize: parseInt(event.target.value, 10),
      assignedTo: profileId
    });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const filteredTickets = applySortFilter(tickets, getComparator(order, orderBy), filterName);
  const isTicketNotFound = filteredTickets.length === 0;
  useEffect(() => {
    getTickets({
      dispatch,
      page: 1,
      pageSize: 10,
      assignedTo: profileId
    });
  }, [dispatch, profileId]);

  return (
    <Box>
      <TicketListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : tickets.length < 1 ? (
        <EmptyState message="No tickets available" />
      ) : (
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TicketListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tickets.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredTickets.map((row) => {
                    const {
                      id,
                      createdby,
                      creatorphone,
                      ticketgroup,
                      ticketdescription,
                      resolved,
                      createdat,
                      code,
                      // eslint-disable-next-line no-unused-vars
                      assignername,
                      // eslint-disable-next-line no-unused-vars
                      assigneename,
                      // eslint-disable-next-line no-unused-vars
                      assignedat
                    } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell>
                        <TableCell align="left">{code}</TableCell>
                        <TableCell align="left">{createdby}</TableCell>
                        <TableCell align="left">{creatorphone}</TableCell>
                        <TableCell align="left">{ticketgroup}</TableCell>
                        <TableCell align="left">{ticketdescription}</TableCell>
                        <TableCell align="left">{createdat && fDate(createdat)}</TableCell>
                        <TableCell align="left">
                          <Typography color={resolved ? 'secondary' : 'error'}>
                            {resolved ? 'Resolved' : 'Pending'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <TicketsMoreMenu ticketId={id} ticketData={row} profileId={profileId} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isTicketNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalTickets}
            rowsPerPage={pageSize}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Box>
  );
}
