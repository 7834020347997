import axios from 'axios';

const authConfig = {
  baseURL: process.env.REACT_APP_MERCHANTS_BACKEND,
  headers: {
    // Authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
    'public-key-pins': `max-age=${process.env.REACT_APP_MAX_AGE};pin-sha256="${process.env.REACT_APP_PUBLIC_KEY_PIN}";`
  }
};

const instance = axios.create(authConfig);

class APIError extends Error {
  constructor(statusCode, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.statusCode = statusCode;
    this.message = message;
  }
}

const errorHandler = (err) => {
  try {
    if (err.response) {
      throw new APIError(err.response.status, err.response.data);
    } else if (err.request) {
      throw new APIError(503, err.request.data);
    } else throw APIError(400, err.data.errors);
  } catch (e) {
    return e;
  }
};

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(errorHandler(error))
);

export const openapiMerchants = instance;
