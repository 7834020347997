import * as React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { addNewConfig } from '../../redux/actions/configs';
import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CreateConfigSchema = Yup.object().shape({
    name: Yup.string().required('Config Name is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      value: ''
    },
    validationSchema: CreateConfigSchema,
    onSubmit: async (values, { resetForm }) => {
      values.value = inputValues;
      const response = await addNewConfig({ values, dispatch });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Config
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Add a new config</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Config Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
              <JSONInput
                id="user_json"
                colors={lightMitsuketaTribute}
                placeholder={{ test: 'Something' }}
                confirmGood={false}
                locale={localeEn}
                style={{
                  outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                  container: { width: '85%', height: '95%' },
                  contentBox: { color: '7E7E7E' }
                }}
                height="250px"
                onChange={(input) => {
                  setInputValues(input.json);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
