export const ADD_NOTIF_MESSAGE = 'ADD_NOTIF_MESSAGE';

export const ADD_NOTIF_SUCCESS_MESSAGE = 'ADD_NOTIF_SUCCESS_MESSAGE';

export const ADD_NOTIF_ERROR_MESSAGE = 'ADD_NOTIF_ERROR_MESSAGE';

export const ADD_NOTIF_WARNING_MESSAGE = 'ADD_NOTIF_WARNING_MESSAGE';

export const CLEAR_NOTIF_MESSAGE = 'CLEAR_NOTIF_MESSAGE';

export const START_LOADING_NOTIF_MESSAGE = 'START_LOADING_NOTIF_MESSAGE';

export const END_LOADING_NOTIF_MESSAGE = 'END_LOADING_NOTIF_MESSAGE';

export const LOAD_NOTIF_MESSAGE = 'LOAD_NOTIF_MESSAGE';

export const START_UPDATE_NOTIF_MESSAGE = 'START_UPDATE_NOTIF_MESSAGE';

export const END_UPDATE_NOTIF_MESSAGE = 'END_UPDATE_NOTIF_MESSAGE';
