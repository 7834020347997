// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
// layouts

// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import AuthorizationForm from '../components/authentication/authozrization/authorizationForm';
import NotificationMessage from '../components/notificationMessage';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled('div')(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center'
  // margin: theme.spacing(2, 0, 0, 2)
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  marginTop: 20,
  display: 'flex',
  height: '90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 5)
}));

// ----------------------------------------------------------------------

export default function Authorization() {
  return (
    <RootStyle title="Login | Fortune Sacco">
      <MHidden width="mdDown">
        <SectionStyle>
          <img src="/static/login-background.svg" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm" sx={{ mr: 'unset' }}>
        <NotificationMessage />
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Welcome to Fortune Sacco Web Portal
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your authorization code below.
            </Typography>
          </Stack>
          <AuthorizationForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
