import {
  END_LOADING_MERCHANTS,
  LOAD_MERCHANTS,
  START_LOADING_MERCHANTS
} from '../constants/merchantsConstants';

const initialState = {
  isLoadingMerchants: false,
  merchantsList: [],
  sortOrder: 1,
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const merchantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_MERCHANTS:
      return { ...state, isLoadingMerchants: true };
    case LOAD_MERCHANTS:
      return {
        ...state,
        merchantsList: action.data.payload.transfers,
        count: action.data.payload.count,
        searchValue: action.data.searchValue
      };
    case END_LOADING_MERCHANTS:
      return { ...state, isLoadingMerchants: false };
    default:
      return state;
  }
};

export default merchantsReducer;
