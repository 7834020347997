import {
  START_LOADING_CHARGES,
  LOAD_CHARGES,
  END_LOADING_CHARGES,
  START_ADD_CHARGE,
  ADD_CHARGE_SUCCESS,
  END_ADD_CHARGE,
  START_LOADING_CHARGE_GROUPS,
  LOAD_CHARGE_GROUPS,
  END_LOADING_CHARGE_GROUPS,
  START_ADD_CHARGE_GROUP,
  ADD_CHARGE_GROUP_SUCCESS,
  END_ADD_CHARGE_GROUP,
  START_EDIT_CHARGE_GROUP,
  EDIT_CHARGE_GROUP_SUCCESS,
  END_EDIT_CHARGE_GROUP
} from '../constants/chargeConstants';

const initialState = {
  isLoading: false,
  isLoadingCharges: false,
  isAddChargeSuccess: false,
  isAddChargeLoading: false,
  isEditChargeLoading: false,
  isEditChargeSuccess: false,
  isDeleteChargeSuccess: false,
  isLoadingChargeGroups: false,
  isAddChargeGroupLoading: false,
  isAddChargeGroupSuccess: false,
  isEditChargeGroupLoading: false,
  isEditChargeGroupSuccess: false,
  chargesList: [],
  chargeGroupList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const chargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_CHARGES:
      return { ...state, isLoadingCharges: true };
    case END_LOADING_CHARGES:
      return { ...state, isLoadingCharges: false };
    case START_ADD_CHARGE:
      return { ...state, isAddChargeLoading: true };
    case ADD_CHARGE_SUCCESS:
      return { ...state, isAddChargeSuccess: true };
    case END_ADD_CHARGE:
      return { ...state, isAddChargeLoading: false };
    case LOAD_CHARGES:
      return { ...state, chargesList: action.payload };
    case START_LOADING_CHARGE_GROUPS:
      return { ...state, isLoadingChargeGroups: true };
    case END_LOADING_CHARGE_GROUPS:
      return { ...state, isLoadingChargeGroups: false };
    case START_ADD_CHARGE_GROUP:
      return { ...state, isAddChargeGroupLoading: true };
    case END_ADD_CHARGE_GROUP:
      return { ...state, isAddChargeGroupLoading: false };
    case ADD_CHARGE_GROUP_SUCCESS:
      return { ...state, isAddChargeGroupSuccess: true };
    case LOAD_CHARGE_GROUPS:
      return { ...state, chargeGroupList: action.payload };
    case START_EDIT_CHARGE_GROUP:
      return { ...state, isEditChargeGroupLoading: true };
    case EDIT_CHARGE_GROUP_SUCCESS:
      return { ...state, isEditChargeGroupSuccess: true };
    case END_EDIT_CHARGE_GROUP:
      return { ...state, isEditChargeGroupLoading: false };
    default:
      return state;
  }
};

export default chargesReducer;
