import {
  START_LOADING_DASHBOARD_SUMMARY,
  LOAD_DASHBOARD_SUMMARY,
  END_LOADING_DASHBOARD_SUMMARY
} from '../constants/dashboardConstants';

const initialState = {
  isLoading: false,
  totalTransactionCount: 0,
  dailyTransactionCount: 0,
  weeklyTransactionCount: 0,
  monthlyTransactionCount: 0,
  totalTransactionSum: 0,
  dailyTransactionSum: 0,
  weeklyTransactionSum: 0,
  monthlyTransactionSum: 0,
  totalCustomerCount: 0,
  dailyCustomerCount: 0,
  weeklyCustomerCount: 0,
  monthlyCustomerCount: 0,
  mpesaTransactionCount: 0,
  successfulTransactionCountByType: [],
  failedTransactionCountByType: [],
  failedMpesaTransactionReport: [],
  successMpesaTransactionReport: [],
  b2cBalances: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_DASHBOARD_SUMMARY:
      return { ...state, isLoading: true };

    case LOAD_DASHBOARD_SUMMARY:
      return {
        ...state,
        totalTransactionCount: action.payload.main.totalTransactionCount,
        dailyTransactionCount: action.payload.main.dailyTransactionCount,
        weeklyTransactionCount: action.payload.main.weeklyTransactionCount,
        monthlyTransactionCount: action.payload.main.monthlyTransactionCount,
        totalTransactionSum: action.payload.main.totalTransactionSum,
        dailyTransactionSum: action.payload.main.dailyTransactionSum,
        weeklyTransactionSum: action.payload.main.weeklyTransactionSum,
        monthlyTransactionSum: action.payload.main.monthlyTransactionSum,
        totalCustomerCount: action.payload.main.totalCustomerCount,
        dailyCustomerCount: action.payload.main.dailyCustomerCount,
        weeklyCustomerCount: action.payload.main.weeklyCustomerCount,
        monthlyCustomerCount: action.payload.main.monthlyCustomerCount,
        mpesaTransactionCount: action.payload.main.mpesaTransactionCount,
        successfulTransactionCountByType: action.payload.successfulTransactionCountByType,
        failedTransactionCountByType: action.payload.failedTransactionCountByType,
        successMpesaTransactionReport: action.payload.successMpesaTransactionReport,
        failedMpesaTransactionReport: action.payload.failedMpesaTransactionReport,
        b2cBalances: action.payload.balances
      };

    case END_LOADING_DASHBOARD_SUMMARY:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default dashboardReducer;
