import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getTransactionById } from '../../redux/actions/transactions';
import { fDate } from '../../utils/formatTime';
import EmptyState from '../EmptyState';

ViewTransaction.propTypes = {
  transactionId: PropTypes.string

  //   transactions: PropTypes.array
};

const rowDetails = {
  display: 'flex',
  marginBottom: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '40%',
  margin: 'auto'
}));

export default function ViewTransaction({ transactionId }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const isLoading = useSelector((state) => state.reducer.transactionsReducer.isLoadingTransaction);
  const transactionData = useSelector((state) => state.reducer.transactionsReducer.transactionData);

  useEffect(() => {
    getTransactionById({ dispatch, id: transactionId });
  }, [dispatch, transactionId]);

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>View Transaction Details</DialogTitle>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : !transactionData ? (
          <EmptyState message="No transactions available" />
        ) : (
          transactionData.customerDetails && (
            <DialogContent>
              <Grid container spacing={2} mt={3}>
                <Paper sx={{ width: '45%', paddingLeft: 3, marginRight: 4 }} elevation={4}>
                  <Box sx={{ width: '50%' }}>
                    <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                      Member Name: {transactionData.customerDetails.firstName}{' '}
                      {transactionData.customerDetails.lastName}
                    </Typography>
                  </Box>
                </Paper>
                <Paper sx={{ width: '45%', paddingLeft: 3, marginRight: 4 }} elevation={4}>
                  <Box style={rowDetails}>
                    <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                      Phone Number:
                      <p>{transactionData.customerDetails.phoneNumber}</p>
                    </Typography>
                  </Box>
                </Paper>
                <Paper sx={{ width: '100%', paddingLeft: 3, marginTop: 4 }} elevation={4}>
                  <Box style={rowDetails} sx={{ width: '100%' }}>
                    <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                      Device Information:
                    </Typography>
                  </Box>
                  <Box sx={{ width: '90%' }} style={rowDetails}>
                    <Typography variant="subtitle2">
                      {transactionData.transfer.deviceInfo || ' '}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid container spacing={2} mt={3}>
                <Paper sx={{ width: '50%', paddingLeft: 3, marginRight: 4 }} elevation={4}>
                  <Box sx={{ width: '100%' }}>
                    <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                      GL TRANSFER
                    </Typography>
                  </Box>
                  <Box style={rowDetails}>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong style={{ marginRight: '2px' }}> Account From :</strong>{' '}
                      {transactionData.transfer.accountFrom}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong>Amount:</strong> {transactionData.transfer.amount}
                    </Typography>
                  </Box>
                  <Box style={rowDetails}>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong>Gl To :</strong> {transactionData.transfer.accountTo}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong> Transfer Reference:</strong>{' '}
                      {transactionData.transfer.transferReference}
                    </Typography>
                  </Box>
                  <Box style={rowDetails}>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong> Created At:</strong>{' '}
                      {transactionData.transfer.createdAt
                        ? fDate(transactionData?.transfer.createdAt)
                        : ''}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ width: '50%' }}>
                      <strong>Transfer Type :</strong> {transactionData.transfer.transferType}
                    </Typography>
                  </Box>
                  <Box style={rowDetails}>
                    <Typography variant="subtitle2" sx={{ width: '100%' }}>
                      <strong>Status :</strong>{' '}
                      {transactionData.transfer?.transferStatus !== null
                        ? transactionData.transfer?.transferStatus
                        : 'NULL'}
                    </Typography>
                  </Box>
                </Paper>
                {((transactionData.transfer.transferType === 'MPESA_B2C' &&
                  transactionData.payment) ||
                  (transactionData.transfer.transferType === 'MPESA_C2B' &&
                    transactionData.payment) ||
                  (transactionData.transfer.transferType === 'MPESA_B2B' &&
                    transactionData.payment) ||
                  (transactionData.transfer.transferType === 'MOBILE_LOAN' &&
                    transactionData.payment) ||
                  (transactionData.transfer.transferType === 'BUY_FLOAT' &&
                    transactionData.payment)) &&
                transactionData.payment.length ? (
                  <Paper sx={{ width: '45%', paddingLeft: 3, marginRight: 4 }} elevation={4}>
                    {transactionData.payment.map((pay) => (
                      <Box key={pay.id}>
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                            {transactionData.payment.length === 1 ? "PROVIDER'S" : pay.provider}
                            {` RESPONSE`}
                          </Typography>
                        </Box>
                        <Box style={rowDetails}>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            <strong>
                              Status:{' '}
                              <span>
                                {' '}
                                {pay.status === 'SENT_EXTERNAL' ? 'PROCESSING' : pay.status}
                              </span>
                            </strong>
                          </Typography>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            <strong>
                              Provider: <span> {pay.provider}</span>
                            </strong>
                          </Typography>
                        </Box>
                        <Box style={rowDetails}>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            <strong style={{ marginRight: '1rem' }}>
                              Recipient Phone Number:{' '}
                              <span> {pay.paymentMetadata.phoneNumber}</span>
                            </strong>
                          </Typography>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            Account Number: <span> {pay.paymentMetadata.accountNumber}</span>
                          </Typography>
                        </Box>
                        <Box style={rowDetails}>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            <strong style={{ marginLeft: '1rem' }}>
                              Updated At: <span>{pay.updatedAt ? fDate(pay.updatedAt) : ''}</span>
                            </strong>
                          </Typography>
                          <Typography variant="subtitle2" sx={{ width: '50%' }}>
                            <strong style={{ marginLeft: '1rem' }}>
                              Transfer reference:{' '}
                              <span> {pay.customerReceipt ? pay.customerReceipt : ''}</span>
                            </strong>
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Paper>
                ) : (
                  ''
                )}
              </Grid>
            </DialogContent>
          )
        )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
