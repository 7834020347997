import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Box,
  Card,
  Checkbox,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import {
  TransactionListHead,
  TransactionListToolbar,
  TransactionMoreMenu
} from '../_dashboard/transactions';
//
import EmptyState from '../EmptyState';
import { fDate } from '../../utils/formatTime';
import { getAllTransactions } from '../../redux/actions/transactions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'transferReference', label: 'Internal Reference', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'accountFrom', label: 'From', alignRight: false },
  { id: 'accountTo', label: 'To', alignRight: false },
  { id: 'transferType', label: 'Type', alignRight: false },
  { id: 'transferStatus', label: 'Status', alignRight: false },
  { id: 'payment.status', label: 'Payment Status', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_transfer) =>
        _transfer.transferReference.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _transfer.accountFrom.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PendingTransactions() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('transferReference');
  const [filterName, setFilterName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('MPESA_B2C');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.transactionsReducer.isLoadingTransactions);
  const transactions = useSelector((state) => state.reducer.transactionsReducer.transactionsList);
  const totalTransactions = useSelector((state) => state.reducer.transactionsReducer.count);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = transactions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllTransactions({
      dispatch,
      transferType: selectedGroup,
      page: newPage + 1,
      pageSize: rowsPerPage,
      status: 'SENT_EXTERNAL'
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getAllTransactions({
      dispatch,
      transferType: selectedGroup,
      page: page + 1,
      pageSize: parseInt(event.target.value, 10),
      status: 'SENT_EXTERNAL'
    });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };

  const filteredTransactions = applySortFilter(
    transactions,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredTransactions.length === 0;
  useEffect(() => {
    getAllTransactions({
      dispatch,
      transferType: selectedGroup,
      page: 1,
      pageSize: rowsPerPage,
      status: 'SENT_EXTERNAL'
    });
  }, [dispatch]);
  return (
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          Pending Transactions
        </Typography>
      </Box>
      <TransactionListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        setGroup={handleGroupChange}
        isPending
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : transactions && transactions.length < 1 ? (
        <EmptyState message="No transactions available" />
      ) : (
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TransactionListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={transactions.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredTransactions.map((row) => {
                    const {
                      transferId,
                      amount,
                      transferReference,
                      transferStatus,
                      accountFrom,
                      accountTo,
                      transferType,
                      createdAt,
                      deviceInfo,
                      payment
                    } = row;
                    const isItemSelected = selected.indexOf(transferId) !== -1;

                    return (
                      <TableRow
                        hover
                        key={transferId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, transferId)}
                          />
                        </TableCell>
                        <TableCell align="left">{transferReference}</TableCell>
                        <TableCell align="left">{amount}</TableCell>
                        <TableCell align="left">{accountFrom}</TableCell>
                        <TableCell align="left">{accountTo}</TableCell>
                        <TableCell align="left">{transferType}</TableCell>
                        <TableCell align="left">{transferStatus}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {payment[0] ? payment[0].status : 'NULL'}
                        </TableCell>
                        <TableCell align="left" sx={{ display: 'none' }}>
                          {deviceInfo}
                        </TableCell>
                        <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                        <TableCell align="right">
                          <TransactionMoreMenu transactionId={transferId} transactionData={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalTransactions}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Box>
  );
}
