import { secureapi } from '../../config/secureapi';
import {
  ADD_PERMISSION_SUCCESS,
  EDIT_PERMISSION_SUCCESS,
  START_ADD_PERMISSION,
  END_ADD_PERMISSION,
  START_EDIT_PERMISSION,
  END_EDIT_PERMISSION,
  START_DELETE_PERMISSION,
  END_DELETE_PERMISSION,
  START_LOADING_PERMISSIONS,
  LOAD_PERMISSIONS,
  END_LOADING_PERMISSIONS,
  DELETE_PERMISSION_SUCCESS
} from '../constants/permissionConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startAddPermission = () => ({
  type: START_ADD_PERMISSION
});

const endAddPermission = () => ({
  type: END_ADD_PERMISSION
});

const startEditPermission = () => ({
  type: START_EDIT_PERMISSION
});

const endEditPermission = () => ({
  type: END_EDIT_PERMISSION
});

const startDeletePermission = () => ({
  type: START_DELETE_PERMISSION
});

const endDeletePermission = () => ({
  type: END_DELETE_PERMISSION
});
const addPermissionSuccess = () => ({
  type: ADD_PERMISSION_SUCCESS
});
const editPermissionSuccess = () => ({
  type: EDIT_PERMISSION_SUCCESS
});
const deletePermissionSuccess = () => ({
  type: DELETE_PERMISSION_SUCCESS
});
const startLoadingPermissions = () => ({
  type: START_LOADING_PERMISSIONS
});

const loadPermissions = (payload) => ({
  type: LOAD_PERMISSIONS,
  payload
});

const endLoadingPermissions = () => ({
  type: END_LOADING_PERMISSIONS
});

// get permissions list
export const getPermissions = ({ dispatch }) => {
  dispatch(startLoadingPermissions());
  const url = '/permissions/all';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingPermissions());
      if (!data.data) {
        dispatch(loadPermissions(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadPermissions(data.data));
        // dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingPermissions());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// adds a new permission
export const addNewPermission = ({ values, dispatch }) => {
  dispatch(startAddPermission());
  const url = '/permissions/create';
  const createdBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, isApproved: false, creatorId: createdBy };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddPermission());
      dispatch(addSuccessMessage(`Permission added successfully`));
      dispatch(addPermissionSuccess());
      getPermissions({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddPermission());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing permission

export const updatePermission = ({ values, dispatch, permissionId }) => {
  dispatch(startEditPermission());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy };
  const url = `/permissions/update/${permissionId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditPermission());
      dispatch(addSuccessMessage(`Permission updated successfully`));
      dispatch(editPermissionSuccess());
      getPermissions({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditPermission());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const deletePermission = ({ dispatch, permissionId }) => {
  dispatch(startDeletePermission());
  const url = `/permissions/delete/${permissionId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeletePermission());
      dispatch(addSuccessMessage(`Permission Deleted successfully`));
      dispatch(deletePermissionSuccess());
      getPermissions({
        dispatch
      });
    })
    .catch(({ message }) => {
      dispatch(endDeletePermission());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
