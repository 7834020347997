export const START_LOADING_LOANS = 'START_LOADING_LOANS';
export const LOAD_LOANS = 'LOAD_LOANS';
export const LOAD_LOAN = 'LOAD_LOAN';
export const END_LOADING_LOANS = 'END_LOADING_LOANS';

export const START_EDIT_LOAN = 'START_EDIT_LOAN';
export const EDIT_LOAN_SUCCESS = 'EDIT_LOAN_SUCCESS';
export const END_EDIT_LOAN = 'END_EDIT_LOAN';

export const START_DELETE_LOAN = 'START_DELETE_LOAN';
export const END_DELETE_LOAN = 'END_DELETE_LOAN';
export const DELETE_LOAN_SUCCESS = 'DELETE_LOAN_SUCCESS';

export const START_ADD_LOAN = 'START_ADD_LOAN';
export const ADD_LOAN_SUCCESS = 'ADD_LOAN_SUCCESS';
export const END_ADD_LOAN = 'END_ADD_LOAN';

export const START_LOADING_LOAN_TYPES = 'START_LOADING_LOAN_TYPES';
export const LOAD_LOAN_TYPES = 'LOAD_LOAN_TYPES';
export const END_LOADING_LOAN_TYPES = 'END_ADD_LOAN_TYPES';

export const START_ADD_CHARGE = 'START_ADD_LOAN';
export const ADD_CHARGE_SUCCESS = 'ADD_CHARGE_SUCCESS';
export const END_ADD_CHARGE = 'END_ADD_LOAN';

export const START_LOADING_CHARGE_GROUPS = 'START_LOADING_CHARGE_GROUPS';
export const LOAD_CHARGE_GROUPS = 'LOAD_CHARGE_GROUPS';
export const END_LOADING_CHARGE_GROUPS = 'END_LOADING_CHARGE_GROUPS';

export const START_ADD_CHARGE_GROUP = 'START_ADD_CHARGE_GROUP';
export const ADD_CHARGE_GROUP_SUCCESS = 'ADD_CHARGE_GROUP_SUCCESS';
export const END_ADD_CHARGE_GROUP = 'END_ADD_CHARGE_GROUP';

export const START_EDIT_CHARGE_GROUP = 'START_EDIT_CHARGE_GROUP';
export const EDIT_CHARGE_GROUP_SUCCESS = 'EDIT_CHARGE_GROUP_SUCCESS';
export const END_EDIT_CHARGE_GROUP = 'END_EDIT_CHARGE_GROUP';

export const START_DELETE_LOANCONFIG = 'START_DELETE_LOANCONFIG';
export const END_DELETE_LOANCONFIG = 'END_DELETE_LOANCONFIG';
export const DELETE_LOANCONFIG_SUCCESS = 'DELETE_DELETE_LOANCONFIG';

export const START_STATUS_LOANCONFIG = 'START_STATUS_LOANCONFIG';
export const STATUS_LOANCONFIG_SUCCESS = 'STATUS_LOANCONFIG_SUCCESS';
export const END_STATUS_LOANCONFIG = 'END_STATUS_LOANCONFIG';
