import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_SUPPORT,
  LOAD_SUPPORT,
  END_LOADING_SUPPORT,
  END_EDIT_SUPPORT,
  START_STATUS_SUPPORT,
  STATUS_SUPPORT_SUCCESS,
  END_STATUS_SUPPORT,
  START_ADD_SUPPORT,
  END_ADD_SUPPORT,
  ADD_SUPPORT_SUCCESS,
  START_DELETE_SUPPORT,
  END_DELETE_SUPPORT,
  DELETE_SUPPORT_SUCCESS
} from '../constants/supportConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startAddSupportTicket = () => ({
  type: START_ADD_SUPPORT
});

const endAddSupportTicket = () => ({
  type: END_ADD_SUPPORT
});

const addSupportTicketSuccess = () => ({
  type: ADD_SUPPORT_SUCCESS
});

const startLoadingSupport = () => ({
  type: START_LOADING_SUPPORT
});

const loadSupport = (payload, page, pageSize) => ({
  type: LOAD_SUPPORT,
  payload: { payload, page, pageSize }
});

const endLoadingSupport = () => ({
  type: END_LOADING_SUPPORT
});

const endEditSupport = () => ({
  type: END_EDIT_SUPPORT
});

const startStatusSupport = () => ({
  type: START_STATUS_SUPPORT
});

const endStatusSupport = () => ({
  type: END_STATUS_SUPPORT
});

const statusSupportSuccess = () => ({
  type: STATUS_SUPPORT_SUCCESS
});

const startDeleteSupport = () => ({
  type: START_DELETE_SUPPORT
});
const endDeleteSupport = () => ({
  type: END_DELETE_SUPPORT
});

const deleteSupportSuccess = () => ({
  type: DELETE_SUPPORT_SUCCESS
});

const getRequestParams = ({
  page,
  pageSize,
  supportGroup,
  sortOrder,
  sortField,
  assignedTo,
  assignedBy
}) => {
  const params = {};

  if (supportGroup) {
    params.supportGroup = supportGroup;
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (pageSize) {
    params.sortOrder = sortOrder;
  }
  if (pageSize) {
    params.sortField = sortField;
  }
  if (assignedTo) {
    params.assignedTo = assignedTo;
  }
  if (assignedBy) {
    params.assignedBy = assignedBy;
  }
  return params;
};

export const getSupport = async ({
  dispatch,
  ticketGroup,
  page,
  pageSize,
  assignedTo,
  assignedBy
}) => {
  dispatch(startLoadingSupport());
  const url = '/support';

  const params = getRequestParams({
    ticketGroup,
    page,
    pageSize,
    assignedTo,
    assignedBy
  });

  try {
    const data = await secureapi.get(url, { params });
    dispatch(endLoadingSupport());
    if (!data.data) {
      dispatch(loadSupport(data.data, page, pageSize));
      dispatch(addWarningMessage('No results found.'));
    } else {
      dispatch(loadSupport(data.data, page, pageSize));
    }
  } catch ({ message }) {
    dispatch(endLoadingSupport());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};

export const addNewSupportTicket = ({ values, dispatch }) => {
  dispatch(startAddSupportTicket());
  const url = '/support/create';
  const createdBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddSupportTicket());
      dispatch(addSuccessMessage(`Support Ticket added successfully`));
      dispatch(addSupportTicketSuccess());
      getSupport({
        dispatch,
        page: 1,
        pageSize: 10
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddSupportTicket());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// eslint-disable-next-line consistent-return
export const updateSupportStatus = async ({ values, dispatch, supportId }) => {
  dispatch(startStatusSupport());
  const url = `/support/update/${supportId}`;
  try {
    const data = await secureapi.put(url, values);
    dispatch(endStatusSupport());
    dispatch(addSuccessMessage('Support updated successfully'));
    dispatch(statusSupportSuccess());
    getSupport({ dispatch, page: 1, pageSize: 10 });
    return data;
  } catch ({ message }) {
    dispatch(endEditSupport());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};

// eslint-disable-next-line consistent-return
export const assignSupport = async ({ values, dispatch, supportId }) => {
  dispatch(startStatusSupport());
  const url = `/support/assign/${supportId}`;
  values.assignedBy = JSON.parse(localStorage.getItem('profile')).id;
  try {
    const data = await secureapi.put(url, values);
    dispatch(endStatusSupport());
    dispatch(addSuccessMessage('Support assigned successfully'));
    dispatch(statusSupportSuccess());
    getSupport({ dispatch, page: 1, pageSize: 10 });
    return data;
  } catch ({ message }) {
    dispatch(endEditSupport());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};
// d9b4e9f3-7033-4533-9dfb-7c314677e20e

export const deleteSupportTicket = ({ dispatch, supportId }) => {
  dispatch(startDeleteSupport());
  const url = `/support/delete/${supportId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteSupport());
      dispatch(addSuccessMessage('Support Ticket Deleted!!!'));
      dispatch(deleteSupportSuccess());
      getSupport({ dispatch, page: 1, pageSize: 10 });
    })
    .catch(({ message }) => {
      dispatch(endDeleteSupport());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
