import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addNewFaq } from '../../redux/actions/faq';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const SORT_CATEGORY = [
    { value: 'general', label: 'General' },
    { value: 'mobileMoney', label: 'Mobile Money Transfer' },
    { value: 'internalTransfer', label: 'Internal Transfer' },
    { value: 'deposit', label: 'Deposit' },
    { value: 'security', label: 'Security' },
    { value: 'loanApplication', label: 'Loan Application' }
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const CreateFaqSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
    category: Yup.string().required('Category is required')
  });

  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
      category: ''
    },
    validationSchema: CreateFaqSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewFaq({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New FAQ
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new faq</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="question"
                type="text"
                margin="normal"
                label="Question"
                multiline
                {...getFieldProps('question')}
                error={Boolean(touched.question && errors.question)}
                helperText={touched.question && errors.question}
              />
              <TextField
                fullWidth
                autoComplete="answer"
                multiline
                type="text"
                margin="normal"
                label="Answer"
                {...getFieldProps('answer')}
                error={Boolean(touched.answer && errors.answer)}
                helperText={touched.answer && errors.answer}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category"
                  {...getFieldProps('category')}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                >
                  {SORT_CATEGORY.map((category) => (
                    <MenuItem value={category.value} key={category.value}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
