import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const STATUS_TYPE = [
  { value: 'DISBURSED', label: 'DISBURSED' },
  { value: 'PARTIALLY_REPAID', label: 'PARTIALLY REPAID' },
  { value: 'FULLY_PAID', label: 'FULLY PAID' },
  { value: 'OVERDUE', label: 'OVERDUE' },
  { value: 'DEFAULTED', label: 'DEFAULTED' },
  { value: 'WRITTEN_OFF', label: 'WRITTEN OFF' },
  { value: 'INTEREST_WAIVED', label: 'INTEREST WAIVED' },
  { value: 'REQUESTED', label: 'REQUESTED' },
  { value: 'FAILED', label: 'FAILED' }
];

export default function LoanListToolbar({
  numSelected,
  selectedStatus,
  handleStatusChange,
  handleSearchChange,
  handleClearFilters
}) {
  const timeout = process.env.REACT_APP_SEARCH_TIMEOUT || 1000;
  const [filterName, setFilterName] = useState('');

  const filterValue = useSelector((state) => state.reducer.loansReducer.searchValue);

  const debounceSearch = useMemo(() => debounce(handleSearchChange, timeout), []);

  const handleClearAll = () => {
    setFilterName('');
    handleClearFilters();
  };

  const handleSearch = (e) => {
    debounceSearch(e.target.value, selectedStatus);
    setFilterName(e.target.value);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={handleSearch}
          placeholder={filterValue}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      <FormControl size="small" sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Status Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedStatus}
          label="Item"
          onChange={handleStatusChange}
        >
          {STATUS_TYPE.map((status) => (
            <MenuItem value={status.value} key={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Clear Search">
          <IconButton onClick={handleClearAll}>
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}

LoanListToolbar.propTypes = {
  numSelected: PropTypes.number,
  selectedStatus: PropTypes.string,
  handleStatusChange: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleClearFilters: PropTypes.func
};
