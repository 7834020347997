import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { secureapi } from '../../../config/secureapi';
import {
  addErrorMessage,
  addSuccessMessage,
  addWarningMessage
} from '../../../redux/actions/notification';

export default function UploadCSV() {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(new Date());

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // handle file upload event
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    console.log({
      file,
      year: new Date(date).getFullYear(),
      uploadedBy: localStorage.getItem('userId')
    });

    const formData = new FormData();
    formData.append('csv_data', file, file.name);
    formData.append('year', new Date(date).getFullYear());
    formData.append('uploadedBy', localStorage.getItem('userId'));

    const url = `/api/v1/loan/salary_advance/upload`;
    return secureapi
      .post(url, formData)
      .then((data) => {
        console.log('DATA >>> ', data);
        dispatch(addSuccessMessage('File uploaded successfully!'));
        setOpen(false);
        return data;
      })
      .catch(({ message }) => {
        // eslint-disable-next-line no-unused-expressions
        message.errorMessage
          ? dispatch(addErrorMessage(message.errorMessage))
          : dispatch(addErrorMessage(message));
      });
  };

  console.log('UPLOADED FILE >>> ', file);
  console.log('PICKED DATE >>> ', new Date(date).getFullYear());

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<FileUploadIcon />}>
        Upload CSV
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Upload CSV</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: '2px dotted #060843',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: '1rem',
              borderRadius: '10px',
              backgroundColor: 'rgba(6, 8, 87, 0.1)'
            }}
          >
            <input
              style={{ width: '65%', fontFamily: 'Rubik, sans-serif' }}
              type="file"
              name="salary_csv"
              onChange={handleChange}
              accept=".csv"
            />
            <Typography
              sx={{
                fontFamily: 'Rubik, sans-serif',
                mt: '1rem',
                fontWeight: 'bold'
              }}
            >
              Upload Salary CSV
            </Typography>
          </Box>
          <Box sx={{ mt: '1.5rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Pick Date"
                value={date}
                onChange={(newValue) => setDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpload} color="secondary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
