import { useRef, useState, useEffect } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import CheckPaymentStatus from './checkPaymentStatus';
import AccessControl from '../../AccessControl';

PendingResolutionMoreMenu.propTypes = {
  paymentId: PropTypes.string,
  paymentData: PropTypes.object
};

export default function PendingResolutionMoreMenu({ paymentId, paymentData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    setUser(userId);
  }, []);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="TRANSACTION MANAGEMENT" allowedPermissions="canread">
          <CheckPaymentStatus paymentId={paymentId} paymentData={paymentData} userId={user} />
        </AccessControl>
      </Menu>
    </>
  );
}
