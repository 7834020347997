import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Box,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Avatar
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { fDate } from '../../utils/formatTime';
import { getInitials } from '../../utils/get-initials';

ViewUser.propTypes = {
  userData: PropTypes.object
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

export default function ViewUser({ userData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false" fullWidth maxWidth="md">
        <DialogTitle>View User</DialogTitle>
        <DialogContent>
          <Card sx={{ display: 'flex', paddingLeft: 3 }}>
            <CardMedia sx={{ width: 140, paddingTop: 4 }}>
              <Avatar
                alt={userData.firstName}
                sx={{ width: 140, height: 140, backgroundColor: '#060843' }}
              >{`${getInitials(userData.firstName)}${getInitials(userData.lastName)}`}</Avatar>
            </CardMedia>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Email:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.emailAddress}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>First Name:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.firstName}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Last Name:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.lastName}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Branch:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.branch}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Phone Number:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.phoneNumber}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Joined On:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {fDate(userData?.createdAt)}
                  </p>
                </div>
                <div style={div}>
                  <Typography sx={{ fontWeight: 'bold' }}>Is Active:</Typography>
                  <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                    {userData.isApproved ? 'Yes' : 'No'}
                  </p>
                </div>
              </CardContent>
            </Box>
          </Card>
          <Paper item sx={{ width: '100%', paddingLeft: 3 }} elevation={4} mt={2}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6" textAlign="center" color="secondary" mt={2}>
                User Update Audit Trail
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle2" sx={{ width: '50%' }}>
                Last Updated At:
              </Typography>
              <Typography variant="body2" sx={{ width: '50%' }}>
                {userData.lastUpdatedAt && fDate(userData.lastUpdatedAt)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle2" sx={{ width: '50%' }}>
                Last Approved At:
              </Typography>
              <Typography variant="body2" sx={{ width: '50%' }}>
                {userData.lastApprovedAt && fDate(userData.lastApprovedAt)}
              </Typography>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
