import { format, formatDistanceToNow, isDate } from 'date-fns';

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
export function fDate(date) {
  if (isDate(new Date(date))) return format(new Date(date), 'dd MMMM yyyy p');
  return false;
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
