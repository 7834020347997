import PropTypes from 'prop-types';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// material
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
// components
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { MHidden } from '../../components/@material-extend';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { getNotifications } from '../../redux/actions/systemNofitications';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
// const { socket } = IO();

export default function DashboardNavbar({ onOpenSidebar }) {
  const dispatch = useDispatch();
  // const [isConnected, setIsConnected] = useState(false);

  // function startSocket() {
  //   console.log('START SOCKET');
  //   socket.connect();
  //   socket.on('connect', () => {
  //     setIsConnected(true);
  //     console.log('SOCKET ID', socket.id);
  //     console.log('CONNECTED');
  //   });
  //   socket.on('disconnect', () => {
  //     setIsConnected(false);
  //     console.log('DISCONNECTED');
  //   });
  //   socket.on('channel:notification', (data) => {
  //     console.log('socket message received', data);
  //     dispatch(addSuccessMessage('Incoming notification'));
  //     getNotifications({
  //       dispatch
  //     });
  //   });
  // }

  // console.log('IS CONNECTED: ', isConnected);
  useEffect(() => {
    // startSocket();
    getNotifications({
      dispatch
    });
  }, [dispatch]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <MenuOutlinedIcon />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
