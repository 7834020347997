import * as React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { addNewMessage } from '../../redux/actions/messages';
import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

const InputFeedback = ({ error }) =>
  error ? (
    <Typography color="error" sx={{ fontSize: '0.75rem', marginLeft: '1rem', marginTop: '.3rem' }}>
      {error}
    </Typography>
  ) : null;

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [inputValues, setInputValues] = React.useState({});
  const [inputMsg, setInputMsg] = React.useState('');
  const [cursor, setCursor] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessageChange = (e) => {
    setCursor(e.target.selectionStart);
    setInputMsg(e.target.value);
    setCursor(e.target.selectionStart);
  };

  const handleMessageBlur = (e) => {
    setCursor(e.target.selectionStart);
  };

  const handleOptionChange = (option) => {
    setInputMsg(inputMsg.slice(0, cursor) + option + inputMsg.slice(cursor));
  };

  const CreateMessageSchema = Yup.object().shape({
    name: Yup.string().required('Message Name is required'),
    repeate: Yup.string().required('Repeat is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      repeate: '',
      scheduleDetails: ''
    },
    validationSchema: CreateMessageSchema,
    onSubmit: async (values, { resetForm }) => {
      values.scheduleDetails = inputValues;
      values.message = inputMsg;
      const response = await addNewMessage({ values, dispatch });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Message
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Add a new message</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Message Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Accepted Placeholders
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) => handleOptionChange(e.target.value)}
                  name="placeholders"
                  label="Accepted Placeholders"
                >
                  <MenuItem value="{{firstName}}">First Name</MenuItem>
                  <MenuItem value="{{lastName}}">Last Name</MenuItem>
                  <MenuItem value="{{phoneNumber}}">Phone Number</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="message"
                type="text"
                multiline
                margin="normal"
                label="Message"
                value={inputMsg}
                onBlur={(e) => handleMessageBlur(e)}
                onChange={(e) => handleMessageChange(e)}
              />
              {touched.inputMsg && <InputFeedback error={errors.inputMsg} />}
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Repeat Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('chargeGroupId')}
                  name="repeate"
                  label="Repeat Status"
                  error={Boolean(touched.repeate && errors.repeate)}
                  helperText={touched.repeate && errors.repeate}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
              {touched.repeate && <InputFeedback error={errors.repeate} />}
              <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
              <JSONInput
                id="user_json"
                colors={lightMitsuketaTribute}
                placeholder={{ hour: '01', minute: '20', dayOfMonth: '08' }}
                confirmGood={false}
                locale={localeEn}
                style={{
                  outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                  container: { width: '85%', height: '95%' },
                  contentBox: { color: '7E7E7E' }
                }}
                height="250px"
                onChange={(input) => {
                  setInputValues(input.json);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
