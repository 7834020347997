import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ChangeConfigStatus from '../../configs/changeConfigStatus';
import UpdateConfig from '../../configs/Update';
import ApproveConfigUpdate from '../../configs/approveConfigUpdate';
import AccessControl from '../../AccessControl';

// ----------------------------------------------------------------------
ConfigsMoreMenu.propTypes = {
  configId: PropTypes.string,
  configData: PropTypes.object
};

const sessionUser = JSON.parse(localStorage.getItem('profile'))?.id;

export default function ConfigsMoreMenu({ configId, configData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="CONFIGS" allowedPermissions="canupdate">
          <UpdateConfig configId={configId} configData={configData} />
          {configData.isActive || configData.createdBy === sessionUser ? (
            ''
          ) : (
            <ChangeConfigStatus configId={configId} configData={configData} />
          )}
        </AccessControl>
        {configData.pendingUpdateJson !== null && configData.createdBy !== sessionUser ? (
          <AccessControl resource="CONFIGS" allowedPermissions="canexecute">
            <ApproveConfigUpdate configId={configId} configData={configData} />
          </AccessControl>
        ) : (
          ''
        )}
      </Menu>
    </>
  );
}
