import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { AntTabs, AntTab, TabPanel } from '../components/CustomTab';
//
import Charges from '../components/charges';
import ChargeGroups from '../components/chargeGroups';
import Branches from '../components/branches';
import Gl from '../components/gl';
import Billers from '../components/billers';
import BillerCategories from '../components/billerCategories';
import ExternalBillers from '../components/externalBillers/index';
import Loans from '../components/loanConfigs/index';
import Pesalink from '../components/pesalink/index';
import SystemConfigs from '../components/system-configs/system-configs';

// ----------------------------------------------------------------------

export default function Settings() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="System Settings | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            System Settings
          </Typography>
        </Stack>

        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <AntTab label="Charges" />
          <AntTab label="Charge Groups" />
          <AntTab label="Branches" />
          <AntTab label="GL Accounts" />
          <AntTab label="Billers" />
          <AntTab label="Biller Categories" />
          <AntTab label="Loan Configurations" />
          <AntTab label="Cellulant" />
          <AntTab label="Pesalink" />
          <AntTab label="System Configurations" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <Charges />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChargeGroups />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Branches />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Gl />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Billers />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <BillerCategories />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Loans />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <ExternalBillers />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Pesalink />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <SystemConfigs />
        </TabPanel>
      </Container>
    </Page>
  );
}
