import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import ChangeMessageStatus from '../../messages/changeMessageStatus';
import UpdateMessage from '../../messages/Update';
import ApproveMessageUpdate from '../../messages/approveMessageUpdate';

// ----------------------------------------------------------------------
MessagesMoreMenu.propTypes = {
  messageId: PropTypes.string,
  messageData: PropTypes.object
};

const sessionUser = JSON.parse(localStorage.getItem('profile'))?.id;

export default function MessagesMoreMenu({ messageId, messageData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="MESSAGING" allowedPermissions="canupdate">
          <UpdateMessage messageId={messageId} messageData={messageData} />
          {messageData.isActive || messageData.createdBy === sessionUser ? (
            ''
          ) : (
            <ChangeMessageStatus messageId={messageId} messageData={messageData} />
          )}
        </AccessControl>
        {messageData.pendingUpdateJson !== null && messageData.createdBy !== sessionUser ? (
          <AccessControl resource="MESSAGING" allowedPermissions="canexecute">
            <ApproveMessageUpdate messageId={messageId} messageData={messageData} />
          </AccessControl>
        ) : (
          ''
        )}
      </Menu>
    </>
  );
}
