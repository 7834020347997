import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { addNewExternalBiller } from '../../redux/actions/externalBillers';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CreateExternalBillerSchema = Yup.object().shape({
    serviceCategory: Yup.string().required('Service Category is required'),
    serviceName: Yup.string().required('Service Name is required'),
    serviceCode: Yup.string().required('Service Code is required'),
    minimumAmount: Yup.number().required('Minimum Amount is required'),
    maximumAmount: Yup.number().required('Maximum Amount is required')
  });

  const formik = useFormik({
    initialValues: {
      serviceCategory: '',
      serviceName: '',
      serviceCode: '',
      minimumAmount: '',
      maximumAmount: ''
    },
    validationSchema: CreateExternalBillerSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewExternalBiller({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New External Biller
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new external biller</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="serviceCategory"
                type="text"
                margin="normal"
                label="Service Category"
                {...getFieldProps('serviceCategory')}
                error={Boolean(touched.serviceCategory && errors.serviceCategory)}
                helperText={touched.serviceCategory && errors.serviceCategory}
              />
              <TextField
                fullWidth
                autoComplete="serviceName"
                type="text"
                margin="normal"
                label="Service Name"
                {...getFieldProps('serviceName')}
                error={Boolean(touched.serviceName && errors.serviceName)}
                helperText={touched.serviceName && errors.serviceName}
              />
              <TextField
                fullWidth
                autoComplete="serviceCode"
                type="text"
                margin="normal"
                label="Service Code"
                {...getFieldProps('serviceCode')}
                error={Boolean(touched.serviceCode && errors.serviceCode)}
                helperText={touched.serviceCode && errors.serviceCode}
              />
              <TextField
                fullWidth
                autoComplete="minimumAmount"
                type="number"
                margin="normal"
                label="Minimum Amount"
                {...getFieldProps('minimumAmount')}
                error={Boolean(touched.minimumAmount && errors.minimumAmount)}
                helperText={touched.minimumAmount && errors.minimumAmount}
              />
              <TextField
                fullWidth
                autoComplete="maximumAmount"
                type="number"
                margin="normal"
                label="Maximum Amount"
                {...getFieldProps('maximumAmount')}
                error={Boolean(touched.maximumAmount && errors.maximumAmount)}
                helperText={touched.maximumAmount && errors.maximumAmount}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
