import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { AntTabs, AntTab, TabPanel } from '../components/CustomTab';
//

import Loans from '../components/loans/loans';
import SalaryAdvanceLoans from '../components/loans/salary-advance/salary-advance';
// ----------------------------------------------------------------------

export default function AccessManagement() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Loan Management | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Loan Management
          </Typography>
        </Stack>

        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="eCash Mobile Loans" />
          <AntTab label="Salary Advance Loans" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <Loans />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SalaryAdvanceLoans />
        </TabPanel>
      </Container>
    </Page>
  );
}
