import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import ViewLoan from '../../loans/viewLoan';
import UpdateLoan from '../../loans/updateLoan';
// import DeleteLoan from '../../loans/deleteLoan';

LoanMoreMenu.propTypes = {
  loanId: PropTypes.string,
  loanData: PropTypes.object
};

export default function LoanMoreMenu({ loanId, loanData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="LOANS MANAGEMENT" allowedPermissions="canread">
          <ViewLoan loanId={loanId} loanData={loanData} />
        </AccessControl>
        <AccessControl resource="LOANS MANAGEMENT" allowedPermissions="canupdate">
          <UpdateLoan loanId={loanId} loanData={loanData} />
        </AccessControl>
      </Menu>
    </>
  );
}
