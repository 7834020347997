import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import ViewTransaction from '../../transactions/view';

import AccessControl from '../../AccessControl';
import ProcessFloatTransaction from '../../transactions/process';

TransactionMoreMenu.propTypes = {
  transactionId: PropTypes.string,
  transactionData: PropTypes.object
};

function ProcessTransaction(props) {
  return null;
}

ProcessTransaction.propTypes = {};
export default function TransactionMoreMenu({ transactionId, transactionData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="TRANSACTION MANAGEMENT" allowedPermissions="canread">
          <ViewTransaction transactionId={transactionId} transactionData={transactionData} />
        </AccessControl>
        {transactionData.transferType === 'BUY_FLOAT' ? (
          <AccessControl resource="TRANSACTION MANAGEMENT" allowedPermissions="canupdate">
            <ProcessFloatTransaction transferId={transactionId} transferData={transactionData} />
          </AccessControl>
        ) : null}
      </Menu>
    </>
  );
}
