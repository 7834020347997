import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CheckOutlined } from '@mui/icons-material';
import { approveUpdateUser } from '../../redux/actions/user';
import { secureapi } from '../../config/secureapi';
import { getBranches } from '../../redux/actions/branches';

ApproveUpdateUser.propTypes = {
  userData: PropTypes.object,
  id: PropTypes.string
};

export default function ApproveUpdateUser({ id, userData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // const [isChecked, setIsChecked] = React.useState(userData.isSuperUser);
  const branches = useSelector((state) => state.reducer.branchesReducer.branchesList);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleCheck = (e) => {
  //   setIsChecked(e.target.checked);
  // };

  // phone number validator regex
  const numberRegex = /^(?:254|\+254|0)?(7|1?[0-9]{9})$/;

  // handle roles selection
  const [roleItems, setRoleItems] = useState([]);
  let results;
  async function getRolesList() {
    const response = await secureapi(`/roles/all`, {});
    results = response.data.data;
    setRoleItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string()
      .matches(numberRegex, 'Phone Number is not valid')
      .required('Phone Number is required'),
    branch: Yup.string().nullable(),
    emailAddress: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const {
    approvedBy,
    branch,
    emailAddress,
    firstName,
    isApproved,
    isSuperUser,
    lastUpdatedBy,
    lastName,
    phoneNumber,
    role,
    updatedAt
  } = userData.pendingUpdateJson;
  const formik = useFormik({
    initialValues: {
      approvedBy,
      branch,
      emailAddress,
      firstName,
      isApproved,
      isSuperUser,
      lastUpdatedBy,
      lastName,
      phoneNumber,
      roleId: role,
      updatedAt
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { resetForm }) => {
      values.isSuperUser = isSuperUser;
      values.isApproved = isApproved;
      const response = await approveUpdateUser({ values, dispatch, id });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });
  useEffect(() => {
    getRolesList();
    getBranches({
      dispatch
    });
  }, []);
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  // console.log('isChecked: ', isChecked);
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckOutlined width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Approve system user update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Typography color="error">Previous Data</Typography>
                  <TextField
                    disabled
                    margin="normal"
                    id="prevFirstName"
                    label="FirstName"
                    variant="outlined"
                    defaultValue={userData.firstName}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevLastName"
                    label="LastName"
                    variant="outlined"
                    defaultValue={userData.lastName}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevPhoneNumber"
                    label="Phone Number"
                    variant="outlined"
                    defaultValue={userData.phoneNumber}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled
                      defaultValue={userData.role ? userData.role.id : null}
                      name="roleId"
                      label="Role"
                    >
                      <MenuItem key="none" value="">
                        None
                      </MenuItem>
                      {roleItems.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
                    <Select
                      disabled
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue={userData.branch}
                      name="branch"
                      label="Branch"
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.name}>
                          {branch.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    disabled
                    fullWidth
                    autoComplete="emailAddress"
                    type="email"
                    margin="normal"
                    label="Email address"
                    defaultValue={userData.emailAddress}
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox checked={userData.isSuperUser} name="isSuperUser" color="primary" />
                    }
                    label="Is Super User"
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox checked={userData.isApproved} name="isApproved" color="primary" />
                    }
                    label="Is Active User"
                  />
                </Grid>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    disabled
                    fullWidth
                    autoComplete="firstName"
                    type="text"
                    margin="normal"
                    label="First Name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  <TextField
                    disabled
                    fullWidth
                    autoComplete="lastName"
                    type="text"
                    margin="normal"
                    label="Last Name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                  <TextField
                    disabled
                    fullWidth
                    autoComplete="phoneNumber"
                    type="text"
                    margin="normal"
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      disabled
                      {...getFieldProps('roleId')}
                      name="roleId"
                      label="Role"
                    >
                      <MenuItem key="none" value="">
                        None
                      </MenuItem>
                      {roleItems.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
                    <Select
                      disabled
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      {...getFieldProps('branch')}
                      name="branch"
                      label="Branch"
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.name}>
                          {branch.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    disabled
                    fullWidth
                    autoComplete="emailAddress"
                    type="email"
                    margin="normal"
                    label="Email address"
                    {...getFieldProps('emailAddress')}
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    helperText={touched.emailAddress && errors.emailAddress}
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={isSuperUser}
                        // onChange={handleCheck}
                        name="isSuperUser"
                        color="primary"
                      />
                    }
                    label="Is Super User"
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={isApproved}
                        // onChange={handleCheck}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label="Is Active User"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
