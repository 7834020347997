import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { updateMessage } from '../../redux/actions/messages';

import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

UpdateMessage.propTypes = {
  messageId: PropTypes.string,
  messageData: PropTypes.object
};

export default function UpdateMessage({ messageId, messageData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateMessageSchema = Yup.object().shape({
    name: Yup.string().required('Message Name is required'),
    message: Yup.string().required('Message is required'),
    repeate: Yup.string().required('Repeat is required')
  });

  const formik = useFormik({
    initialValues: {
      name: messageData.name,
      message: messageData.message,
      repeat: messageData.repeat,
      scheduleDetails:
        typeof messageData.scheduleDetails === 'string'
          ? JSON.parse(messageData.scheduleDetails)
          : messageData.scheduleDetails,
      isApproved: messageData.isApproved,
      isActive: messageData.isActive
    },
    validationSchema: UpdateMessageSchema,
    onSubmit: async (values, { resetForm }) => {
      values.scheduleDetails = inputValues;
      const response = await updateMessage({ values, dispatch, messageId });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update Message</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Message Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                autoComplete="message"
                type="text"
                margin="normal"
                label="Message"
                {...getFieldProps('message')}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Repeat Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('repeat')}
                  name="repeat"
                  label="Repeat Status"
                  error={Boolean(touched.repeat && errors.repeat)}
                  helperText={touched.repeat && errors.repeat}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
              <JSONInput
                id="user_json"
                colors={lightMitsuketaTribute}
                placeholder={
                  typeof messageData.scheduleDetails === 'string'
                    ? JSON.parse(messageData.scheduleDetails)
                    : messageData.scheduleDetails
                }
                confirmGood={false}
                locale={localeEn}
                style={{
                  outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                  container: { width: '85%', height: '95%' },
                  contentBox: { color: '7E7E7E' }
                }}
                height="250px"
                onChange={(input) => {
                  setInputValues(input.json);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
