export const START_LOADING_CONFIGS = 'START_LOADING_CONFIGS';
export const LOAD_CONFIGS = 'LOAD_CONFIGS';
export const END_LOADING_CONFIGS = 'END_LOADING_CONFIGS';

export const START_ADD_CONFIG = 'START_ADD_CONFIG';
export const ADD_CONFIG_SUCCESS = 'ADD_CONFIG_SUCCESS';
export const END_ADD_CONFIG = 'END_ADD_CONFIG';

export const START_EDIT_CONFIG = 'START_EDIT_CONFIG';
export const EDIT_CONFIG_SUCCESS = 'EDIT_CONFIG_SUCCESS';
export const END_EDIT_CONFIG = 'END_EDIT_CONFIG';
