import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// material
import { styled } from '@mui/material/styles';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { getMembers } from '../../../redux/actions/members';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

MemberListToolbar.propTypes = {
  numSelected: PropTypes.number,
  isSuspended: PropTypes.string,
  pendingUpdateApproval: PropTypes.bool,
  deviceCheck: PropTypes.string
};

export default function MemberListToolbar({
  numSelected,
  isSuspended,
  pendingUpdateApproval,
  deviceCheck,
  isBlocked
}) {
  const dispatch = useDispatch();
  const timeout = process.env.REACT_APP_SEARCH_TIMEOUT || 1000;
  const [filterName, setFilterName] = useState('');

  const filterValue = useSelector((state) => state.reducer.membersReducer.searchValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(async (e) => {
      await getMembers({
        dispatch,
        isSuspended,
        pendingUpdateApproval,
        deviceCheck,
        isBlocked,
        searchValue: e,
        page: 1,
        pageSize: 10
      });
    }, timeout),
    []
  );

  const handleSearch = (e) => {
    debounceSearch(e.target.value);
    setFilterName(e.target.value);
  };

  const handleClear = async () => {
    await getMembers({
      dispatch,
      isSuspended,
      deviceCheck,
      pendingUpdateApproval,
      page: 1,
      pageSize: 10
    });
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={handleSearch}
          placeholder={filterValue}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Clear Search">
          <IconButton onClick={handleClear}>
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
