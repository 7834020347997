import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';
import authConfig from './authHeader';
import { persistor } from '../redux';

const instance = axios.create(authConfig);

class APIError extends Error {
  constructor(statusCode, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.statusCode = statusCode;
    this.message = message;
  }
}

const errorHandler = (err) => {
  try {
    if (err.response) {
      throw new APIError(err.response.status, err.response.data?.message || err.response.data);
    } else if (err.request) {
      throw new APIError(503, err.request.data?.message || err.request.data);
    } else throw APIError(400, err.data.errors);
  } catch (e) {
    return e;
  }
};

function generateSecretKey(response) {
  const model = response.headers['x-device'] || '';
  const deviceId = response.headers['x-identifier'] || '';
  const deviceIp = response.headers['x-device-ip'] || '';
  const timestamp = response.headers['x-ile-time'] || '';

  const baseString = `${model}${deviceId}${deviceIp}${timestamp}`;

  const hash = CryptoJS.SHA256(baseString);
  const hashInHex = hash.toString(CryptoJS.enc.Hex);

  return hashInHex.slice(0, 32);
}

function decrypt(encryptedData, secretKey, iv) {
  const ciphertextWordArray = CryptoJS.enc.Hex.parse(encryptedData);
  const secretKeyWordArray = CryptoJS.enc.Utf8.parse(secretKey);
  const ivWordArray = CryptoJS.enc.Hex.parse(iv);

  const ciphertextCP = { ciphertext: ciphertextWordArray };

  const decrypted = CryptoJS.AES.decrypt(ciphertextCP, secretKeyWordArray, { iv: ivWordArray });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

function logout() {
  localStorage.clear();
  localStorage.removeItem('token');
  persistor.purge().then(() => console.log('purged'));
  window.location.href = '/login';
}

// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data === 'Revoked Token') {
      logout();
    }
    return Promise.reject(errorHandler(error));
  }
);

instance.interceptors.response.use((response) => {
  const { headers } = response;

  if (headers['content-type'] === 'text/plain') {
    try {
      const secretKey = generateSecretKey(response);

      const base64String = response.data;
      const jsonString = Buffer.from(base64String, 'base64').toString();

      const { iv, encryptedData } = JSON.parse(jsonString);

      const decryptedData = decrypt(encryptedData, secretKey, iv);

      response.data = JSON.parse(decryptedData);
    } catch (e) {
      console.error(e);
    }
  }
  return response;
});

export const secureapi = instance;
