import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography
} from '@mui/material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { linkAccounts } from '../../redux/actions/members';

LinkAccount.propTypes = { memberId: PropTypes.string, memberData: PropTypes.object };

export default function LinkAccount({ memberId, memberData }) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [sessionUser, setSessionUser] = useState(null);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFilePreview(null);
  };

  useEffect(() => {
    const sessionUserId = localStorage.getItem('userId');
    setSessionUser(sessionUserId);
  }, []);

  // handle image upload
  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    // check if image is a pdf file and less than 2mb in size
    if (selectedFile && selectedFile.type === 'application/pdf' && selectedFile.size <= 2097152) {
      setFile(selectedFile);

      // handle image preview
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onloadend = () => {
        setFilePreview(fileReader.result);
      };
    } else {
      setFile(null);
    }
  };

  // account number validator
  const numberRegex = /^\d{16}$/;

  const LinkAccountSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .matches(numberRegex, 'Account Number is not valid')
      .required('Account Number is required')
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: ''
    },
    validationSchema: LinkAccountSchema,
    onSubmit: async (values, { resetForm }) => {
      values.updatedBy = sessionUser;
      values.customerId = memberId;
      values.linkageForm = file;

      const response = await linkAccounts({ dispatch, values });

      if (response.status === 200) {
        resetForm();
        setFile(null);
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <AddLinkIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Link Account" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>{`Link Account to ${memberData?.firstname} ${memberData?.lastname}`}</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  Account Number
                </Typography>
                <TextField
                  sx={{ width: '40%', mt: '.5rem' }}
                  autoComplete="accountNumber"
                  type="text"
                  margin="normal"
                  label="Account Number"
                  {...getFieldProps('accountNumber')}
                  error={Boolean(touched.accountNumber && errors.accountNumber)}
                  helperText={touched.accountNumber && errors.accountNumber}
                />
              </Box>
              <Box>
                <Box sx={{ margin: '1rem 0' }}>
                  <a
                    href={`${process.env.PUBLIC_URL}/static/template.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View and download Account Linkage Form
                  </a>
                </Box>
                <Box>
                  <Typography variant="h6">Upload filled Account Linkage Form below:</Typography>
                  <Box
                    sx={{
                      padding: '.5rem',
                      border: '1px solid red',
                      width: '35%',
                      mt: '.7rem',
                      borderRadius: '7px',
                      backgroundColor: 'rgba(255, 99, 71, 0.3)'
                    }}
                  >
                    <Typography sx={{ fontStyle: 'italic', color: 'red' }}>
                      *** The file to upload has to be a pdf.
                    </Typography>
                  </Box>
                  <Box sx={{ mt: '1rem' }}>
                    {filePreview && (
                      <Box>
                        <embed
                          src={filePreview}
                          type="application/pdf"
                          width="80%"
                          height="600px"
                        />
                      </Box>
                    )}
                    <Box sx={{ mt: '1rem' }}>
                      <input type="file" accept="application/pdf" onChange={handleImageUpload} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Link Account
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
}
