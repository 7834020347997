import {
  START_LOADING_PESALINKS,
  LOAD_PESALINKS,
  END_LOADING_PESALINKS,
  START_EDIT_PESALINK,
  EDIT_PESALINK_SUCCESS,
  END_EDIT_PESALINK,
  START_ADD_PESALINK,
  ADD_PESALINK_SUCCESS,
  END_ADD_PESALINK
} from '../constants/pesalinkConstants';

const initialState = {
  isLoading: false,
  isLoadingPesalinks: false,
  isAddPesalinkSuccess: false,
  isAddPesalinkLoading: false,
  isEditPesalinkLoading: false,
  isEditPesalinkSuccess: false,
  pesalinksList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const pesalinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_PESALINKS:
      return { ...state, isLoadingPesalinks: true };
    case END_LOADING_PESALINKS:
      return { ...state, isLoadingPesalinks: false };
    case START_ADD_PESALINK:
      return { ...state, isAddPesalinkLoading: true };
    case ADD_PESALINK_SUCCESS:
      return { ...state, isAddPesalinkSuccess: true };
    case END_ADD_PESALINK:
      return { ...state, isAddPesalinkLoading: false };
    case START_EDIT_PESALINK:
      return { ...state, isEditPesalinkLoading: true };
    case EDIT_PESALINK_SUCCESS:
      return { ...state, isEditPesalinkSuccess: true };
    case END_EDIT_PESALINK:
      return { ...state, isEditPesalinkLoading: false };
    case LOAD_PESALINKS:
      return { ...state, pesalinksList: action.payload };
    default:
      return state;
  }
};

export default pesalinkReducer;
