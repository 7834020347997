import {
  ADD_NOTIF_MESSAGE,
  ADD_NOTIF_WARNING_MESSAGE,
  ADD_NOTIF_SUCCESS_MESSAGE,
  ADD_NOTIF_ERROR_MESSAGE,
  CLEAR_NOTIF_MESSAGE,
  START_LOADING_NOTIF_MESSAGE,
  END_LOADING_NOTIF_MESSAGE,
  LOAD_NOTIF_MESSAGE
} from '../constants/systemNotifications';

const initialState = {
  type: 'info',
  messages: [],
  message: '',
  isLoading: false,
  count: 0
};

const systemNotif = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIF_MESSAGE:
      return { ...state, message: action.message };

    case ADD_NOTIF_SUCCESS_MESSAGE:
      return { ...state, type: 'success', message: action.message };

    case ADD_NOTIF_ERROR_MESSAGE:
      return { ...state, type: 'error', message: action.message };

    case ADD_NOTIF_WARNING_MESSAGE:
      return { ...state, type: 'warning', message: action.message };

    case CLEAR_NOTIF_MESSAGE:
      return { ...state, type: 'info', message: '' };

    case START_LOADING_NOTIF_MESSAGE:
      return { ...state, isLoading: true };

    case LOAD_NOTIF_MESSAGE:
      return {
        ...state,
        messages: action.payload
      };

    case END_LOADING_NOTIF_MESSAGE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default systemNotif;
