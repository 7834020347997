import {
  START_LOADING_BILLERS,
  LOAD_BILLERS,
  END_LOADING_BILLERS,
  START_LOADING_BILLER_TYPES,
  END_LOADING_BILLER_TYPES,
  LOAD_BILLER_TYPES,
  START_ADD_BILLER,
  ADD_BILLER_SUCCESS,
  END_ADD_BILLER
} from '../constants/billerConstants';

const initialState = {
  isLoadingBillers: false,
  isAddBillerSuccess: false,
  isAddBillerLoading: false,
  isLoadingBillerTypes: false,
  billersList: [],
  billerTypesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const billersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_BILLERS:
      return { ...state, isLoadingBillers: true };
    case END_LOADING_BILLERS:
      return { ...state, isLoadingBillers: false };
    case START_ADD_BILLER:
      return { ...state, isAddBillerLoading: true };
    case ADD_BILLER_SUCCESS:
      return { ...state, isAddBillerSuccess: true };
    case END_ADD_BILLER:
      return { ...state, isAddBillerLoading: false };
    case START_LOADING_BILLER_TYPES:
      return { ...state, isLoadingBillerTypes: true };
    case END_LOADING_BILLER_TYPES:
      return { ...state, isLoadingBillerTypes: false };
    case LOAD_BILLER_TYPES:
      return { ...state, billerTypesList: action.payload };
    case LOAD_BILLERS:
      return { ...state, billersList: action.payload };
    default:
      return state;
  }
};

export default billersReducer;
