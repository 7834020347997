import {
  START_LOADING_EXTERNALBILLERS,
  LOAD_EXTERNALBILLERS,
  END_LOADING_EXTERNALBILLERS,
  START_LOADING_EXTERNALBILLER_TYPES,
  END_LOADING_EXTERNALBILLER_TYPES,
  LOAD_EXTERNALBILLER_TYPES,
  START_ADD_EXTERNALBILLER,
  ADD_EXTERNALBILLER_SUCCESS,
  END_ADD_EXTERNALBILLER,
  START_EDIT_EXTERNALBILLER,
  EDIT_EXTERNALBILLER_SUCCESS,
  END_EDIT_EXTERNALBILLER,
  START_DELETE_EXTERNALBILLER,
  DELETE_EXTERNALBILLER_SUCCESS,
  END_DELETE_EXTERNALBILLER
} from '../constants/externalBillersConstants';

const initialState = {
  isLoadingExternalBillers: false,
  isAddExternalBillerSuccess: false,
  isAddExternalBillerLoading: false,
  isLoadingExternalBillerTypes: false,
  isEditExternalBillerLoading: false,
  isEditExternalBillerSuccess: false,
  isDeleteExternalBillerLoading: false,
  isDeleteExternalBillerSuccess: false,
  externalBillersList: [],
  externalBillerTypesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const externalBillersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_EXTERNALBILLERS:
      return { ...state, isLoadingExternalBillers: true };
    case END_LOADING_EXTERNALBILLERS:
      return { ...state, isLoadingExternalBillers: false };
    case START_ADD_EXTERNALBILLER:
      return { ...state, isAddExternalBillerLoading: true };
    case ADD_EXTERNALBILLER_SUCCESS:
      return { ...state, isAddExternalBillerSuccess: true };
    case END_ADD_EXTERNALBILLER:
      return { ...state, isAddExternalBillerLoading: false };
    case START_EDIT_EXTERNALBILLER:
      return { ...state, isEditExternalBillerLoading: true };
    case EDIT_EXTERNALBILLER_SUCCESS:
      return { ...state, isAddExternalBillerSuccess: true };
    case END_EDIT_EXTERNALBILLER:
      return { ...state, isEditExternalBillerLoading: false };
    case START_DELETE_EXTERNALBILLER:
      return { ...state, isDeleteExternalBillerLoading: true };
    case DELETE_EXTERNALBILLER_SUCCESS:
      return { ...state, isDeleteExternalBillerSuccess: true };
    case END_DELETE_EXTERNALBILLER:
      return { ...state, isDeleteExternalBillerLoading: false };
    case START_LOADING_EXTERNALBILLER_TYPES:
      return { ...state, isLoadingExternalBillerTypes: true };
    case END_LOADING_EXTERNALBILLER_TYPES:
      return { ...state, isLoadingExternalBillerTypes: false };
    case LOAD_EXTERNALBILLER_TYPES:
      return { ...state, externalBillerTypesList: action.payload };
    case LOAD_EXTERNALBILLERS:
      return { ...state, externalBillersList: action.payload };
    default:
      return state;
  }
};

export default externalBillersReducer;
