import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// material
import { styled } from '@mui/material/styles';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { getAllTransactions } from '../../../redux/actions/transactions';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 1)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

TransactionListToolbar.propTypes = {
  numSelected: PropTypes.number,
  isPending: PropTypes.bool,
  setGroup: PropTypes.func
};

const TRANSFER_TYPE = [
  { value: 'REGULAR_DEPOSIT', label: 'REGULAR_DEPOSIT' },
  { value: 'TERM_DEPOSIT', label: 'TERM_DEPOSIT' },
  { value: 'LOAN_REPAYMENT', label: 'LOAN_REPAYMENT' },
  { value: 'MOBILE_LOAN', label: 'MOBILE_LOAN' },
  { value: 'INTERNAL_BILL_PAYMENT', label: 'INTERNAL_BILL_PAYMENT' },
  { value: 'CELLULANT', label: 'CELLULANT' },
  { value: 'REVERSAL', label: 'REVERSAL' },
  { value: 'AIRTIME', label: 'AIRTIME' },
  { value: 'MPESA_C2B', label: 'MPESA_C2B' },
  { value: 'MPESA_B2C', label: 'MPESA_B2C' },
  { value: 'MPESA_B2B', label: 'MPESA_B2B' },
  { value: 'CHARGES_TRANSFER_SACCO', label: 'CHARGES_TRANSFER_SACCO' },
  { value: 'CHARGES_TRANSFER_EXCISE', label: 'CHARGES_TRANSFER_EXCISE' },
  { value: 'CHARGES_TRANSFER_MPESA_COMMISSION', label: 'CHARGES_TRANSFER_MPESA_COMMISSION' },
  { value: 'BUY_FLOAT', label: 'FLOAT' }
];

export default function TransactionListToolbar({ numSelected, setGroup, isPending }) {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState('MPESA_B2C');
  const timeout = process.env.REACT_APP_SEARCH_TIMEOUT || 1000;
  let filterValue = '';
  filterValue = useSelector((state) => state.reducer.transactionsReducer.searchValue);
  const debounceSearch = useCallback(
    debounce(async (e) => {
      const filters = isPending
        ? {
            dispatch,
            // transferType: selectedGroup,
            searchValue: e,
            page: 1,
            pageSize: 10,
            status: 'SENT_EXTERNAL'
          }
        : {
            dispatch,
            // transferType: selectedGroup,
            searchValue: e,
            page: 1,
            pageSize: 10
          };
      await getAllTransactions(filters);
    }, timeout),
    []
  );
  const handleSearch = (e) => {
    debounceSearch(e.target.value);
  };
  const handleClear = async () => {
    setSelectedGroup('MPESA_B2C');
    filterValue = '';
    const filters = isPending
      ? {
          dispatch,
          transferType: 'MPESA_B2C',
          page: 1,
          pageSize: 10,
          status: 'SENT_EXTERNAL'
        }
      : {
          dispatch,
          transferType: 'MPESA_B2C',
          page: 1,
          pageSize: 10
        };
    await getAllTransactions(filters);
  };
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    setGroup(event.target.value);
    const filters = isPending
      ? {
          dispatch,
          transferType: event.target.value,
          page: 1,
          pageSize: 10,
          status: 'SENT_EXTERNAL'
        }
      : {
          dispatch,
          transferType: event.target.value,
          page: 1,
          pageSize: 10
        };
    getAllTransactions(filters);
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterValue}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      <FormControl size="small" sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Transfer Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGroup}
          label="Transfer Type"
          onChange={handleGroupChange}
        >
          {TRANSFER_TYPE.map((group) => (
            <MenuItem value={group.value} key={group.value}>
              {group.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Clear Search">
          <IconButton onClick={handleClear}>
            <ClearAllIcon />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
