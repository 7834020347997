export const START_LOADING_GL = 'START_LOADING_GL';
export const LOAD_GL = 'LOAD_GL';
export const END_LOADING_GL = 'END_LOADING_GL';

export const START_EDIT_GL = 'START_EDIT_GL';
export const EDIT_GL_SUCCESS = 'EDIT_GL_SUCCESS';
export const END_EDIT_GL = 'END_EDIT_GL';

export const START_ADD_GL = 'START_ADD_GL';
export const ADD_GL_SUCCESS = 'ADD_GL_SUCCESS';
export const END_ADD_GL = 'END_ADD_GL';
