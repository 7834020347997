import {
  START_LOADING_LOANS_META,
  LOAD_LOANS_META,
  END_LOADING_LOANS_META
} from '../constants/loanMetaConstants';

const initialState = {
  isLoadingLoanMeta: false,
  loanMetaList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const loanMetaReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_LOANS_META:
      return { ...state, isLoadingLoanMeta: true };
    case END_LOADING_LOANS_META:
      return { ...state, isLoadingLoanMeta: false };
    case LOAD_LOANS_META:
      return { ...state, loanMetaList: action.payload };
    default:
      return state;
  }
};

export default loanMetaReducer;
