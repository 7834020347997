import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { checkStatus, closePayment, processPayment } from '../../../redux/actions/transactions';

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

CheckPaymentStatus.propTypes = {
  paymentId: PropTypes.string,
  userId: PropTypes.string,
  paymentData: PropTypes.object
};

const PaymentDoesntExists = ({ open, setOpen, paymentId, paymentExists, userId, isLoading }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePayment = async () => {
    setOpen(false);
    await closePayment({ dispatch, paymentId, userId, paymentExists });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center', textDecoration: 'underline' }}>
        Payment Status!
      </DialogTitle>
      {isLoading ? (
        <LinearProgressStyle />
      ) : (
        <DialogContent>
          <Typography color="error" sx={{ fontStyle: 'italic' }}>
            Payment <strong>DOES NOT</strong> exist!! Click <strong>'close payment'</strong> below
            to close this payment.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close Dialog
        </Button>
        {isLoading ? (
          ''
        ) : (
          <Button variant="contained" color="error" onClick={handleClosePayment}>
            Close Payment
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const PaymentExists = ({ open, setOpen, paymentId, paymentExists, userId, isLoading }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePayment = async () => {
    setOpen(false);
    await closePayment({ dispatch, paymentId, userId, paymentExists });
  };

  const handleProcess = async ({ dispatch, paymentId, userId, paymentExists }) => {
    setOpen(false);
    await processPayment({ dispatch, paymentId, userId, paymentExists });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center', textDecoration: 'underline' }}>
        Payment Status!
      </DialogTitle>
      {isLoading ? (
        <LinearProgressStyle />
      ) : (
        <DialogContent>
          <Typography sx={{ color: 'green', fontStyle: 'italic' }}>
            Payment <strong>EXISTS</strong>!! Click <strong>'close payment'</strong> below to close
            this payment or <strong>'process'</strong> to process this payment.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close Dialog
        </Button>
        {isLoading ? (
          ''
        ) : (
          <Button variant="contained" color="error" onClick={handleClosePayment}>
            Close Payment
          </Button>
        )}
        {isLoading ? (
          ''
        ) : (
          <Button variant="contained" onClick={handleProcess}>
            Process
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default function CheckPaymentStatus({ paymentId, paymentData, userId }) {
  const [open, setOpen] = useState(false);
  const [paymentExists, setPaymentExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const handleClickOpen = async () => {
    setOpen(true);
    const response = await checkStatus({ dispatch, paymentId, paymentData, userId });

    if (response) {
      setIsLoading(false);
      setPaymentExists(response);
    }
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <QuestionMarkIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Check Status" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      {/* eslint-disable-next-line no-nested-ternary */}
      {paymentExists ? (
        <PaymentExists
          paymentExists={paymentExists}
          userId={userId}
          paymentId={paymentId}
          open={open}
          setOpen={setOpen}
          isLoading={isLoading}
        />
      ) : (
        <PaymentDoesntExists
          paymentExists={paymentExists}
          userId={userId}
          paymentId={paymentId}
          open={open}
          setOpen={setOpen}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}
