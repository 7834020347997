import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
//

import AccessControl from '../components/AccessControl';
import FormDialog from '../components/support/Create';

import { SecondaryTab, SecondaryTabs, TabPanel } from '../components/CustomTab';
import AllSupportTickets from '../components/support/index';
import AssignedSupport from '../components/support/AssignedSupport';
import AssignedByMeSupport from '../components/support/AssignedByMeSupport';

export default function Support() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Tickets | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Support Tickets
          </Typography>
          <AccessControl resource="SUPPORT" allowedPermissions="cancreate">
            <FormDialog />
          </AccessControl>
        </Stack>
        <SecondaryTabs value={value} onChange={handleChange} aria-label="ant example">
          <SecondaryTab label="All Tickets" />
          <SecondaryTab label="Tickets Assigned to me" />
          <SecondaryTab label="Tickets Assigned by me" />
        </SecondaryTabs>
        <TabPanel value={value} index={0}>
          <AllSupportTickets />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AssignedSupport />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AssignedByMeSupport />
        </TabPanel>
      </Container>
    </Page>
  );
}
