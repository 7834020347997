import {
  START_LOADING_MEMBERS,
  LOAD_MEMBERS,
  END_LOADING_MEMBERS,
  START_EDIT_MEMBER,
  EDIT_MEMBER_SUCCESS,
  END_EDIT_MEMBER,
  START_STATUS_MEMBER,
  STATUS_MEMBER_SUCCESS,
  END_STATUS_MEMBER,
  START_LOADING_ACCOUNTS,
  END_LOADING_ACCOUNTS,
  LOAD_ACCOUNTS,
  LOAD_TRANSACTIONS,
  START_LOADING_TRANSACTIONS,
  END_LOADING_TRANSACTIONS,
  START_PIN_RESET,
  PIN_RESET_SUCCESS,
  END_PIN_RESET,
  LOAD_CORPORATE_ACCOUNTS
} from '../constants/memberConstants';

const initialState = {
  isLoading: false,
  isAddMemberSuccess: false,
  isAddMemberLoading: false,
  isEditMemberLoading: false,
  isEditMemberSuccess: false,
  isStatusMemberSuccess: false,
  isPinResetLoading: false,
  isPinResetSuccess: false,
  isAccounts: false,
  isTransactions: false,
  membersList: [],
  accountsList: [],
  corporateAccountsList: [],
  transactionsList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false,
  searchValue: 'Search Member ...'
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_EDIT_MEMBER:
      return { ...state, isEditMemberLoading: true };
    case END_EDIT_MEMBER:
      return { ...state, isEditMemberLoading: false };
    case START_STATUS_MEMBER:
      return { ...state, isStatusMemberLoading: true };
    case END_STATUS_MEMBER:
      return { ...state, isStatusMemberLoading: false };
    case EDIT_MEMBER_SUCCESS:
      return { ...state, isEditMemberSuccess: true };
    case STATUS_MEMBER_SUCCESS:
      return { ...state, isStatusMemberSuccess: true };
    case START_LOADING_MEMBERS:
      return { ...state, isLoading: true };
    case LOAD_MEMBERS:
      return {
        ...state,
        membersList: action.data.payload.data,
        count: action.data.payload.count,
        searchValue: action.data.searchValue
        // page: action.payload.pagination.pageNumber,
        // pageSize: action.payload.pagination.pageLimit,
        // totalPages: action.payload.pagination.pageCount
        // sortOrder: action.payload.sort.order,
        // sortField: action.payload.sort.field
      };

    case END_LOADING_MEMBERS:
      return { ...state, isLoading: false };

    case START_LOADING_ACCOUNTS:
      return { ...state, isAccounts: true };

    case LOAD_ACCOUNTS:
      return {
        ...state,
        accountsList: action.payload
      };

    case LOAD_CORPORATE_ACCOUNTS:
      return {
        ...state,
        corporateAccountsList: action.payload
      };

    case END_LOADING_ACCOUNTS:
      return { ...state, isAccounts: false };

    case START_LOADING_TRANSACTIONS:
      return { ...state, isTransactions: true };

    case END_LOADING_TRANSACTIONS:
      return { ...state, isLoading: false };

    case LOAD_TRANSACTIONS:
      return {
        ...state,
        transactionsList: action.payload
      };

    case START_PIN_RESET:
      return { ...state, isPinResetLoading: true };

    case END_PIN_RESET:
      return { ...state, isPinResetLoading: false };

    case PIN_RESET_SUCCESS:
      return { ...state, isPinResetSuccess: true };

    default:
      return state;
  }
};

export default membersReducer;
