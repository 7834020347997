import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_EXTERNALBILLERS,
  LOAD_EXTERNALBILLERS,
  END_LOADING_EXTERNALBILLERS,
  START_ADD_EXTERNALBILLER,
  ADD_EXTERNALBILLER_SUCCESS,
  END_ADD_EXTERNALBILLER,
  START_EDIT_EXTERNALBILLER,
  EDIT_EXTERNALBILLER_SUCCESS,
  END_EDIT_EXTERNALBILLER,
  START_DELETE_EXTERNALBILLER,
  END_DELETE_EXTERNALBILLER,
  DELETE_EXTERNALBILLER_SUCCESS
} from '../constants/externalBillersConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingExternalBillers = () => ({
  type: START_LOADING_EXTERNALBILLERS
});

const endLoadingExternalBillers = () => ({
  type: END_LOADING_EXTERNALBILLERS
});

const startAddExternalBiller = () => ({
  type: START_ADD_EXTERNALBILLER
});

const addExternalBillerSuccess = () => ({
  type: ADD_EXTERNALBILLER_SUCCESS
});

const endAddExternalBiller = () => ({
  type: END_ADD_EXTERNALBILLER
});

const loadExternalBillers = (payload) => ({
  type: LOAD_EXTERNALBILLERS,
  payload
});

const startEditExternalBiller = () => ({
  type: START_EDIT_EXTERNALBILLER
});

const endEditExternalBiller = () => ({
  type: END_EDIT_EXTERNALBILLER
});

const editExternalBillerSuccess = () => ({
  type: EDIT_EXTERNALBILLER_SUCCESS
});

const startDeleteExternalBiller = () => ({
  type: START_DELETE_EXTERNALBILLER
});
const endDeleteExternalBiller = () => ({
  type: END_DELETE_EXTERNALBILLER
});

const deleteExternalBillerSuccess = () => ({
  type: DELETE_EXTERNALBILLER_SUCCESS
});

// get external billers
export const getExternalBillers = ({ dispatch }) => {
  dispatch(startLoadingExternalBillers());
  const url = `/cellulant/v1/service`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingExternalBillers());
      if (!data.data.data) {
        dispatch(loadExternalBillers(data?.data.data));
        dispatch(addWarningMessage('No External Billers found.'));
      } else {
        dispatch(loadExternalBillers(data?.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingExternalBillers());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create external biller
export const addNewExternalBiller = ({ values, dispatch }) => {
  dispatch(startAddExternalBiller());
  const url = `/cellulant/v1/service/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddExternalBiller());
      dispatch(addSuccessMessage(`External Biller added successfully`));
      dispatch(addExternalBillerSuccess());
      getExternalBillers({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddExternalBiller());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update external biller
export const updateExternalBiller = async ({ values, dispatch, externalBillerId }) => {
  dispatch(startEditExternalBiller());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/cellulant/v1/service/update/${externalBillerId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditExternalBiller());
      dispatch(addSuccessMessage('External Biller updated pending Approval'));
      dispatch(editExternalBillerSuccess());
      getExternalBillers({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditExternalBiller());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// delete external biller
export const deleteExternalBiller = async ({ externalBillerId, dispatch }) => {
  dispatch(startDeleteExternalBiller());
  // const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/cellulant/v1/service/delete/${externalBillerId}`;
  return secureapi
    .delete(url)
    .then((data) => {
      dispatch(endDeleteExternalBiller());
      dispatch(addSuccessMessage('Biller Deleted'));
      dispatch(deleteExternalBillerSuccess());
      getExternalBillers({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endDeleteExternalBiller());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
