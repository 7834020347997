import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Select,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { updateCharge, getChargeGroups } from '../../redux/actions/charges';

UpdateCharge.propTypes = {
  chargeId: PropTypes.string,
  chargeData: PropTypes.object
};

export default function UpdateCharge({ chargeId, chargeData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = React.useState(chargeData.isApproved);
  const chargeGroups = useSelector((state) => state.reducer.chargesReducer.chargeGroupList);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const UpdateChargeSchema = Yup.object().shape({
    chargeGroupId: Yup.string().required('Charge group is required'),
    amountFrom: Yup.string().required('Amount from is required'),
    amountTo: Yup.string().required('Amount to is required'),
    saccoCharge: Yup.string().required('Sacco charge from is required'),
    exciseDuty: Yup.string().required('Excise duty from is required'),
    providerCommission: Yup.string().nullable(),
    isApproved: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      chargeGroupId: chargeData.chargeGroupId.id,
      amountFrom: chargeData.amountFrom,
      amountTo: chargeData.amountTo,
      saccoCharge: chargeData.saccoCharge,
      exciseDuty: chargeData.exciseDutyCharge,
      providerCommission: chargeData.providerCommission,
      isApproved: chargeData.isApproved
    },
    validationSchema: UpdateChargeSchema,
    onSubmit: async (values, { resetForm }) => {
      values.isApproved = isChecked;
      const response = await updateCharge({ values, dispatch, chargeId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  useEffect(() => {
    getChargeGroups({
      dispatch
    });
  }, []);

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update system charge</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Charge Group</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('chargeGroupId')}
                  name="chargeGroupId"
                  label="Charge Group"
                  error={Boolean(touched.chargeGroupId && errors.chargeGroupId)}
                  helperText={touched.chargeGroupId && errors.chargeGroupId}
                >
                  {chargeGroups.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="amountFrom"
                type="text"
                margin="normal"
                label="Amount From"
                {...getFieldProps('amountFrom')}
                error={Boolean(touched.amountFrom && errors.amountFrom)}
                helperText={touched.amountFrom && errors.amountFrom}
              />
              <TextField
                fullWidth
                autoComplete="amountTo"
                type="text"
                margin="normal"
                label="Amount To"
                {...getFieldProps('amountTo')}
                error={Boolean(touched.amountTo && errors.amountTo)}
                helperText={touched.amountTo && errors.amountTo}
              />
              <TextField
                fullWidth
                autoComplete="saccoCharge"
                type="text"
                margin="normal"
                label="Sacco Charge"
                {...getFieldProps('saccoCharge')}
                error={Boolean(touched.saccoCharge && errors.saccoCharge)}
                helperText={touched.saccoCharge && errors.saccoCharge}
              />
              <TextField
                fullWidth
                autoComplete="exciseDuty"
                type="text"
                margin="normal"
                label="Excise Duty"
                {...getFieldProps('exciseDuty')}
                error={Boolean(touched.exciseDuty && errors.exciseDuty)}
                helperText={touched.exciseDuty && errors.exciseDuty}
              />
              <TextField
                fullWidth
                autoComplete="providerCommission"
                type="text"
                margin="normal"
                label="Provider Commission"
                {...getFieldProps('providerCommission')}
                error={Boolean(touched.providerCommission && errors.providerCommission)}
                helperText={touched.providerCommission && errors.providerCommission}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheck}
                    error={Boolean(touched.isApproved && errors.isApproved)}
                    helperText={touched.isApproved && errors.isApproved}
                    name="isApproved"
                    color="primary"
                  />
                }
                label="Approve"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
