import {
  START_LOADING_LOANS,
  LOAD_LOANS,
  END_LOADING_LOANS,
  START_EDIT_LOAN,
  EDIT_LOAN_SUCCESS,
  END_EDIT_LOAN
} from '../constants/loansConstants';

const initialState = {
  isLoadingLoans: false,
  isEditLoanLoading: false,
  isEditLoanSuccess: false,
  loansList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  searchValue: 'Search Loan...'
};

const loansReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_LOANS:
      return { ...state, isLoadingLoans: true };
    case END_LOADING_LOANS:
      return { ...state, isLoadingLoans: false };
    case LOAD_LOANS:
      return {
        ...state,
        loansList: action.data.payload.data,
        count: action.data.payload.count,
        searchValue: action.data.searchValue
      };
    case START_EDIT_LOAN:
      return { ...state, isEditLoanLoading: true };
    case EDIT_LOAN_SUCCESS:
      return { ...state, isEditLoanSuccess: true };
    case END_EDIT_LOAN:
      return { ...state, isEditLoanLoading: false };
    default:
      return state;
  }
};

export default loansReducer;
