import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import UpdateMember from '../../member/updateMember';
// import ViewMemberLoans from '../../member/viewMemberLoans';
import PinReset from '../../member/pinReset';
import PinUnblock from '../../member/pinUnblock';
import ChangeStatus from '../../member/changeMemberStatus';
import UnsuspendUser from '../../member/unsuspendUser';

import AccessControl from '../../AccessControl';
import ResetMemberDevice from '../../member/resetMemberDevice';
import ViewMember from '../../member/viewMember';
import ClearFromQueue from '../../member/clearFromQueue';
import LinkAccount from '../../member/linkAccount';

MemberMoreMenu.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

export default function MemberMoreMenu({ memberId, memberData, transactions }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('profile')).emailAddress;
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="MEMBER MANAGEMENT" allowedPermissions="canupdate">
          {!memberData.issuspended && memberData.isregistrationcomplete ? (
            <>
              <PinReset memberId={memberId} memberData={memberData} />
              <PinUnblock memberId={memberId} memberData={memberData} />
            </>
          ) : null}
          {!memberData.isdeleted ? (
            <>
              <UpdateMember memberId={memberId} memberData={memberData} />
              <ChangeStatus memberId={memberId} memberData={memberData} />
            </>
          ) : (
            <UnsuspendUser memberId={memberId} memberData={memberData} />
          )}
          {memberData && memberData.devicecheck === 'FAILED' ? (
            <ResetMemberDevice memberId={memberId} memberData={memberData} />
          ) : (
            ''
          )}
        </AccessControl>
        <AccessControl resource="MEMBER MANAGEMENT" allowedPermissions="canread">
          <ViewMember memberId={memberId} memberData={memberData} transactions={transactions} />
          {/* <ViewMemberLoans */}
          {/*  memberId={memberId} */}
          {/*  memberData={memberData} */}
          {/*  transactions={transactions} */}
          {/* /> */}
        </AccessControl>
        <AccessControl resource="MEMBER MANAGEMENT" allowedPermissions="canexecute">
          <LinkAccount memberId={memberId} memberData={memberData} />
        </AccessControl>
        {loggedUser === 'patsheba@asilimia.co.ke' ? (
          <ClearFromQueue memberId={memberId} memberData={memberData} />
        ) : null}
        {/* <AccessControl resource="MEMBER MANAGEMENT" allowedPermissions="canexecute"> */}
        {/* </AccessControl> */}
      </Menu>
    </>
  );
}
