import { PURGE } from 'redux-persist';
import {
  START_LOADING_LOGIN,
  END_LOADING_LOGIN,
  ADD_USER_SUCCESS,
  START_ADD_USER,
  END_ADD_USER,
  START_EDIT_USER,
  EDIT_USER_SUCCESS,
  END_EDIT_USER,
  START_DELETE_USER,
  END_DELETE_USER,
  DELETE_USER_SUCCESS,
  START_LOADING_USERS,
  LOAD_USERS,
  END_LOADING_USERS,
  RESET_PASSWORD_EMAIL_SENT,
  START_PASSWORD_RESET,
  END_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  START_LOADING_USER_PERMISSIONS,
  LOAD_USER_PERMISSIONS,
  END_LOADING_USER_PERMISSIONS
} from '../constants/userConstants';

const initialState = {
  isLoadingUsers: false,
  isLoadingLogin: false,
  isAddUserSuccess: false,
  isAddUserLoading: false,
  isEditUserLoading: false,
  isEditUserSuccess: false,
  isDeleteUserSuccess: false,
  usersList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false,
  token: '',
  profile: [],
  userPermissions: [],
  isLoadingUserPermissions: false,
  isSuperUser: false,
  isPasswordResetLoading: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_USER:
      return { ...state, isAddUserLoading: true };
    case END_ADD_USER:
      return { ...state, isAddUserLoading: false };
    case START_EDIT_USER:
      return { ...state, isEditUserLoading: true };
    case END_EDIT_USER:
      return { ...state, isEditUserLoading: false };
    case START_DELETE_USER:
      return { ...state, isDeleteUserLoading: true };
    case END_DELETE_USER:
      return { ...state, isDeleteUserLoading: false };
    case START_LOADING_LOGIN:
      return { ...state, isLoadingLogin: true };
    case ADD_USER_SUCCESS:
      return { ...state, isAddUserSuccess: true };
    case EDIT_USER_SUCCESS:
      return { ...state, isEditUserSuccess: true };
    case DELETE_USER_SUCCESS:
      return { ...state, isDeleteUserSuccess: true };
    case END_LOADING_LOGIN:
      return { ...state, isLoadingLogin: false };
    case START_LOADING_USERS:
      return { ...state, isLoadingUsers: true };
    case START_LOADING_USER_PERMISSIONS:
      return { ...state, isLoadingPermissions: true };
    case LOGIN_SUCCESS:
      return { ...state, token: action.payload.token, profile: action.payload.data };
    case LOGOUT_SUCCESS:
      return { initialState };
    case PURGE:
      return { initialState };
    case LOAD_USERS:
      return {
        ...state,
        usersList: action.payload
      };
    case LOAD_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload.data,
        isSuperUser: action.isSuperUser
      };
    case END_LOADING_USER_PERMISSIONS:
      return { ...state, isLoadingPermissions: false };
    case END_LOADING_USERS:
      return { ...state, isLoadingUsers: false };

    case RESET_PASSWORD_EMAIL_SENT:
      return { ...state, isResetEmailSent: true };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, isPassReset: true };
    case START_PASSWORD_RESET:
      return { ...state, isPasswordResetLoading: true };
    case END_PASSWORD_RESET:
      return { ...state, isPasswordResetLoading: false };

    default:
      return state;
  }
};

export default usersReducer;
