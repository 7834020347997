import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, InputLabel, Select } from '@mui/material';
import { updateLoan } from '../../redux/actions/loans';

UpdateLoan.propTypes = {
  loanId: PropTypes.string,
  loanData: PropTypes.object
};

export default function UpdateLoan({ loanId, loanData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // phone number and id validator regex
  const numberRegex = /^(?:254|\+254|0)?(7|1?[0-9]{9})$/;

  const UpdateLoanSchema = Yup.object().shape({
    recipientPhone: Yup.string().matches(numberRegex, 'Phone Number is not valid'),
    principalAmount: Yup.number(),
    interest: Yup.number(),
    amountRepaid: Yup.number().nullable(),
    isOverdue: Yup.boolean(),
    penaltyAmount: Yup.number().nullable(),
    status: Yup.string(),
    updatedBy: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      recipientPhone: loanData?.recipientPhone,
      principalAmount: loanData?.principalAmount,
      interest: loanData?.interest,
      amountRepaid: loanData?.amountRepaid,
      isOverdue: loanData?.isOverdue,
      penaltyAmount: loanData?.penaltyAmount,
      status: loanData?.status,
      updatedBy: loanData?.updatedBy
    },
    validationSchema: UpdateLoanSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await updateLoan({ values, dispatch, loanId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update Loan</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                disabled
                fullWidth
                autoComplete="recipientPhone"
                type="text"
                margin="normal"
                label="Recipient Phone"
                {...getFieldProps('recipientPhone')}
                error={Boolean(touched.recipientPhone && errors.recipientPhone)}
                helperText={touched.recipientPhone && errors.recipientPhone}
              />
              <TextField
                disabled
                fullWidth
                autoComplete="principalAmount"
                type="number"
                margin="normal"
                label="Principal Amount"
                {...getFieldProps('principalAmount')}
                error={Boolean(touched.principalAmount && errors.principalAmount)}
                helperText={touched.principalAmount && errors.principalAmount}
              />
              <TextField
                disabled
                fullWidth
                autoComplete="interest"
                type="number"
                margin="normal"
                label="Interest"
                {...getFieldProps('interest')}
                error={Boolean(touched.interest && errors.interest)}
                helperText={touched.interest && errors.interest}
              />
              <TextField
                disabled
                fullWidth
                autoComplete="amountRepaid"
                type="number"
                margin="normal"
                label="Amount Repaid"
                {...getFieldProps('amountRepaid')}
                error={Boolean(touched.amountRepaid && errors.amountRepaid)}
                helperText={touched.amountRepaid && errors.amountRepaid}
              />
              <InputLabel id="demo-simple-select-outlined-label">Overdue</InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="isOverdue"
                label="Overdue"
                {...getFieldProps('isOverdue')}
                error={Boolean(touched.isOverdue && errors.isOverdue)}
                helperText={touched.isOverdue && errors.isOverdue}
              >
                <MenuItem value="false">False</MenuItem>
                <MenuItem value="true">True</MenuItem>
              </Select>
              <TextField
                disabled
                fullWidth
                autoComplete="penaltyAmount"
                type="number"
                margin="normal"
                label="Penalty Amount"
                {...getFieldProps('penaltyAmount')}
                error={Boolean(touched.penaltyAmount && errors.penaltyAmount)}
                helperText={touched.penaltyAmount && errors.penaltyAmount}
              />
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="status"
                label="Status"
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <MenuItem value="DISBURSED">DISBURSED</MenuItem>
                <MenuItem value="PARTIALLY_REPAID">PARTIALLY REPAID</MenuItem>
                <MenuItem value="FULLY_PAID">FULLY PAID</MenuItem>
                <MenuItem value="OVERDUE">OVERDUE</MenuItem>
                <MenuItem value="DEFAULTED">DEFAULTED</MenuItem>
                <MenuItem value="WRITTEN_OFF">WRITTEN OFF</MenuItem>
                <MenuItem value="INTEREST_WAIVED">INTEREST WAIVED</MenuItem>
                <MenuItem value="REQUESTED">REQUESTED</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
