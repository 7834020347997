import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useEffect, useState } from 'react';
import { addNewUser } from '../../redux/actions/user';
import { secureapi } from '../../config/secureapi';
import { getBranches } from '../../redux/actions/branches';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const branches = useSelector((state) => state.reducer.branchesReducer.branchesList);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // phone number validator regex
  const numberRegex = /^(?:254|\+254|0)?(7|1?[0-9]{9})$/;

  const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string()
      .matches(numberRegex, 'Phone Number is not valid')
      .required('Phone Number is required'),
    roleId: Yup.string(),
    branch: Yup.string().nullable(),
    emailAddress: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    isSuperUser: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      branch: '',
      emailAddress: '',
      isSuperUser: false,
      roleId: ''
    },
    validationSchema: CreateUserSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewUser({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });
  // handle roles selection
  const [roleItems, setRoleItems] = useState([]);
  let results;
  async function getRolesList() {
    const response = await secureapi(`/roles/all`, {});
    results = response.data.data;
    setRoleItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;
  // const handleShowPassword = () => {
  //   setShowPassword((show) => !show);
  // };
  useEffect(() => {
    getRolesList();
    getBranches({ dispatch });
  }, []);
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New User
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new system user</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="firstName"
                type="text"
                margin="normal"
                label="First Name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <TextField
                fullWidth
                autoComplete="lastName"
                type="text"
                margin="normal"
                label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
              <TextField
                fullWidth
                autoComplete="phoneNumber"
                type="text"
                margin="normal"
                label="Phone Number"
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  disabled={!!values.isSuperUser}
                  {...getFieldProps('roleId')}
                  name="roleId"
                  label="Role"
                  error={Boolean(touched.roleId && errors.roleId)}
                  helperText={touched.roleId && errors.roleId}
                >
                  <MenuItem key="none" value="">
                    None
                  </MenuItem>
                  {roleItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('branch')}
                  name="branch"
                  label="Branch"
                  error={Boolean(touched.branch && errors.branch)}
                  helperText={touched.branch && errors.branch}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.name}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="emailAddress"
                type="email"
                margin="normal"
                label="Email address"
                {...getFieldProps('emailAddress')}
                error={Boolean(touched.emailAddress && errors.emailAddress)}
                helperText={touched.emailAddress && errors.emailAddress}
              />
              <FormControlLabel
                disabled={!!values.roleId}
                control={
                  <Checkbox {...getFieldProps('isSuperUser')} name="isSuperUser" color="primary" />
                }
                label="Is Super User"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
