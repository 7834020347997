import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import UpdateGL from '../../gl/Update';
import AccessControl from '../../AccessControl';

// ----------------------------------------------------------------------
GLMoreMenu.propTypes = {
  glId: PropTypes.string,
  glData: PropTypes.object
};

export default function GLMoreMenu({ glId, glData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canupdate">
          <UpdateGL glId={glId} glData={glData} />
        </AccessControl>
      </Menu>
    </>
  );
}
