import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Checkbox,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
//
import EmptyState from '../EmptyState';
import { getSystemConfigs } from '../../redux/actions/system-configs';
import {
  SysConfigsListHead,
  SysConfigsListToolbar,
  SysConfigsMoreMenu
} from '../_dashboard/systemConfigs';
import AccessControl from '../AccessControl';
import { fDate } from '../../utils/formatTime';
import CreateSysConfig from './createSysConfig';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'key', label: 'System Config', alignRight: false },
  { id: 'value', label: 'Config Value', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_config) => _config.type.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const SystemConfigs = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('type');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.sysConfigsReducer.isLoading);
  const configs = useSelector((state) => state.reducer.sysConfigsReducer.sysConfigsList);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  console.log('SYSTEM CONF:::', configs);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = configs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - configs.length) : 0;

  const isConfigNotFound = configs.length === 0;

  useEffect(() => {
    getSystemConfigs({
      dispatch
    });
  }, [dispatch]);

  return (
    <Paper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} mt={3}>
        <Typography variant="h4" gutterBottom />
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="cancreate">
          <CreateSysConfig />
        </AccessControl>
      </Stack>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : configs.length < 1 ? (
        <EmptyState message="No configs available" />
      ) : (
        <Card>
          <SysConfigsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SysConfigsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={configs.length}
                  numSelected={selected.length}
                  M
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {configs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, key, value, description, type, createdAt, updatedAt } = row;
                      const isItemSelected = selected.indexOf(type) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, type)}
                            />
                          </TableCell>
                          <TableCell align="left">{key}</TableCell>
                          <TableCell align="left">{value}</TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">{fDate(createdAt)}</TableCell>
                          <TableCell align="left">{fDate(updatedAt)}</TableCell>
                          <TableCell align="right">
                            <SysConfigsMoreMenu sysConfigId={id} sysConfigData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isConfigNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={configs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Paper>
  );
};
export default SystemConfigs;
