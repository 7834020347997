import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_PESALINKS,
  LOAD_PESALINKS,
  END_LOADING_PESALINKS,
  START_EDIT_PESALINK,
  EDIT_PESALINK_SUCCESS,
  END_EDIT_PESALINK,
  START_ADD_PESALINK,
  ADD_PESALINK_SUCCESS,
  END_ADD_PESALINK,
  START_DELETE_PESALINK,
  END_DELETE_PESALINK,
  DELETE_PESALINK_SUCCESS
} from '../constants/pesalinkConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingPesalinks = () => ({
  type: START_LOADING_PESALINKS
});

const endLoadingPesalinks = () => ({
  type: END_LOADING_PESALINKS
});

const startAddPesalink = () => ({
  type: START_ADD_PESALINK
});

const addPesalinkSuccess = () => ({
  type: ADD_PESALINK_SUCCESS
});

const endAddPesalink = () => ({
  type: END_ADD_PESALINK
});

const startEditPesalink = () => ({
  type: START_EDIT_PESALINK
});

const EditPesalinkSuccess = () => ({
  type: EDIT_PESALINK_SUCCESS
});

const endEditPesalink = () => ({
  type: END_EDIT_PESALINK
});

const startDeletePesalink = () => ({
  type: START_DELETE_PESALINK
});

const endDeletePesalink = () => ({
  type: END_DELETE_PESALINK
});

const deletePesalinkSuccess = () => ({
  type: DELETE_PESALINK_SUCCESS
});

const loadPesalinks = (payload) => ({
  type: LOAD_PESALINKS,
  payload
});

// get pesalinks list
export const getPesalinks = ({ dispatch }) => {
  dispatch(startLoadingPesalinks());
  const url = `/pesalink/v1/bank/details`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingPesalinks());
      if (!data.data.data) {
        dispatch(loadPesalinks(data?.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadPesalinks(data?.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingPesalinks());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create Pesalink Bank
export const addNewPesalink = ({ values, dispatch }) => {
  dispatch(startAddPesalink());
  const url = `/pesalink/v1/bank/add`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddPesalink());
      dispatch(addSuccessMessage(`Pesalink Bank added successfully`));
      dispatch(addPesalinkSuccess());
      getPesalinks({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddPesalink());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update Pesalink Bank
export const updatePesalink = ({ values, dispatch, pesalinkId }) => {
  dispatch(startEditPesalink());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, updatedBy: approverId };
  const url = `/pesalink/v1/bank/update/${pesalinkId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditPesalink());
      dispatch(addSuccessMessage('Pesalink Bank updated successfully'));
      dispatch(EditPesalinkSuccess());
      getPesalinks({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditPesalink());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// approve an updated existing pesalink
export const approveUpdatePesalink = async ({ values, dispatch, pesalinkId }) => {
  dispatch(startEditPesalink());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/pesalink/v1/bank/update/${pesalinkId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditPesalink());
      dispatch(addSuccessMessage('Pesalink Bank updated successfully'));
      dispatch(EditPesalinkSuccess());
      getPesalinks({ dispatch, pendingUpdateApproval: 'true' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditPesalink());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update Pesalink Bank Status
export const updatePesalinkStatus = ({ values, dispatch, pesalinkId }) => {
  dispatch(startEditPesalink());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId };
  const url = `/pesalink/v1/bank/update/${pesalinkId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditPesalink());
      dispatch(addSuccessMessage('Pesalink Bank Status updated successfully'));
      dispatch(EditPesalinkSuccess());
      getPesalinks({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditPesalink());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// Delete Pesalink Bank
export const deletePesalink = ({ values, dispatch, pesalinkId }) => {
  dispatch(startDeletePesalink());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, updatedBy };
  const url = `/pesalink/v1/bank/update/${pesalinkId}`;
  return secureapi
    .delete(url, data)
    .then((data) => {
      dispatch(endDeletePesalink());
      dispatch(addSuccessMessage(`Pesalink bank Deleted successfully`));
      dispatch(deletePesalinkSuccess());
      getPesalinks({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endDeletePesalink());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
