import {
  START_LOADING_MESSAGES,
  LOAD_MESSAGES,
  END_LOADING_MESSAGES,
  START_ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  END_ADD_MESSAGE,
  START_EDIT_MESSAGE,
  EDIT_MESSAGE_SUCCESS,
  END_EDIT_MESSAGE
} from '../constants/messagesConstants';

const initialState = {
  isLoading: false,
  isLoadingMessages: false,
  isAddMessageSuccess: false,
  isAddMessageLoading: false,
  isEditMessageLoading: false,
  isEditMessageSuccess: false,
  messagesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_MESSAGES:
      return { ...state, isLoadingMessages: true };
    case END_LOADING_MESSAGES:
      return { ...state, isLoadingMessages: false };
    case START_ADD_MESSAGE:
      return { ...state, isAddMessageLoading: true };
    case ADD_MESSAGE_SUCCESS:
      return { ...state, isAddMessageSuccess: true };
    case END_ADD_MESSAGE:
      return { ...state, isAddMessageLoading: false };
    case START_EDIT_MESSAGE:
      return { ...state, isEditMessageLoading: true };
    case EDIT_MESSAGE_SUCCESS:
      return { ...state, isEditMessageSuccess: true };
    case END_EDIT_MESSAGE:
      return { ...state, isEditMessageLoading: false };
    case LOAD_MESSAGES:
      return { ...state, messagesList: action.payload };
    default:
      return state;
  }
};

export default messagesReducer;
