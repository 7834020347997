import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { updateSupportStatus } from '../../redux/actions/support';

ChangeSupportStatus.propTypes = {
  supportId: PropTypes.string,
  supportData: PropTypes.object
};

export default function ChangeSupportStatus({ supportId, supportData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const resolvedBy = useSelector((state) => state.reducer.usersReducer.profile);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ResolveSchema = Yup.object().shape({
    comments: Yup.string().required('Resolution comments are required')
  });

  const formik = useFormik({
    initialValues: {
      ticketGroup: supportData.ticketgroup,
      ticketDescription: supportData.ticketdescription,
      resolved: supportData.resolved,
      comments: supportData.comments,
      resolvedBy: supportData.resolvedby ? supportData.resolvedby : ''
    },
    validationSchema: ResolveSchema,
    onSubmit: async (values) => {
      values.resolved = !values.resolved;
      values.resolvedBy = resolvedBy.id;
      const response = await updateSupportStatus({ values, dispatch, supportId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          {supportData.resolved ? (
            <ToggleOnOutlinedIcon width={24} height={24} />
          ) : (
            <ToggleOffOutlinedIcon width={24} height={24} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={supportData.resolved ? 'Pending' : 'Resolve'}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>{supportData.resolved ? 'Resolve' : 'Pending'}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                Support
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${supportData.code} by 
                  ${supportData.createdby && sentenceCase(supportData.createdby)} of phone number ${
                    supportData.creatorphone && supportData.creatorphone
                  }`}
                </span>{' '}
                will be resolved with the following comments.
              </Typography>
              <TextField
                fullWidth
                autoComplete="comments"
                type="text"
                margin="normal"
                label="Resolve with comments"
                {...getFieldProps('comments')}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button type="submit" color="success">
                Resolve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
