export const START_LOADING_TRANSACTIONS_REPORT = 'START_LOADING_TRANSACTIONS_REPORT';
export const LOAD_TRANSACTIONS_REPORT = 'LOAD_TRANSACTIONS_REPORT';
export const END_LOADING_TRANSACTIONS_REPORT = 'END_LOADING_TRANSACTIONS_REPORT';

export const START_LOADING_MEMBERS_REPORT = 'START_LOADING_MEMBERS_REPORT';
export const LOAD_MEMBERS_REPORT = 'LOAD_MEMBERS_REPORT';
export const END_LOADING_MEMBERS_REPORT = 'END_LOADING_MEMBERS_REPORT';

export const START_LOADING_ADVANCED_REPORT = 'START_LOADING_ADVANCED_REPORT';
export const LOAD_ADVANCED_REPORT = 'LOAD_ADVANCED_REPORT';
export const END_LOADING_ADVANCED_REPORT = 'END_LOADING_ADVANCED_REPORT';
