export const START_LOADING_MESSAGES = 'START_LOADING_MESSAGES';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const END_LOADING_MESSAGES = 'END_LOADING_MESSAGES';

export const START_ADD_MESSAGE = 'START_ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const END_ADD_MESSAGE = 'END_ADD_MESSAGE';

export const START_EDIT_MESSAGE = 'START_EDIT_MESSAGE';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const END_EDIT_MESSAGE = 'END_EDIT_MESSAGE';
