import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Button,
  Card,
  Checkbox,
  Container,
  LinearProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Page from '../../Page';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { LoanListHead, LoanListToolbar } from '../../_dashboard/loans';
//

import EmptyState from '../../EmptyState';
import { fDate } from '../../../utils/formatTime';
import { getSalaryAdvances } from '../../../redux/actions/salary-advance';
import AdvanceLoanMoreMenu from './salaryAdvanceMoreMenu';
import UploadCSV from './uploadCSV';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'recipientPhone', label: 'Phone Number', alignRight: false },
  { id: 'principalAmount', label: 'Principal Amount', alignRight: false },
  { id: 'interest', label: 'Interest', alignRight: false },
  { id: 'amountRepaid', label: 'Amount Repaid', alignRight: false },
  { id: 'expectedRepaymentDate', label: 'Repayment Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'isOverdue', label: 'Overdue', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_loan) =>
        _loan.recipientPhone || _loan.amountRepaid || _loan.principalAmount || _loan.lastRepaid
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function SalaryAdvanceLoans() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('status');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.reducer.salaryAdvanceReducer.isLoadingAdvanceLoans
  );
  const advancedLoans = useSelector((state) => state.reducer.salaryAdvanceReducer.advanceLoansList);
  const advancedLoanCount = useSelector((state) => state.reducer.salaryAdvanceReducer.count);

  console.log('salary Advance Loans >>> ', advancedLoans);

  const handleDownloadCSV = () => {
    const csvContent = `nationalId,JANUARY,FEBRUARY,MARCH,APRIL,MAY,JUNE\n12345678,1000,2000,3000,2500,0,0`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'salary_advance_sample.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = advancedLoans.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    fetchFilteredSalaryAdvances({
      page: newPage,
      pageSize: rowsPerPage,
      searchValue: filterName,
      status: selectedStatus
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    setPage(0);

    fetchFilteredSalaryAdvances({
      page: 0,
      pageSize,
      searchValue: filterName,
      status: selectedStatus
    });
  };

  const handleSearchChange = (event, status) => {
    setFilterName(event);
    setPage(0);

    fetchFilteredSalaryAdvances({
      page: 0,
      pageSize: rowsPerPage,
      status,
      searchValue: event
    });
  };

  const handleClearFilters = () => {
    setSelectedStatus('');
    setFilterName('');
    setPage(0);

    fetchFilteredSalaryAdvances({
      page: 0,
      pageSize: rowsPerPage,
      status: '',
      searchValue: ''
    });
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(0);

    fetchFilteredSalaryAdvances({
      page: 0,
      pageSize: rowsPerPage,
      status: event.target.value,
      searchValue: filterName
    });
  };

  const fetchFilteredSalaryAdvances = ({ page, pageSize, searchValue, status }) => {
    getSalaryAdvances({
      dispatch,
      page: page + 1,
      pageSize,
      type: 'ea5f6d32-308d-4d54-999b-3b0d25fd0b3a',
      status,
      searchValue
    });
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loans.length) : 0;

  const filteredLoans = applySortFilter(advancedLoans, getComparator(order, orderBy), filterName);

  // const isLoanNotFound = filteredLoans?.length === 0;

  // to get advanced loans list
  useEffect(() => {
    getSalaryAdvances({
      dispatch,
      page: page + 1,
      pageSize: rowsPerPage,
      type: 'ea5f6d32-308d-4d54-999b-3b0d25fd0b3a'
    });
  }, []);

  console.log('CLEAR FILTERS');
  return (
    <Page title="Salary Advance Loans | Fortune Sacco">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '1rem',
          padding: '5px 8px'
        }}
      >
        <Button
          sx={{
            border: '2px solid #060843',
            color: '#060843',
            fontWeight: 'bold',
            marginRight: '2rem',

            '&:hover': {
              backgroundColor: 'rgba(6, 8, 87, 0.1)'
            }
          }}
          onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
        <UploadCSV />
      </Box>
      <Container maxWidth={false}>
        <LoanListToolbar
          numSelected={selected.length}
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
          handleSearchChange={handleSearchChange}
          handleClearFilters={handleClearFilters}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : advancedLoans?.length < 1 ? (
          <EmptyState message="No advanced loans available" />
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <LoanListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={advancedLoans?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredLoans.map((row) => {
                      const {
                        id,
                        recipientPhone,
                        principalAmount,
                        interest,
                        amountRepaid,
                        expectedRepaymentDate,
                        status,
                        isOverdue,
                        createdAt
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell align="left">{recipientPhone}</TableCell>
                          <TableCell align="left">{principalAmount}</TableCell>
                          <TableCell align="left">{interest}</TableCell>
                          <TableCell align="left">{amountRepaid}</TableCell>
                          <TableCell align="left">
                            {expectedRepaymentDate && fDate(expectedRepaymentDate)}
                          </TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="left">
                            {expectedRepaymentDate &&
                            status !== 'FULLY_PAID' &&
                            new Date().getTime() > new Date(expectedRepaymentDate).getTime() ? (
                              <Typography color="error">Yes</Typography>
                            ) : (
                              <Typography color="secondary">No</Typography>
                            )}
                          </TableCell>
                          <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                          <TableCell align="right">
                            <AdvanceLoanMoreMenu loanId={id} loanData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {isLoanNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={advancedLoanCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
