import {
  ADD_PERMISSION_SUCCESS,
  START_ADD_PERMISSION,
  END_ADD_PERMISSION,
  START_EDIT_PERMISSION,
  EDIT_PERMISSION_SUCCESS,
  END_EDIT_PERMISSION,
  START_DELETE_PERMISSION,
  END_DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  START_LOADING_PERMISSIONS,
  LOAD_PERMISSIONS,
  END_LOADING_PERMISSIONS
} from '../constants/permissionConstants';

const initialState = {
  isLoading: false,
  isAddPermissionSuccess: false,
  isAddPermissionLoading: false,
  isEditPermissionLoading: false,
  isEditPermissionSuccess: false,
  isDeletePermissionSuccess: false,
  permissionsList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_PERMISSION:
      return { ...state, isAddPermissionLoading: true };
    case END_ADD_PERMISSION:
      return { ...state, isAddPermissionLoading: false };
    case START_EDIT_PERMISSION:
      return { ...state, isEditPermissionLoading: true };
    case END_EDIT_PERMISSION:
      return { ...state, isEditPermissionLoading: false };
    case START_DELETE_PERMISSION:
      return { ...state, isDeletePermissionLoading: true };
    case END_DELETE_PERMISSION:
      return { ...state, isDeletePermissionLoading: false };
    case ADD_PERMISSION_SUCCESS:
      return { ...state, isAddPermissionSuccess: true };
    case EDIT_PERMISSION_SUCCESS:
      return { ...state, isEditPermissionSuccess: true };
    case DELETE_PERMISSION_SUCCESS:
      return { ...state, isDeletePermissionSuccess: true };
    case START_LOADING_PERMISSIONS:
      return { ...state, isLoading: true };

    case LOAD_PERMISSIONS:
      return {
        ...state,
        permissionsList: action.payload.data
        // count: action.payload.data.length
        // page: action.payload.pagination.pageNumber,
        // pageSize: action.payload.pagination.pageLimit,
        // totalPages: action.payload.pagination.pageCount
        // sortOrder: action.payload.sort.order,
        // sortField: action.payload.sort.field
      };

    case END_LOADING_PERMISSIONS:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default permissionsReducer;
