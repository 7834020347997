import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import PropTypes from 'prop-types';
import { updateUser } from '../../redux/actions/user';

ApproveUser.propTypes = {
  id: PropTypes.string,
  userData: PropTypes.object,
  activeUsers: PropTypes.bool
};
export default function ApproveUser({ id, userData, activeUsers }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: userData.phoneNumber,
      emailAddress: userData.emailAddress,
      branch: userData.branch,
      roleId: userData.role?.id,
      isApproved: userData.isApproved,
      isSuperUser: userData.isSuperUser
    },
    onSubmit: async (values) => {
      values.isApproved = !values.isApproved;
      const response = await updateUser({ values, dispatch, id, statusUpdate: true, activeUsers });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          {userData.isApproved ? (
            <ToggleOnOutlinedIcon width={24} height={24} />
          ) : (
            <ToggleOffOutlinedIcon width={24} height={24} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={userData.isApproved ? 'Suspend' : 'Activate'}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>{userData.isApproved ? 'Suspend' : 'Activate'}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                User
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${sentenceCase(userData.firstName)} ${sentenceCase(userData.lastName)}'s`}
                </span>{' '}
                account will be {userData.isApproved ? 'Suspended' : 'Activated'} .
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="error">
                {userData.isApproved ? 'Suspend' : 'Activate'}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
