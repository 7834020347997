import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { ApproveUpdateLoan } from '../../redux/actions/loans';

// to check the correct data types and data is passed
ApproveUpdateLoanConfig.propTypes = {
  loanId: PropTypes.string,
  loanData: PropTypes.object
};

export default function ApproveUpdateLoanConfig({ loanId, loanData }) {
  const dispatch = useDispatch();

  const chargeGroups = useSelector((state) => state.reducer.chargesReducer.chargeGroupList);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };
  // phone number and id validator regex
  // const SORT_REPAYMENTPERIOD = [
  //   { value: 'DAILY', label: 'DAILY' },
  //   { value: 'WEEKLY', label: 'WEEKLY' },
  //   { value: 'MONTHLY', label: 'MONTHLY' }
  // ];
  // const SORT_TYPE = [
  //   { value: 'MOBILE', label: 'MOBILE' },
  //   { value: 'SHORT_TERM', label: 'SHORT TERM' },
  //   { value: 'MEDIUM_TERM', label: 'MEDIUM TERM' },
  //   { value: 'LONG_TERM', label: 'LONG TERM' }
  // ];

  const UpdateLoanSchema = Yup.object().shape({
    name: Yup.string().required('Loan name is required'),
    type: Yup.string().required('Loan type is required'),
    minAmountAllowed: Yup.number().required('Minimum Limit is required'),
    maxAmountAllowed: Yup.number().required('Maximum Limit is required'),
    chargeGroupId: Yup.string().required('Charge Type is required'),
    repaymentPeriod: Yup.string().required('Repayment Period is required'),
    installments: Yup.number().required('Installments is required'),
    interestRate: Yup.number().required('Interest Rate is required'),
    isApproved: Yup.boolean()
  });
  const {
    name,
    type,
    minAmountAllowed,
    maxAmountAllowed,
    chargeGroupId,
    repaymentPeriod,
    installments,
    interestRate,
    isActive,
    lastUpdatedBy,
    lastUpdatedAt,
    lastApprovedBy
  } = loanData.pendingUpdateJson;

  const formik = useFormik({
    initialValues: {
      name,
      type,
      minAmountAllowed,
      maxAmountAllowed,
      chargeGroupId,
      repaymentPeriod,
      installments,
      interestRate,
      isActive,
      lastUpdatedBy,
      approvedBy: loanData.approvedBy,
      lastApprovedBy,
      lastUpdatedAt
    },
    validationSchema: UpdateLoanSchema,
    onSubmit: async (values) => {
      const response = await ApproveUpdateLoan({ values, dispatch, loanId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve Loan Configuration Update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <Grid item lg={6}>
                  <Typography color="error">Previous Data</Typography>
                  <TextField
                    disabled
                    margin="normal"
                    id="prevType"
                    label="Type"
                    variant="outlined"
                    defaultValue={loanData.type}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevName"
                    label="Loan Name"
                    variant="outlined"
                    defaultValue={loanData.name}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevminAmountAllowed"
                    label="Minimum Limit"
                    variant="outlined"
                    defaultValue={loanData.minAmountAllowed}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevmaxAmountAllowed"
                    label="Maximum Limit"
                    variant="outlined"
                    defaultValue={loanData.maxAmountAllowed}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Charge Group</InputLabel>
                    <Select
                      disabled
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      {...getFieldProps('chargeGroupId')}
                      name="chargeGroupId"
                      label="Charge Group"
                    >
                      {chargeGroups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    disabled
                    margin="normal"
                    id="prevrepaymentPeriod"
                    label="Repayment Period"
                    variant="outlined"
                    defaultValue={loanData.repaymentPeriod}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="previnterestRate"
                    label="Interest Rate"
                    variant="outlined"
                    defaultValue={loanData.interestRate}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevInstallments"
                    label="Installment"
                    variant="outlined"
                    defaultValue={loanData.installments}
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox checked={loanData.isActive} name="isActive" color="primary" />
                    }
                    label="Is Active Loan Configuration"
                  />
                </Grid>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="type"
                    type="text"
                    margin="normal"
                    label="Loan Type"
                    {...getFieldProps('type')}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="name"
                    type="text"
                    margin="normal"
                    label="Loan Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="minAmountAllowed"
                    type="number"
                    margin="normal"
                    label="Minimum Limit"
                    {...getFieldProps('minAmountAllowed')}
                    error={Boolean(touched.minAmountAllowed && errors.minAmountAllowed)}
                    helperText={touched.minAmountAllowed && errors.minAmountAllowed}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="maxAmountAllowed"
                    type="number"
                    margin="normal"
                    label="Maximum Limit"
                    {...getFieldProps('maxAmountAllowed')}
                    error={Boolean(touched.maxAmountAllowed && errors.maxAmountAllowed)}
                    helperText={touched.maxAmountAllowed && errors.maxAmountAllowed}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Charge Group</InputLabel>
                    <Select
                      disabled
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      {...getFieldProps('chargeGroupId')}
                      name="chargeGroupId"
                      label="Charge Group"
                    >
                      {chargeGroups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="repaymentPeriod"
                    type="text"
                    margin="normal"
                    label="Repayment Period"
                    {...getFieldProps('repaymentPeriod')}
                    error={Boolean(touched.repaymentPeriod && errors.repaymentPeriod)}
                    helperText={touched.repaymentPeriod && errors.repaymentPeriod}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="interestRate"
                    type="number"
                    margin="normal"
                    label="Interest Rate"
                    {...getFieldProps('interestRate')}
                    error={Boolean(touched.interestRate && errors.interestRate)}
                    helperText={touched.interestRate && errors.interestRate}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="installments"
                    type="number"
                    margin="normal"
                    label="Installments"
                    {...getFieldProps('installments')}
                    error={Boolean(touched.installments && errors.installments)}
                    helperText={touched.installments && errors.installments}
                  />
                  <FormControlLabel
                    disabled
                    control={<Checkbox checked={isActive} name="isActive" color="primary" />}
                    label="Is Active Loan configuration"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
