export const START_LOADING_CHARGES = 'START_LOADING_CHARGES';
export const LOAD_CHARGES = 'LOAD_CHARGES';
export const LOAD_CHARGE = 'LOAD_CHARGE';
export const END_LOADING_CHARGES = 'END_LOADING_CHARGES';

export const START_ADD_CHARGE = 'START_ADD_CHARGE';
export const ADD_CHARGE_SUCCESS = 'ADD_CHARGE_SUCCESS';
export const END_ADD_CHARGE = 'END_ADD_CHARGE';

export const START_EDIT_CHARGE = 'START_EDIT_CHARGE';
export const EDIT_CHARGE_SUCCESS = 'EDIT_CHARGE_SUCCESS';
export const END_EDIT_CHARGE = 'END_EDIT_CHARGE';

export const START_LOADING_CHARGE_GROUPS = 'START_LOADING_CHARGE_GROUPS';
export const LOAD_CHARGE_GROUPS = 'LOAD_CHARGE_GROUPS';
export const END_LOADING_CHARGE_GROUPS = 'END_LOADING_CHARGE_GROUPS';

export const START_ADD_CHARGE_GROUP = 'START_ADD_CHARGE_GROUP';
export const ADD_CHARGE_GROUP_SUCCESS = 'ADD_CHARGE_GROUP_SUCCESS';
export const END_ADD_CHARGE_GROUP = 'END_ADD_CHARGE_GROUP';

export const START_EDIT_CHARGE_GROUP = 'START_EDIT_CHARGE_GROUP';
export const EDIT_CHARGE_GROUP_SUCCESS = 'EDIT_CHARGE_GROUP_SUCCESS';
export const END_EDIT_CHARGE_GROUP = 'END_EDIT_CHARGE_GROUP';

export const START_ADD_LOAN = 'START_ADD_LOAN';
export const ADD_CHARGE_LOAN = 'ADD_CHARGE_LOAN';
export const END_ADD_LOAN = 'END_ADD_LOAN';
