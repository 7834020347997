export const START_LOADING_MEMBERS = 'START_LOADING_MEMBERS';
export const LOAD_MEMBERS = 'LOAD_MEMBERS';
export const END_LOADING_MEMBERS = 'END_LOADING_MEMBERS';

export const START_EDIT_MEMBER = 'START_EDIT_MEMBERS';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const END_EDIT_MEMBER = 'END_EDIT_MEMBER';

export const START_STATUS_MEMBER = 'START_STATUS_MEMBER';
export const STATUS_MEMBER_SUCCESS = 'STATUS_MEMBER_SUCCESS';
export const END_STATUS_MEMBER = 'END_STATUS_MEMBER';

export const START_LOADING_ACCOUNTS = 'START_LOADING_ACCOUNTS';
export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS';
export const END_LOADING_ACCOUNTS = 'END_LOADING_ACCOUNTS';

export const START_LOADING_CORPORATE_ACCOUNTS = 'START_LOADING_CORPORATE_ACCOUNTS';
export const LOAD_CORPORATE_ACCOUNTS = 'LOAD_CORPORATE_ACCOUNTS';
export const END_LOADING_CORPORATE_ACCOUNTS = 'END_LOADING_CORPORATE_ACCOUNTS';

export const START_LOADING_TRANSACTIONS = 'START_LOADING_TRANSACTIONS';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const END_LOADING_TRANSACTIONS = 'END_LOADING_TRANSACTIONS';

export const START_PIN_RESET = 'START_PIN_RESET';
export const PIN_RESET_SUCCESS = 'PIN_RESET_SUCCESS';
export const END_PIN_RESET = 'END_PIN_RESET';

export const START_CLEAR_FROM_QUEUE = 'START_CLEAR_FROM_QUEUE';
export const CLEAR_FROM_QUEUE_SUCCESS = 'CLEAR_FROM_QUEUE_SUCCESS';
export const END_CLEAR_FROM_QUEUE = 'END_CLEAR_FROM_QUEUE';

export const START_LINKING_ACCOUNTS = 'START_LINKING_ACCOUNTS';
export const LINKING_ACCOUNTS_SUCCESS = 'LINKING_ACCOUNTS_SUCCESS';
export const END_LINKING_ACCOUNTS = 'END_LINKING_ACCOUNTS';
