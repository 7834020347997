import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import JSONInput from 'react-json-editor-ajrm';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
import { approveMessageUpdate } from '../../redux/actions/messages';

import { localeEn, lightMitsuketaTribute } from '../../utils/localeEn';

// to check the correct data types and data is passed
ApproveMessageUpdate.propTypes = {
  messageId: PropTypes.string,
  messageData: PropTypes.object
};

export default function ApproveMessageUpdate({ messageId, messageData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateMessageUpdateSchema = Yup.object().shape({
    name: Yup.string().required('Message Name is required')
  });

  const { isActive, isApproved, name, message, repeate, scheduleDetails } =
    messageData.pendingUpdateJson;

  const formik = useFormik({
    initialValues: {
      name,
      message,
      repeate,
      scheduleDetails:
        typeof scheduleDetails === 'string' ? JSON.parse(scheduleDetails) : scheduleDetails,
      isApproved,
      isActive
    },
    validationSchema: UpdateMessageUpdateSchema,
    onSubmit: async (values) => {
      values.scheduleDetails = scheduleDetails;
      values.isApproved = true;
      const response = await approveMessageUpdate({ values, dispatch, messageId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckBoxIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Approve Message Update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    margin="normal"
                    label="Message Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    autoComplete="message"
                    type="text"
                    margin="normal"
                    label="Message"
                    {...getFieldProps('message')}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Repeat Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      {...getFieldProps('repeate')}
                      name="repeate"
                      label="Repeat Status"
                      error={Boolean(touched.repeate && errors.repeate)}
                      helperText={touched.repeate && errors.repeate}
                    >
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography sx={{ m: '0.5rem 0' }}>Enter Values as shown below:</Typography>
                  <JSONInput
                    id="user_json"
                    colors={lightMitsuketaTribute}
                    placeholder={
                      typeof scheduleDetails === 'string'
                        ? JSON.parse(scheduleDetails)
                        : scheduleDetails
                    }
                    confirmGood={false}
                    locale={localeEn}
                    style={{
                      outerBox: { border: `1px solid #D9D9D9`, borderRadius: '7px' },
                      container: { width: '85%', height: '95%' },
                      contentBox: { color: '7E7E7E' }
                    }}
                    height="250px"
                    onChange={(input) => {
                      setInputValues(input.json);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
