import { useEffect, useState } from 'react';
// material
import {
  Checkbox,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import Page from '../Page';
import { getDbConfig } from '../../redux/actions/reports';

//

// ----------------------------------------------------------------------

export default function AdvancedReports() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const dbConfig = useSelector((state) => state.reducer.reportsReducer.advancedDbData);
  console.log('DB CONFIG:', dbConfig);
  useEffect(() => {
    getDbConfig({
      dispatch
    });
  }, [dispatch]);
  return (
    <Page title="Advanced Reports | Fortune Sacco">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Advanced Reports
        </Typography>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {dbConfig.tables &&
            dbConfig.tables.map((table) => (
              <ListItemButton role={undefined} onClick={handleChange} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': table }}
                  />
                </ListItemIcon>
                <ListItemText id={table} primary={table} />
              </ListItemButton>
            ))}
        </List>
      </Container>
    </Page>
  );
}
