import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import UpdateChargeGroup from '../../chargeGroups/Update';
import AccessControl from '../../AccessControl';
// import DeleteRole from '../../roles/Delete';

// ----------------------------------------------------------------------
ChargeGroupMoreMenu.propTypes = {
  chargeGroupId: PropTypes.string,
  chargeGroupData: PropTypes.object
};

export default function ChargeGroupMoreMenu({ chargeGroupId, chargeGroupData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* <DeleteRole chargeGroupId={chargeGroupId} chargeGroupData={chargeGroupData} /> */}
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canupdate">
          <UpdateChargeGroup chargeGroupId={chargeGroupId} chargeGroupData={chargeGroupData} />
        </AccessControl>
      </Menu>
    </>
  );
}
