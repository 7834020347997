import {
  START_LOADING_BRANCHES,
  LOAD_BRANCHES,
  END_LOADING_BRANCHES,
  START_EDIT_BRANCH,
  EDIT_BRANCH_SUCCESS,
  END_EDIT_BRANCH,
  START_ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  END_ADD_BRANCH
} from '../constants/branchesConstants';

const initialState = {
  isLoading: false,
  isLoadingBranches: false,
  isAddBranchSuccess: false,
  isAddBranchLoading: false,
  isEditBranchLoading: false,
  isEditBranchSuccess: false,
  branchesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_BRANCHES:
      return { ...state, isLoadingBranches: true };
    case END_LOADING_BRANCHES:
      return { ...state, isLoadingBranches: false };
    case START_ADD_BRANCH:
      return { ...state, isAddBranchLoading: true };
    case ADD_BRANCH_SUCCESS:
      return { ...state, isAddBranchSuccess: true };
    case END_ADD_BRANCH:
      return { ...state, isAddBranchLoading: false };
    case START_EDIT_BRANCH:
      return { ...state, isEditBranchLoading: true };
    case EDIT_BRANCH_SUCCESS:
      return { ...state, isEditBranchSuccess: true };
    case END_EDIT_BRANCH:
      return { ...state, isEditBranchLoading: false };
    case LOAD_BRANCHES:
      return { ...state, branchesList: action.payload };
    default:
      return state;
  }
};

export default branchesReducer;
