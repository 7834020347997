import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, InputLabel, Select } from '@mui/material';
import { updateAdvanceLoan } from '../../../redux/actions/salary-advance';

UpdateAdvanceLoan.propTypes = {
  loanId: PropTypes.string,
  loanData: PropTypes.object
};

export default function UpdateAdvanceLoan({ loanId, loanData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateLoanSchema = Yup.object().shape({
    amountRepaid: Yup.number().nullable(),
    status: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      amountRepaid: loanData?.amountRepaid,
      status: loanData?.status
    },
    validationSchema: UpdateLoanSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await updateAdvanceLoan({ values, dispatch, loanId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update Advance Loan</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="amountRepaid"
                type="number"
                margin="normal"
                label="Amount Repaid"
                {...getFieldProps('amountRepaid')}
                error={Boolean(touched.amountRepaid && errors.amountRepaid)}
                helperText={touched.amountRepaid && errors.amountRepaid}
              />
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="status"
                label="Status"
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <MenuItem value="DISBURSED">DISBURSED</MenuItem>
                <MenuItem value="PARTIALLY_REPAID">PARTIALLY REPAID</MenuItem>
                <MenuItem value="FULLY_PAID">FULLY PAID</MenuItem>
                <MenuItem value="OVERDUE">OVERDUE</MenuItem>
                <MenuItem value="DEFAULTED">DEFAULTED</MenuItem>
                <MenuItem value="WRITTEN_OFF">WRITTEN OFF</MenuItem>
                <MenuItem value="INTEREST_WAIVED">INTEREST WAIVED</MenuItem>
                <MenuItem value="REQUESTED">REQUESTED</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
