// material
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

//

// ----------------------------------------------------------------------

export default function Reports() {
  return (
    <Page title="Dashboard: Reports | Fortune Sacco">
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Ecash Reports
        </Typography>
      </Container>
    </Page>
  );
}
