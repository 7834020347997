export const START_LOADING_SYS_CONFIGS = 'START_LOADING_SYS_CONFIGS';
export const LOAD_SYS_CONFIGS = 'LOAD_SYS_CONFIGS';
export const END_LOADING_SYS_CONFIGS = 'END_LOADING_SYS_CONFIGS';

export const START_ADDING_SYS_CONFIGS = 'START_ADDING_SYS_CONFIGS';
export const ADD_SYS_CONFIG_SUCCESS = 'ADD_SYS_CONFIG_SUCCESS';
export const END_ADDING_SYS_CONFIGS = 'END_ADDING_SYS_CONFIGS';

export const START_EDIT_SYS_CONFIG = 'START_EDIT_SYS_CONFIG';
export const EDIT_SYS_CONFIG_SUCCESS = 'EDIT_SYS_CONFIG_SUCCESS';
export const END_EDIT_SYS_CONFIG = 'END_EDIT_SYS_CONFIG';
