import React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { loginVerifyUser } from '../../../redux/actions/user';

// ----------------------------------------------------------------------

const AuthorizationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate('/login', { replace: true });
  };

  const AuthorizationSchema = Yup.object().shape({
    authorizationCode: Yup.string().required('Code is required')
  });

  const formik = useFormik({
    initialValues: {
      authorizationCode: ''
    },
    validationSchema: AuthorizationSchema,
    onSubmit: async (values) => {
      const response = await loginVerifyUser({ values, dispatch });

      if (response.isDefaultPassword) {
        navigate('/users/password-reset', {
          replace: true,
          state: { isResetDefaultPassword: true }
        });
      } else if (response.token) {
        navigate('/dashboard/app', { replace: true });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="authorizationCode"
            type="authorizationCode"
            label="Authorization Code"
            {...getFieldProps('authorizationCode')}
            error={Boolean(touched.authorizationCode && errors.authorizationCode)}
            helperText={touched.authorizationCode && errors.authorizationCode}
          />
        </Stack>
        <Stack spacing={2} direction="row" sx={{ marginTop: '3rem' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="button"
            variant="outlined"
            color="error"
            onClick={handleBack}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default AuthorizationForm;
