import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import PropTypes from 'prop-types';
import { updateMemberStatus } from '../../redux/actions/members';

ChangeStatus.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};
export default function ChangeStatus({ memberId, memberData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: memberData.firstname,
      lastName: memberData.lastname,
      phoneNumber: memberData.phonenumber,
      nationalId: memberData.nationalid,
      isSuspended: memberData.issuspended
    },
    onSubmit: async (values) => {
      values.isSuspended = !values.isSuspended;
      const response = await updateMemberStatus({ values, dispatch, memberId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          {memberData.issuspended ? (
            <ToggleOnOutlinedIcon width={24} height={24} />
          ) : (
            <ToggleOffOutlinedIcon width={24} height={24} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={memberData.issuspended ? 'Activate' : 'Suspend'}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{memberData.issuspended ? 'Activate' : 'Suspend'}</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                User
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${sentenceCase(memberData.firstname)} ${sentenceCase(memberData.lastname)}'s`}
                </span>{' '}
                account will be {memberData.issuspended ? 'Activated' : 'Suspended'} .
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="error">
                {memberData.issuspended ? 'Activate' : 'Suspend'}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
