import {
  END_LOADING_ADVANCED_REPORT,
  END_LOADING_MEMBERS_REPORT,
  END_LOADING_TRANSACTIONS_REPORT,
  LOAD_ADVANCED_REPORT,
  LOAD_MEMBERS_REPORT,
  LOAD_TRANSACTIONS_REPORT,
  START_LOADING_ADVANCED_REPORT,
  START_LOADING_MEMBERS_REPORT,
  START_LOADING_TRANSACTIONS_REPORT
} from '../constants/reportsConstants';

const initialState = {
  isLoading: false,
  transactionsReportList: [],
  transactionReportCount: 0,
  isLoadingMemberReport: false,
  membersReportList: [],
  memberReportCount: 0,
  advancedDbData: [],
  isLoadingAdvancedReport: false,
  sortOrder: 1,
  sortField: 'createdAt',
  page: 1,
  pageSize: 10,
  searchValue: 'Search Transaction ...'
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_TRANSACTIONS_REPORT:
      return { ...state, isLoading: true };
    case LOAD_TRANSACTIONS_REPORT:
      return {
        ...state,
        transactionsReportList: action.data.payload.data,
        transactionReportCount: action.data.payload.count,
        searchValue: action.data.searchValue
      };

    case END_LOADING_TRANSACTIONS_REPORT:
      return { ...state, isLoading: false };
    // ----------------members----------------
    case START_LOADING_MEMBERS_REPORT:
      return { ...state, isLoadingMemberReport: true };
    case LOAD_MEMBERS_REPORT:
      return {
        ...state,
        membersReportList: action.data.payload.data,
        memberReportCount: action.data.payload.count,
        searchValue: action.data.searchValue
      };

    case END_LOADING_MEMBERS_REPORT:
      return { ...state, isLoadingMemberReport: false };

    // ----------------advanced----------------
    case START_LOADING_ADVANCED_REPORT:
      return { ...state, isLoadingAdvancedReport: true };
    case LOAD_ADVANCED_REPORT:
      return {
        ...state,
        advancedDbData: action.data.payload
      };

    case END_LOADING_ADVANCED_REPORT:
      return { ...state, isLoadingAdvancedReport: false };
    default:
      return state;
  }
};

export default reportsReducer;
