import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box
} from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
//
import { sentenceCase } from 'change-case';
import { getInitials } from '../../utils/get-initials';
import EmptyState from '../EmptyState';
import { fDate } from '../../utils/formatTime';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../_dashboard/user';
import { getUsers } from '../../redux/actions/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'firstName', label: 'Name', alignRight: false },
  { id: 'emailAddress', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isSuperUser', label: 'Super User', alignRight: false },
  { id: 'isApproved', label: 'Active', alignRight: false },
  { id: 'createdAt', label: 'Date Joined', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_member) =>
        _member.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.phoneNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _member.emailAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ActiveUsers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.usersReducer.isLoadingUsers);
  const users = useSelector((state) => state.reducer.usersReducer.usersList);
  const profile = JSON.parse(localStorage.getItem('profile')).id;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = users && applySortFilter(users, getComparator(order, orderBy), filterName);

  const isUserNotFound = users && filteredUsers.length === 0;

  useEffect(() => {
    getUsers({
      dispatch,
      isApproved: 'true'
    });
  }, []);

  return (
    <Box>
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : users && users.length < 1 ? (
        <EmptyState message="No active users available" />
      ) : (
        users && (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          firstName,
                          lastName,
                          emailAddress,
                          phoneNumber,
                          isSuperUser,
                          isApproved,
                          role,
                          createdAt
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={emailAddress}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={firstName}>{`${getInitials(firstName)}${getInitials(
                                  firstName
                                )}${getInitials(lastName)}`}</Avatar>
                                <Typography variant="subtitle2" noWrap>
                                  {`${firstName && sentenceCase(firstName)} ${
                                    lastName && sentenceCase(lastName)
                                  }`}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{emailAddress}</TableCell>
                            <TableCell align="left">{phoneNumber}</TableCell>
                            <TableCell align="left">{role ? role.name : ''}</TableCell>
                            <TableCell align="left">{isSuperUser ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="left">{isApproved ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="left">{createdAt ? fDate(createdAt) : ''}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu id={id} userId={profile} userData={row} activeUsers />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )
      )}
    </Box>
  );
}
