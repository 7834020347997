import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// material
import {
  Container,
  Stack,
  Box,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
//
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { filter } from 'lodash';
import Page from '../components/Page';
import { getFaqs } from '../redux/actions/faq';
import FormDialog from '../components/faq/Create';
import UpdateFaq from '../components/faq/Update';
import DeleteFaq from '../components/faq/Delete';
import EmptyState from '../components/EmptyState';
import SearchNotFound from '../components/SearchNotFound';
import { FaqListToolbar } from '../components/_dashboard/faq';
import AccessControl from '../components/AccessControl';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_faq) =>
        _faq.question.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _faq.answer.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
export default function Faq() {
  const [filterName, setFilterName] = useState('');
  const [
    selected
    /* setSelected */
  ] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.faqsReducer.isLoading);
  const faqs = useSelector((state) => state.reducer.faqsReducer.faqsList);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const filteredFaqs = applySortFilter(faqs, getComparator('asc', 'question'), filterName);
  const isFaqNotFound = filteredFaqs.length === 0;

  useEffect(() => {
    getFaqs({
      dispatch
    });
  }, [dispatch]);
  return (
    <Page title="Dashboard: FAQ | Fortune Sacco">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            FAQ
          </Typography>
          <AccessControl resource="FAQS" allowedPermissions="cancreate">
            <FormDialog />
          </AccessControl>
        </Stack>
        <FaqListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : faqs.length < 1 ? (
          <EmptyState message="No FAQS available" />
        ) : (
          <div>
            {filteredFaqs.map((faq) => (
              <Accordion key={faq.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="secondary.darker">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ width: '80%' }}>
                    <Typography color="primary.light">{faq.answer}</Typography>
                  </Box>
                  <Box sx={{ width: '20%', display: 'flex', flexDirection: 'row' }}>
                    <UpdateFaq faqId={faq.id} faqData={faq} />
                    <DeleteFaq faqId={faq.id} faqData={faq} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
            {isFaqNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </div>
        )}
      </Container>
    </Page>
  );
}
