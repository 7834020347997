import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_CONFIGS,
  LOAD_CONFIGS,
  END_LOADING_CONFIGS,
  START_ADD_CONFIG,
  ADD_CONFIG_SUCCESS,
  END_ADD_CONFIG,
  START_EDIT_CONFIG,
  EDIT_CONFIG_SUCCESS,
  END_EDIT_CONFIG
} from '../constants/configsConstants';

import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingConfigs = () => ({
  type: START_LOADING_CONFIGS
});

const endLoadingConfigs = () => ({
  type: END_LOADING_CONFIGS
});

const startAddConfig = () => ({
  type: START_ADD_CONFIG
});

const addConfigSuccess = () => ({
  type: ADD_CONFIG_SUCCESS
});

const endAddConfig = () => ({
  type: END_ADD_CONFIG
});

const startEditConfig = () => ({
  type: START_EDIT_CONFIG
});

const editConfigSuccess = () => ({
  type: EDIT_CONFIG_SUCCESS
});

const endEditConfig = () => ({
  type: END_EDIT_CONFIG
});

const loadConfigs = (payload) => ({
  type: LOAD_CONFIGS,
  payload
});

// get configs list
export const getConfigs = async ({ dispatch }) => {
  dispatch(startLoadingConfigs());
  const url = '/core/v1/configs/get';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingConfigs());
      if (!data.data.data) {
        dispatch(loadConfigs(data.data.data && data.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadConfigs(data.data.data && data.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingConfigs());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create config
export const addNewConfig = ({ values, dispatch }) => {
  dispatch(startAddConfig());
  const url = `/core/v1/configs/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, userId: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddConfig());
      dispatch(addSuccessMessage(`Config added successfully. Waiting for approval.`));
      dispatch(addConfigSuccess());
      getConfigs({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing Config
export const updateConfig = async ({ values, dispatch, configId }) => {
  dispatch(startEditConfig());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/core/v1/configs/update/${configId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditConfig());
      dispatch(addSuccessMessage('Config updated pending Approval'));
      dispatch(editConfigSuccess());
      getConfigs({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// approve an updated existing Config
export const approveConfigUpdate = async ({ values, dispatch, configId }) => {
  dispatch(startEditConfig());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/core/v1/configs/approve/update/${configId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditConfig());
      dispatch(addSuccessMessage('Config update approved successfully'));
      dispatch(editConfigSuccess());
      getConfigs({ dispatch, pendingUpdateApproval: 'true' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update Config Status
export const updateConfigStatus = ({ values, dispatch, configId }) => {
  dispatch(startEditConfig());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId, configId };
  const url = `/core/v1/configs/update/${configId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditConfig());
      dispatch(addSuccessMessage('Config Status updated successfully'));
      dispatch(editConfigSuccess());
      getConfigs({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
