import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ChangeTicketStatus from '../../tickets/ChangeTicketStatus';
import ViewTicket from '../../tickets/View';
import AccessControl from '../../AccessControl';
import AssignTicket from '../../tickets/AssignTicket';

TicketsMoreMenu.propTypes = {
  ticketId: PropTypes.string,
  ticketData: PropTypes.object,
  profileId: PropTypes.string
};

export default function TicketsMoreMenu({ ticketId, ticketData, profileId }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="TICKETS" allowedPermissions="canread">
          <ViewTicket ticketId={ticketId} ticketData={ticketData} />
        </AccessControl>
        {(ticketData && !ticketData.assignedto) || ticketData.assignedby === profileId ? (
          <AssignTicket ticketId={ticketId} ticketData={ticketData} profileId={profileId} />
        ) : (
          ''
        )}
        <AccessControl resource="TICKETS" allowedPermissions="canupdate">
          {/* eslint-disable-next-line no-nested-ternary */}
          {ticketData.resolved ? (
            ''
          ) : ticketData.assignedto === profileId ? (
            <ChangeTicketStatus ticketId={ticketId} ticketData={ticketData} />
          ) : (
            ''
          )}
        </AccessControl>
      </Menu>
    </>
  );
}
