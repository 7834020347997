export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const START_LOADING_TICKETS = 'START_LOADING_TICKETS';
export const LOAD_TICKETS = 'LOAD_TICKETS';
export const END_LOADING_TICKETS = 'END_LOADING_TICKETS';

export const END_EDIT_TICKET = 'END_EDIT_TICKET';
export const START_STATUS_TICKET = 'START_STATUS_TICKET';
export const STATUS_TICKET_SUCCESS = 'STATUS_TICKET_SUCCESS';
export const STATUS_TICKET = 'STATUS_TICKET';
export const END_STATUS_TICKET = 'END_STATUS_TICKET';
