import {
  ADD_ROLE_SUCCESS,
  START_ADD_ROLE,
  END_ADD_ROLE,
  START_EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  END_EDIT_ROLE,
  START_DELETE_ROLE,
  END_DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  START_LOADING_ROLES,
  LOAD_ROLES,
  END_LOADING_ROLES
} from '../constants/roleConstants';

const initialState = {
  isLoading: false,
  isAddRoleSuccess: false,
  isAddRoleLoading: false,
  isEditRoleLoading: false,
  isEditRoleSuccess: false,
  isDeleteRoleSuccess: false,
  rolesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_ROLE:
      return { ...state, isAddRoleLoading: true };
    case END_ADD_ROLE:
      return { ...state, isAddRoleLoading: false };
    case START_EDIT_ROLE:
      return { ...state, isEditRoleLoading: true };
    case END_EDIT_ROLE:
      return { ...state, isEditRoleLoading: false };
    case START_DELETE_ROLE:
      return { ...state, isDeleteRoleLoading: true };
    case END_DELETE_ROLE:
      return { ...state, isDeleteRoleLoading: false };
    case ADD_ROLE_SUCCESS:
      return { ...state, isAddRoleSuccess: true };
    case EDIT_ROLE_SUCCESS:
      return { ...state, isEditRoleSuccess: true };
    case DELETE_ROLE_SUCCESS:
      return { ...state, isDeleteRoleSuccess: true };
    case START_LOADING_ROLES:
      return { ...state, isLoading: true };

    case LOAD_ROLES:
      return {
        ...state,
        rolesList: action.payload.data
        // count: action.payload.data.length
        // page: action.payload.pagination.pageNumber,
        // pageSize: action.payload.pagination.pageLimit,
        // totalPages: action.payload.pagination.pageCount
        // sortOrder: action.payload.sort.order,
        // sortField: action.payload.sort.field
      };

    case END_LOADING_ROLES:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default rolesReducer;
