import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_MESSAGES,
  LOAD_MESSAGES,
  END_LOADING_MESSAGES,
  START_ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  END_ADD_MESSAGE,
  START_EDIT_MESSAGE,
  EDIT_MESSAGE_SUCCESS,
  END_EDIT_MESSAGE
} from '../constants/messagesConstants';

import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingMessages = () => ({
  type: START_LOADING_MESSAGES
});

const endLoadingMessages = () => ({
  type: END_LOADING_MESSAGES
});

const startAddMessage = () => ({
  type: START_ADD_MESSAGE
});

const addMessageSuccess = () => ({
  type: ADD_MESSAGE_SUCCESS
});

const endAddMessage = () => ({
  type: END_ADD_MESSAGE
});

const startEditMessage = () => ({
  type: START_EDIT_MESSAGE
});

const editMessageSuccess = () => ({
  type: EDIT_MESSAGE_SUCCESS
});

const endEditMessage = () => ({
  type: END_EDIT_MESSAGE
});

const loadMessages = (payload) => ({
  type: LOAD_MESSAGES,
  payload
});

// get messages list
export const getMessages = async ({ dispatch }) => {
  dispatch(startLoadingMessages());
  const url = '/core/v1/messages/get';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingMessages());
      if (!data.data.response) {
        dispatch(loadMessages(data.data.response && data.data.response));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadMessages(data.data.response && data.data.response));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingMessages());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create Message
export const addNewMessage = ({ values, dispatch }) => {
  dispatch(startAddMessage());
  const url = `/core/v1/messages/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, userId: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddMessage());
      dispatch(addSuccessMessage(`Message added successfully.`));
      dispatch(addMessageSuccess());
      getMessages({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddMessage());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing Message
export const updateMessage = async ({ values, dispatch, messageId }) => {
  dispatch(startEditMessage());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/core/v1/messages/update/${messageId}`;
  values.updatedBy = updatedBy;
  values.lastUpdatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditMessage());
      dispatch(addSuccessMessage('Message updated pending Approval'));
      dispatch(editMessageSuccess());
      getMessages({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMessage());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update Message Status
export const updateMessageStatus = ({ values, dispatch, messageId }) => {
  dispatch(startEditMessage());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId, lastUpdatedBy: approverId };
  const url = `/core/v1/messages/update/${messageId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditMessage());
      dispatch(addSuccessMessage('Message Status updated successfully'));
      dispatch(editMessageSuccess());
      getMessages({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMessage());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// approve an updated existing Message
export const approveMessageUpdate = async ({ values, dispatch, messageId }) => {
  dispatch(startEditMessage());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/core/v1/messages/approve/update/${messageId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditMessage());
      dispatch(addSuccessMessage('Message update approved successfully'));
      dispatch(editMessageSuccess());
      getMessages({ dispatch, pendingUpdateApproval: 'true' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMessage());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
