import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import ApproveAccountLinkage from './approveAccountLinkage';

AccountsMoreMenu.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};

export default function AccountsMoreMenu({ memberData, accountNumber, accountData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('profile')).id;

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ApproveAccountLinkage
          accountNumber={accountNumber}
          accountData={accountData}
          memberData={memberData}
          user={loggedUser}
        />
      </Menu>
    </>
  );
}
