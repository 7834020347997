import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const DivStyle = styled('div')(() => ({
  border: '1px solid #b9b9b982',
  borderRadius: '3px',
  position: 'relative',
  minHeight: '300px',
  alignItems: 'center'
}));
const ContainerStyle = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  position: 'absolute',
  top: '40%',
  justifyContent: 'center'
}));
const ImageStyle = styled('img')(({ theme }) => ({
  color: theme.palette.background.dark
}));

const EmptyState = ({ message }) => (
  <DivStyle>
    <ContainerStyle disableGutters maxWidth="xl">
      <ImageStyle height="50px" src="/static/empty.svg" alt="Logo" />
      <Typography color="textSecondary" align="center" variant="h6">
        {message}
      </Typography>
    </ContainerStyle>
  </DivStyle>
);

EmptyState.propTypes = {
  message: PropTypes.string
};
export default EmptyState;
