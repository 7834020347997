import { useState } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
//
import { AntTab, AntTabs, TabPanel } from '../components/CustomTab';
import AllTransactions from '../components/transactions/allTransactions';
import PendingTransactions from '../components/transactions/pendingTransactions';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function TransactionManagement() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Transactions | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transaction Management
          </Typography>
        </Stack>
        <AntTabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab label="All Transactions" />
          <AntTab label="Pending Callback" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <AllTransactions />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PendingTransactions />
        </TabPanel>
      </Container>
    </Page>
  );
}
