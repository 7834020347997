import { useSelector } from 'react-redux';

/**
 * This function checks if a resource is part of the role's assignments and if the role has the permission
 * @param resource
 * @param userPermissions
 * @param allowedPermissions
 * @returns {boolean}
 */

const checkPermissions = (resource, userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  const resourcePermissions = userPermissions.find(
    (permission) => permission.resourcename === resource
  );
  return resourcePermissions[allowedPermissions] === true;
};
/**
 * This wrapper function ensures that the user has the required permissions to view and act on the action.
 * @param resource
 * @param allowedPermissions
 * @param children
 * @param renderNoAccess
 * @returns {*}
 * @constructor
 */

const AccessControl = ({ resource, allowedPermissions, children, renderNoAccess }) => {
  const userPermissions = useSelector((state) => state.reducer.usersReducer.userPermissions);
  const isSuperUser = useSelector((state) => state.reducer.usersReducer.isSuperUser);
  const permitted = isSuperUser
    ? true
    : checkPermissions(resource, userPermissions, allowedPermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null
};

// Compose AccessControl component with redux

export default AccessControl;
