import { secureapi } from '../../config/secureapi';
import {
  CLEAR_FROM_QUEUE_SUCCESS,
  EDIT_MEMBER_SUCCESS,
  END_CLEAR_FROM_QUEUE,
  END_EDIT_MEMBER,
  END_LOADING_ACCOUNTS,
  END_LOADING_MEMBERS,
  END_LOADING_TRANSACTIONS,
  END_PIN_RESET,
  END_STATUS_MEMBER,
  LOAD_ACCOUNTS,
  LOAD_MEMBERS,
  LOAD_TRANSACTIONS,
  PIN_RESET_SUCCESS,
  START_CLEAR_FROM_QUEUE,
  START_EDIT_MEMBER,
  START_LOADING_ACCOUNTS,
  START_LOADING_MEMBERS,
  START_LOADING_TRANSACTIONS,
  START_PIN_RESET,
  START_STATUS_MEMBER,
  STATUS_MEMBER_SUCCESS,
  START_LINKING_ACCOUNTS,
  LINKING_ACCOUNTS_SUCCESS,
  END_LINKING_ACCOUNTS,
  START_LOADING_CORPORATE_ACCOUNTS,
  LOAD_CORPORATE_ACCOUNTS,
  END_LOADING_CORPORATE_ACCOUNTS
} from '../constants/memberConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startEditMember = () => ({
  type: START_EDIT_MEMBER
});

const endEditMember = () => ({
  type: END_EDIT_MEMBER
});

const startStatusMember = () => ({
  type: START_STATUS_MEMBER
});

const endStatusMember = () => ({
  type: END_STATUS_MEMBER
});

const editMemberSuccess = () => ({
  type: EDIT_MEMBER_SUCCESS
});

const statusMemberSuccess = () => ({
  type: STATUS_MEMBER_SUCCESS
});

const startLoadingMembers = () => ({
  type: START_LOADING_MEMBERS
});

const loadMembers = (payload, searchValue) => ({
  type: LOAD_MEMBERS,
  data: { payload, searchValue }
});

const endLoadingMembers = () => ({
  type: END_LOADING_MEMBERS
});

const startLoadingAccounts = () => ({
  type: START_LOADING_ACCOUNTS
});

const loadAccounts = (payload) => ({
  type: LOAD_ACCOUNTS,
  payload
});

const startLoadingCorporateAccounts = () => ({
  type: START_LOADING_CORPORATE_ACCOUNTS
});

const loadCorporateAccounts = (payload) => ({
  type: LOAD_CORPORATE_ACCOUNTS,
  payload
});

const endLoadingCorporateAccounts = () => ({
  type: END_LOADING_CORPORATE_ACCOUNTS
});

const endLoadingAccounts = () => ({
  type: END_LOADING_ACCOUNTS
});

const startLoadingTransactions = () => ({
  type: START_LOADING_TRANSACTIONS
});

const endLoadingTransactions = () => ({
  type: END_LOADING_TRANSACTIONS
});

const loadTransactions = (payload) => ({
  type: LOAD_TRANSACTIONS,
  payload
});

const startPinReset = () => ({
  type: START_PIN_RESET
});

const endPinReset = () => ({
  type: END_PIN_RESET
});

const pinResetSuccess = () => ({
  type: PIN_RESET_SUCCESS
});

const startClearFromQueue = () => ({
  type: START_CLEAR_FROM_QUEUE
});

const endClearFromQueue = () => ({
  type: END_CLEAR_FROM_QUEUE
});

const clearFromQueueSuccess = () => ({
  type: CLEAR_FROM_QUEUE_SUCCESS
});

const startLinkingAccounts = () => ({
  type: START_LINKING_ACCOUNTS
});

const endLinkingAccounts = () => ({
  type: END_LINKING_ACCOUNTS
});

const linkingAccountsSuccess = () => ({
  type: LINKING_ACCOUNTS_SUCCESS
});

const getRequestParams = ({
  isSuspended,
  pendingUpdateApproval,
  searchValue,
  page,
  pageSize,
  deviceCheck,
  isBlocked,
  customerId
}) => {
  const params = {};

  if (isSuspended) {
    params.isSuspended = isSuspended;
  }
  if (pendingUpdateApproval) {
    params.pendingUpdateApproval = pendingUpdateApproval;
  }
  if (searchValue) {
    params.searchValue = searchValue;
  }
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.pageSize = pageSize;
  }
  if (deviceCheck) {
    params.deviceCheck = deviceCheck;
  }
  if (isBlocked) {
    params.isBlocked = isBlocked;
  }
  if (customerId) {
    params.customerId = customerId;
  }
  return params;
};

// fetching members
export const getMembers = async ({
  dispatch,
  pendingUpdateApproval,
  isSuspended,
  isBlocked,
  searchValue,
  page,
  pageSize,
  deviceCheck
}) => {
  dispatch(startLoadingMembers());
  const params = getRequestParams({
    isSuspended,
    isBlocked,
    pendingUpdateApproval,
    searchValue,
    page,
    pageSize,
    deviceCheck
  });
  const url = '/api/v1/customers';

  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingMembers());
      if (!data.data.data) {
        dispatch(loadMembers(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadMembers(data.data, searchValue));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingMembers());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// updating existing member
export const updateMember = async ({ values, dispatch, memberId }) => {
  dispatch(startEditMember());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/customers/update/${memberId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditMember());
      dispatch(addSuccessMessage('Member updated pending Approval'));
      dispatch(editMemberSuccess());
      getMembers({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMember());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// approve an updated existing member
export const approveUpdateMember = async ({ values, dispatch, memberId }) => {
  dispatch(startEditMember());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/customers/approve/update/${memberId}`;
  values.approvedBy = approvedBy;
  // console.log('VALUES: ', values);
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditMember());
      dispatch(addSuccessMessage('Member updated successfully'));
      dispatch(editMemberSuccess());
      getMembers({ dispatch, pendingUpdateApproval: 'true', page: 1, pageSize: 10 });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMember());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// getting member accounts
export const getAccounts = ({ dispatch, memberId }) => {
  dispatch(startLoadingAccounts());
  const url = `/accounts/v1/account/all/${memberId}`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingAccounts());
      if (!data.data.accounts) {
        dispatch(loadAccounts(data.data.accounts));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadAccounts(data.data.accounts));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingAccounts());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// getting member corporate accounts
export const getCorporateAccounts = ({ dispatch, memberId }) => {
  dispatch(startLoadingCorporateAccounts());
  const url = `/accounts/v1/accounts/coop`;
  const params = getRequestParams({
    customerId: memberId
  });
  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingCorporateAccounts());
      if (!data.data.accounts[0]) {
        dispatch(loadCorporateAccounts([]));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadCorporateAccounts(data.data.accounts[0]));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingCorporateAccounts());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update member status
export const updateMemberStatus = ({ values, dispatch, memberId }) => {
  dispatch(startStatusMember());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/api/v1/customers/status/${memberId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endStatusMember());
      dispatch(addSuccessMessage('Member updated pending approval'));
      dispatch(statusMemberSuccess());
      if (values.isSuspended) {
        getMembers({ dispatch, isSuspended: 'false', page: 1, pageSize: 10 });
      } else {
        getMembers({ dispatch, isSuspended: 'true', page: 1, pageSize: 10 });
      }
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditMember());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// get member transactions
export const getTransactions = ({ dispatch, memberId }) => {
  dispatch(startLoadingTransactions());
  const url = `/core/v1/portal/transfers/transaction/${memberId}`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingTransactions());
      if (!data.data.transfers) {
        dispatch(loadTransactions(data.data.transfers));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadTransactions(data.data.transfers));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingTransactions());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// pin reset
export const pinReset = ({ dispatch, memberId }) => {
  dispatch(startPinReset());
  const url = `/auth/v1/otp/pin/reset/create`;
  const data = {
    customerId: memberId
  };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endPinReset());
      dispatch(addSuccessMessage('Member pin reset successfully'));
      dispatch(pinResetSuccess());
      getMembers({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endPinReset());
      // eslint-disable-next-line no-unused-expressions
      message.responseDesc
        ? dispatch(addErrorMessage(message.responseDesc))
        : dispatch(addErrorMessage(message));
      return message;
    });
};

// Reactivate user
export const unsuspendMember = ({ dispatch, memberId }) => {
  dispatch(startPinReset());
  const url = `/auth/v1/customer/recover/manual/${memberId}`;
  const data = {};
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endPinReset());
      dispatch(addSuccessMessage('Member unsuspended successfully'));
      dispatch(pinResetSuccess());
      getMembers({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endPinReset());
      // eslint-disable-next-line no-unused-expressions
      message.responseDesc
        ? dispatch(addErrorMessage(message.responseDesc))
        : dispatch(addErrorMessage(message));
      return message;
    });
};
// pin reset
export const resetDevice = ({ dispatch, memberId, memberData }) => {
  dispatch(startPinReset());
  const url = `/auth/v1/otp/device/reset/create`;
  const data = {
    customerId: memberId,
    phoneNumber: memberData.phonenumber
  };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endPinReset());
      dispatch(addSuccessMessage('Member device reset successfully'));
      dispatch(pinResetSuccess());
      getMembers({ dispatch, page: 1, pageSize: 10, deviceCheck: 'FAILED' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endPinReset());
      // eslint-disable-next-line no-unused-expressions
      message.responseDesc
        ? dispatch(addErrorMessage(message.responseDesc))
        : dispatch(addErrorMessage(message));
    });
};
// pin reset
export const pinUnblock = ({ dispatch, memberId }) => {
  dispatch(startPinReset());
  const url = `/auth/v1/otp/pin/unblock/create`;
  const data = {
    customerId: memberId
  };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endPinReset());
      dispatch(addSuccessMessage('Member pin unblocked successfully'));
      dispatch(pinResetSuccess());
      getMembers({ dispatch, isSuspended: 'false', page: 1, pageSize: 10 });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endPinReset());
      // eslint-disable-next-line no-unused-expressions
      message.responseDesc
        ? dispatch(addErrorMessage(message.responseDesc))
        : dispatch(addErrorMessage(message));
      return message;
    });
};

export const clearFromQueue = ({ dispatch, memberId }) => {
  dispatch(startClearFromQueue());
  const url = `/v1/core/transfer/remove_from_queue`;
  const data = {
    customerId: memberId
  };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endClearFromQueue());
      dispatch(addSuccessMessage('Member Cleared successfully'));
      dispatch(clearFromQueueSuccess());
      getMembers({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endClearFromQueue());
      // eslint-disable-next-line no-unused-expressions
      message.responseDesc
        ? dispatch(addErrorMessage(message.responseDesc))
        : dispatch(addErrorMessage(message));
    });
};

// link corporate accounts
export const linkAccounts = ({ dispatch, values }) => {
  dispatch(startLinkingAccounts());
  const url = `/accounts/v1/link/cooporate`;

  const formData = new FormData();

  formData.append('accountNumber', values.accountNumber);
  formData.append('customerId', values.customerId);
  formData.append('linkageForm', values.linkageForm);
  formData.append('updatedBy', values.updatedBy);

  const data = formData;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return secureapi
    .post(url, data, config)
    .then((response) => {
      dispatch(endLinkingAccounts());
      dispatch(addSuccessMessage('Account Linked successfully'));
      getMembers({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });

      return response;
    })
    .catch(({ message }) => {
      console.log('Error while linking account: ', message.responseDesc);
      dispatch(addErrorMessage(message.responseDesc));
    });
};

// approve account linkage
export const approveAccountLinkage = (dispatch, values) => {
  dispatch(startLinkingAccounts());
  const url = `/accounts/v1/approve/cooporate`;

  return secureapi
    .post(url, values)
    .then((response) => {
      dispatch(endLinkingAccounts());
      dispatch(addSuccessMessage('Approval and Account Linked successfully'));
      dispatch(linkingAccountsSuccess());
      return response;
    })
    .catch((error) => console.log('Error while linking account: ', error));
};
