import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';

import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { addNewSupportTicket } from '../../redux/actions/support';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CreateSupportTicketSchema = Yup.object().shape({
    ticketGroup: Yup.string().required('Ticket Group is required'),
    ticketDescription: Yup.string().required('Ticket Description is required'),
    ticketType: Yup.string().required('Ticket Type is required')
  });

  const formik = useFormik({
    initialValues: {
      ticketGroup: '',
      ticketDescription: '',
      ticketType: ''
    },
    validationSchema: CreateSupportTicketSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewSupportTicket({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const GROUP = [
    { value: 'QUESTION', label: 'QUESTION' },
    { value: 'PROBLEM', label: 'PROBLEM' },
    { value: 'REQUEST', label: 'REQUEST' }
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Support Ticket
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new support ticket</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Ticket Group</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('ticketGroup')}
                  name="ticketGroup"
                  label="Ticket Group"
                  error={Boolean(touched.ticketGroup && errors.ticketGroup)}
                  helperText={touched.ticketGroup && errors.ticketGroup}
                >
                  {GROUP.map((group) => (
                    <MenuItem value={group.value} key={group.value}>
                      {group.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="ticketDescription"
                type="text"
                margin="normal"
                label="Ticket Description"
                multiline
                maxRows={4}
                {...getFieldProps('ticketDescription')}
                error={Boolean(touched.ticketDescription && errors.ticketDescription)}
                helperText={touched.ticketDescription && errors.ticketDescription}
              />
              <TextField
                fullWidth
                autoComplete="ticketType"
                type="text"
                margin="normal"
                label="Ticket Type"
                {...getFieldProps('ticketType')}
                error={Boolean(touched.ticketType && errors.ticketType)}
                helperText={touched.ticketType && errors.ticketType}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
