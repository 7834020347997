export const START_LOADING_PERMISSIONS = 'START_LOADING_PERMISSIONS';
export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS';
export const END_LOADING_PERMISSIONS = 'END_LOADING_PERMISSIONS';

export const START_ADD_PERMISSION = 'START_ADD_PERMISSION';
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const END_ADD_PERMISSION = 'END_ADD_PERMISSION';

export const START_EDIT_PERMISSION = 'START_EDIT_PERMISSION';
export const EDIT_PERMISSION_SUCCESS = 'EDIT_PERMISSION_SUCCESS';
export const EDIT_PERMISSION = 'EDIT_PERMISSION';
export const END_EDIT_PERMISSION = 'END_EDIT_PERMISSION';

export const START_DELETE_PERMISSION = 'START_DELETE_PERMISSION';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const END_DELETE_PERMISSION = 'END_DELETE_PERMISSION';
