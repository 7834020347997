import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { Box, ListItemIcon, ListItemText, MenuItem, Modal, Typography } from '@mui/material';
import { fDate } from '../../utils/formatTime';

ViewLoanConfig.propTypes = {
  loanData: PropTypes.object,
  pendingUpdateJson: PropTypes.object
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  borderRadius: '7px',
  boxShadow: 24,
  backgroundColor: 'white',
  paddingBottom: '1.5rem'
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: '1.5rem'
};

const div = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem'
};

export default function ViewLoanConfig({ loanData, pendingUpdateJson }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Modal open={open} onClose={handleClose} sx={{ width: '85%', marginLeft: '15rem' }}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: 'center', marginTop: '1rem' }}
          >
            View Loan configuration
          </Typography>
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Loan Type:</Typography>
              <p>{loanData?.type}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Minimum Amount:</Typography>
              <p>{loanData?.minAmountAllowed}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Maximum Amount:</Typography>
              <p>{loanData?.maxAmountAllowed}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Charge Type:</Typography>
              <p>{loanData?.chargeGroupId.name}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Repayment Period:</Typography>
              <p>{loanData?.repaymentPeriod}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Interest Rate:</Typography>
              <p>{`${loanData?.interestRate} %`}</p>
            </div>
          </div>
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Installments:</Typography>
              <p>{loanData?.installments}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Created At:</Typography>
              <p>{loanData.createdAt && fDate(loanData.createdAt)}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Approved:</Typography>
              <p>{pendingUpdateJson?.isActive ? 'Yes' : 'No'}</p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Last Updated At:</Typography>
              <p>{loanData.lastUpdatedAt && fDate(pendingUpdateJson?.lastUpdatedAt)}</p>
            </div>
            {/* <div style={div}> */}
            {/*  <Typography sx={{ fontWeight: 'bold' }}>Deleted:</Typography> */}
            {/*  <p  >{loanData?.isDeleted ? 'Yes' : 'No'}</p> */}
            {/* </div> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
