import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { LinearProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AntTab, AntTabs, TabPanel } from '../CustomTab';

import ProfileDetails from './profileDetails';
import MobileLoans from './MobileLoans';
import TransactionsDetails from './Transactions';
import UpdateAuditDetails from './UpdateAuditTrail';
import AccountsDetails from './Accounts';
import DeviceInformation from './DeviceInformation';
// import ViewMemberLoanMeta from './viewMemberLoanMeta';

ViewMember.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '40%',
  margin: 'auto'
}));

export default function ViewMember({ memberId, memberData }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.reducer.membersReducer.accountsList);
  const isLoadingAccounts = useSelector((state) => state.reducer.membersReducer.isAccounts);
  const loanMetaData = useSelector((state) => state.reducer.loanMetaReducer.loanMetaList);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   getLoansMeta({ dispatch });
  // }, []);

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>View Member</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <container style={{ maxWidth: false }}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              <AntTab label="Profile" />
              <AntTab label="Accounts" />
              <AntTab label="Transactions" />
              <AntTab label="Mobile Loans" />
              <AntTab label="Update Audit Trail" />
              <AntTab label="Device Information" />
              {/* <AntTab label="Loan Meta" /> */}
            </AntTabs>
            <TabPanel value={value} index={0}>
              <ProfileDetails memberId={memberId} memberData={memberData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AccountsDetails memberId={memberId} memberData={memberData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TransactionsDetails memberId={memberId} memberData={memberData} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MobileLoans memberId={memberId} memberData={memberData} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <UpdateAuditDetails memberId={memberId} memberData={memberData} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <DeviceInformation memberId={memberId} memberData={memberData} />
            </TabPanel>
            {/* <TabPanel value={value} index={6}>
              <ViewMemberLoanMeta
                memberId={memberId}
                memberData={memberData}
                // loanMetaData={loanMetaData}
              />
            </TabPanel> */}
          </container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
