import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase, sentenceCase } from 'change-case';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
// utils
import { useDispatch, useSelector } from 'react-redux';
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { updateNotification } from '../../redux/actions/systemNofitications';

function renderContent(notification) {
  const userId = JSON.parse(localStorage.getItem('profile')).id;
  const isUnread = notification.readBy ? !notification.readBy.includes(userId) : true;
  const title = (
    <Typography variant="subtitle2">
      {notification.description}
      <Typography
        component="span"
        variant="body2"
        color={isUnread ? 'secondary' : 'text.secondary'}
      >
        &nbsp; {sentenceCase(notification.content)}
      </Typography>
    </Typography>
  );
  if (notification.type === 'mobile') {
    return {
      avatar: <img alt={notification.description} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'portal') {
    return {
      avatar: <img alt={notification.description} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.description} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { avatar, title } = renderContent(notification);
  const handleNotificationRead = async () => {
    await updateNotification({ dispatch, id: notification.id }).then(() => {
      if (notification.description === 'customer-portal-update') {
        navigate('/dashboard/member-management', { replace: true });
      } else if (notification.description === 'user-portal-update') {
        navigate('/dashboard/user', { replace: true });
      }
    });
  };
  return (
    <ListItemButton
      to="#"
      disableGutters
      onClick={handleNotificationRead}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <AccessTimeOutlinedIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const userId = JSON.parse(localStorage.getItem('profile')).id;
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const notifications = useSelector((state) => state.reducer.systemNotif.messages) || [];
  const totalUnRead = notifications.filter((item) =>
    item.readBy ? !item.readBy.includes(userId) : true
  );
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = async () => {
    totalUnRead.map(async (item) => {
      await updateNotification({ dispatch, id: item.id });
    });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead.length} color="secondary">
          <NotificationsNoneOutlinedIcon fontSize="large" />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead.length} unread messages
            </Typography>
          </Box>

          {totalUnRead.length > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <DoneAllOutlinedIcon width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
