import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import LayersClearOutlinedIcon from '@mui/icons-material/LayersClearOutlined';
import { clearFromQueue } from '../../redux/actions/members';

// to check the correct data types and data is passed
ClearFromQueue.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};

export default function ClearFromQueue({ memberId, memberData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await clearFromQueue({ dispatch, memberId });

    if (response.status === 200) {
      handleClose();
    }
  };
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <LayersClearOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="ClearQueue" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Clear From Queue</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography>
              <span style={{ fontWeight: 'bold', color: 'black' }}>
                {` ${sentenceCase(memberData.firstname)} ${sentenceCase(memberData.lastname)} `}
              </span>{' '}
              will be cleared from queue
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Close
            </Button>
            <Button type="submit" color="secondary">
              ClearFromQueue
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
