import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_GL,
  LOAD_GL,
  END_LOADING_GL,
  START_EDIT_GL,
  EDIT_GL_SUCCESS,
  END_EDIT_GL,
  START_ADD_GL,
  ADD_GL_SUCCESS,
  END_ADD_GL
} from '../constants/glConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingGL = () => ({
  type: START_LOADING_GL
});

const endLoadingGL = () => ({
  type: END_LOADING_GL
});

const startAddGL = () => ({
  type: START_ADD_GL
});

const addGLSuccess = () => ({
  type: ADD_GL_SUCCESS
});

const endAddGL = () => ({
  type: END_ADD_GL
});

const startEditGL = () => ({
  type: START_EDIT_GL
});

const EditGLSuccess = () => ({
  type: EDIT_GL_SUCCESS
});

const endEditGL = () => ({
  type: END_EDIT_GL
});

const loadGL = (payload) => ({
  type: LOAD_GL,
  payload
});

// get gl list
export const getGl = ({ dispatch }) => {
  dispatch(startLoadingGL());
  const url = `/accounts/v1/gl-accounts`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingGL());
      if (!data.data.data) {
        dispatch(loadGL(data?.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadGL(data?.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingGL());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create gl
export const addNewGl = ({ values, dispatch }) => {
  dispatch(startAddGL());
  const url = `/accounts/v1/gl-accounts/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddGL());
      dispatch(addSuccessMessage(`GL added successfully`));
      dispatch(addGLSuccess());
      getGl({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddGL());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update gl
export const updateGl = ({ values, dispatch, glId }) => {
  dispatch(startEditGL());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId };
  const url = `/accounts/v1/gl-accounts/update/${glId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditGL());
      dispatch(addSuccessMessage('GL updated successfully'));
      dispatch(EditGLSuccess());
      getGl({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditGL());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
