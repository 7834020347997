import { secureapi } from '../../config/secureapi';
import {
  ADD_RESOURCE_SUCCESS,
  EDIT_RESOURCE_SUCCESS,
  START_ADD_RESOURCE,
  END_ADD_RESOURCE,
  START_EDIT_RESOURCE,
  END_EDIT_RESOURCE,
  START_DELETE_RESOURCE,
  END_DELETE_RESOURCE,
  START_LOADING_RESOURCES,
  LOAD_RESOURCES,
  END_LOADING_RESOURCES,
  DELETE_RESOURCE_SUCCESS
} from '../constants/resourceConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startAddResource = () => ({
  type: START_ADD_RESOURCE
});

const endAddResource = () => ({
  type: END_ADD_RESOURCE
});

const startEditResource = () => ({
  type: START_EDIT_RESOURCE
});

const endEditResource = () => ({
  type: END_EDIT_RESOURCE
});

const startDeleteResource = () => ({
  type: START_DELETE_RESOURCE
});

const endDeleteResource = () => ({
  type: END_DELETE_RESOURCE
});
const addResourceSuccess = () => ({
  type: ADD_RESOURCE_SUCCESS
});
const editResourceSuccess = () => ({
  type: EDIT_RESOURCE_SUCCESS
});
const deleteResourceSuccess = () => ({
  type: DELETE_RESOURCE_SUCCESS
});
const startLoadingResources = () => ({
  type: START_LOADING_RESOURCES
});

const loadResources = (payload) => ({
  type: LOAD_RESOURCES,
  payload
});

const endLoadingResources = () => ({
  type: END_LOADING_RESOURCES
});

// get resources list
export const getResources = ({ dispatch }) => {
  dispatch(startLoadingResources());
  const url = '/resources/all';
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingResources());
      if (!data.data) {
        dispatch(loadResources(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadResources(data.data));
        // dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingResources());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// adds a new resource
export const addNewResource = ({ values, dispatch }) => {
  dispatch(startAddResource());
  const url = '/resources/create';
  const createdBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, isApproved: false, createdBy };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddResource());
      dispatch(addSuccessMessage(`Resource added successfully`));
      dispatch(addResourceSuccess());
      getResources({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddResource());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing resource

export const updateResource = ({ values, dispatch, resourceId }) => {
  dispatch(startEditResource());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy };
  const url = `/resources/update/${resourceId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditResource());
      dispatch(addSuccessMessage(`Resource updated successfully`));
      dispatch(editResourceSuccess());
      getResources({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditResource());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

export const deleteResource = ({ dispatch, resourceId }) => {
  dispatch(startDeleteResource());
  const url = `/resources/delete/${resourceId}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteResource());
      dispatch(addSuccessMessage(`Resource Deleted successfully`));
      dispatch(deleteResourceSuccess());
      getResources({
        dispatch
      });
    })
    .catch(({ message }) => {
      dispatch(endDeleteResource());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
