import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { addNewResource } from '../../redux/actions/resource';

const resources = [
  { label: 'Users', value: 'USERS' },
  { label: 'Transaction Management', value: 'TRANSACTION MANAGEMENT' },
  { label: 'Member Management', value: 'MEMBER MANAGEMENT' },
  { label: 'Access Management', value: 'ACCESS MANAGEMENT' },
  { label: 'System Settings', value: 'SYSTEM SETTINGS' },
  { label: 'Tickets', value: 'TICKETS' },
  { label: 'Faqs', value: 'FAQS' },
  { label: 'Messaging', value: 'MESSAGING' },
  { label: 'Support Tickets', value: 'SUPPORT' },
  { label: 'Reports', value: 'REPORTS' },
  { label: 'Pesalink', value: 'PESALINK' },
  { label: 'Configs', value: 'CONFIGS' },
  { label: 'Loans', value: 'LOANS MANAGEMENT' },
  { label: 'Paybill Deposits', value: 'PAYBILL DEPOSITS' }
];
export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CreateResourceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    parentResourceId: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      parentResourceId: ''
    },
    validationSchema: CreateResourceSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewResource({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Resource
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new system resource</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Resource Name</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={role}
                  // onChange={handleRoleChange}
                  {...getFieldProps('name')}
                  name="name"
                  label="Name"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                >
                  {resources.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
