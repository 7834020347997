import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { updateExternalBiller } from '../../redux/actions/externalBillers';

UpdateExternalBiller.propTypes = {
  externalBillerId: PropTypes.string,
  externalBillerData: PropTypes.object
};

export default function UpdateExternalBiller({ externalBillerId, externalBillerData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateExternalBillerSchema = Yup.object().shape({
    serviceCategory: Yup.string(),
    serviceName: Yup.string(),
    serviceCode: Yup.string(),
    minimumAmount: Yup.number(),
    maximumAmount: Yup.number()
  });

  const formik = useFormik({
    initialValues: {
      serviceCategory: externalBillerData.serviceCategory,
      serviceName: externalBillerData.serviceName,
      serviceCode: externalBillerData.serviceCode,
      minimumAmount: externalBillerData.minimumAmount,
      maximumAmount: externalBillerData.maximumAmount
    },
    validationSchema: UpdateExternalBillerSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await updateExternalBiller({ values, dispatch, externalBillerId });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update External Biller</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="serviceCategory"
                type="text"
                margin="normal"
                label="Service Category"
                {...getFieldProps('serviceCategory')}
                error={Boolean(touched.serviceCategory && errors.serviceCategory)}
                helperText={touched.serviceCategory && errors.serviceCategory}
              />
              <TextField
                fullWidth
                autoComplete="serviceName"
                type="text"
                margin="normal"
                label="Service Name"
                {...getFieldProps('serviceName')}
                error={Boolean(touched.serviceName && errors.serviceName)}
                helperText={touched.serviceName && errors.serviceName}
              />
              <TextField
                fullWidth
                autoComplete="serviceCode"
                type="text"
                margin="normal"
                label="Service Code"
                {...getFieldProps('serviceCode')}
                error={Boolean(touched.serviceCode && errors.serviceCode)}
                helperText={touched.serviceCode && errors.serviceCode}
              />
              <TextField
                fullWidth
                autoComplete="minimumAmount"
                type="number"
                margin="normal"
                label="Minimum Amount"
                {...getFieldProps('minimumAmount')}
                error={Boolean(touched.minimumAmount && errors.minimumAmount)}
                helperText={touched.minimumAmount && errors.minimumAmount}
              />
              <TextField
                fullWidth
                autoComplete="maximumAmount"
                type="number"
                margin="normal"
                label="Maximum Amount"
                {...getFieldProps('maximumAmount')}
                error={Boolean(touched.maximumAmount && errors.maximumAmount)}
                helperText={touched.maximumAmount && errors.maximumAmount}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
