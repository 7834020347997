import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addNewPesalink } from '../../redux/actions/pesalink';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CreatePesalinkSchema = Yup.object().shape({
    bankName: Yup.string().required('Bank name is required'),
    bankAlias: Yup.string().required('Bank alias is required'),
    bankCode: Yup.string().required('Bank code is required')
  });

  const formik = useFormik({
    initialValues: {
      bankName: '',
      bankAlias: '',
      bankCode: ''
    },
    validationSchema: CreatePesalinkSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewPesalink({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Pesalink Bank
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Add a new pesalink bank</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="bankName"
                type="text"
                margin="normal"
                label="Bank Name"
                {...getFieldProps('bankName')}
                error={Boolean(touched.bankName && errors.bankName)}
                helperText={touched.bankName && errors.bankName}
              />
              <TextField
                fullWidth
                autoComplete="bankAlias"
                type="text"
                margin="normal"
                label="Bank Alias"
                {...getFieldProps('bankAlias')}
                error={Boolean(touched.bankAlias && errors.bankAlias)}
                helperText={touched.bankAlias && errors.bankAlias}
              />
              <TextField
                fullWidth
                autoComplete="bankCode"
                type="text"
                margin="normal"
                label="Bank Code"
                {...getFieldProps('bankCode')}
                error={Boolean(touched.bankCode && errors.bankCode)}
                helperText={touched.bankCode && errors.bankCode}
              />
              {/* <TextField
                fullWidth
                autoComplete="contactNumber"
                type="text"
                margin="normal"
                label="Contact Number"
                {...getFieldProps('contactNumber')}
                error={Boolean(touched.contactNumber && errors.contactNumber)}
                helperText={touched.contactNumber && errors.contactNumber}
              /> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
