// const token = localStorage.getItem('token');
const authConfig = {
  baseURL: process.env.REACT_APP_FORTUNE,
  headers: {
    // Authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
    'public-key-pins': `max-age=${process.env.REACT_APP_MAX_AGE};pin-sha256="${process.env.REACT_APP_PUBLIC_KEY_PIN}";`
  }
};

export default authConfig;
