// material
import { Box, Container, Grid, Typography } from '@mui/material';
// components
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import {
  AppCustomerCount,
  AppMpesaCellulantAmount,
  AppMpesaCellulantCount,
  AppMPesaTransactionCount,
  AppMPesaUtilityBalance,
  AppMPesaWorkingBalance,
  AppTotalTransactions,
  AppTransactedAmount
} from '../components/_dashboard/app';
import { getDashboardSummary } from '../redux/actions/dashboard';
import AppSuccessTransactions from '../components/_dashboard/app/AppSuccessTransactions';
import AppFailedTransactions from '../components/_dashboard/app/AppFailedTransactions';
import AppB2BWorkingBalance from '../components/_dashboard/app/AppB2BWorkingBalance';
import AppFloatWorkingBalance from '../components/_dashboard/app/AppFloatWorkingBalance';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const dispatch = useDispatch();
  const userName = localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile')).firstName
    : '';
  useEffect(() => {
    getDashboardSummary({ dispatch });
  }, []);
  return (
    <Page title="Dashboard | Fortune">
      <Container maxWidth={false}>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{`Hi ${userName}, Welcome back`}</Typography>
        </Box>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalTransactions />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTransactedAmount />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppCustomerCount />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppMPesaTransactionCount />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <AppMPesaUtilityBalance />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppMPesaWorkingBalance />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppB2BWorkingBalance />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppFloatWorkingBalance />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <AppSuccessTransactions />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppFailedTransactions />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppMpesaCellulantCount />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppMpesaCellulantAmount />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
