import {
  START_LOADING_LOANS,
  LOAD_LOANS,
  END_LOADING_LOANS,
  START_LOADING_LOAN_TYPES,
  END_LOADING_LOAN_TYPES,
  LOAD_LOAN_TYPES,
  START_ADD_LOAN,
  ADD_LOAN_SUCCESS,
  END_ADD_LOAN
} from '../constants/loansConstants';

const initialState = {
  isLoadingLoans: false,
  isAddLoanSuccess: false,
  isAddLoanLoading: false,
  isLoadingLoanTypes: false,
  loanConfigsList: [],
  loanTypesList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const loanConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_LOANS:
      return { ...state, isLoadingLoans: true };
    case END_LOADING_LOANS:
      return { ...state, isLoadingLoans: false };
    case START_ADD_LOAN:
      return { ...state, isAddLoanLoading: true };
    case ADD_LOAN_SUCCESS:
      return { ...state, isAddLoanSuccess: true };
    case END_ADD_LOAN:
      return { ...state, isAddLoanLoading: false };
    case START_LOADING_LOAN_TYPES:
      return { ...state, isLoadingLoanTypes: true };
    case END_LOADING_LOAN_TYPES:
      return { ...state, isLoadingLoanTypes: false };
    case LOAD_LOAN_TYPES:
      return { ...state, loanTypesList: action.payload };
    case LOAD_LOANS:
      return { ...state, loanConfigsList: action.payload };
    default:
      return state;
  }
};

export default loanConfigsReducer;
