import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import rolesReducer from './rolesReducer';
import resourcesReducer from './resourcesReducer';
import permissionsReducer from './permissionsReducer';
import dashboardReducer from './dashboardReducer';
import membersReducer from './membersReducer';
import ticketsReducer from './ticketsReducer';
import faqsReducer from './faqsReducer';
import notification from './notification';
import chargesReducer from './chargesReducer';
import branchesReducer from './branchesReducer';
import glReducer from './glReducer';
import billersReducer from './billersReducer';
import loansReducer from './loansReducer';
import loanConfigsReducer from './loanConfigsReducer';
import pesalinkReducer from './pesalinkReducer';
import externalBillersReducer from './externalBillersReducer';
import reportsReducer from './reportsReducer';
import supportReducer from './supportReducer';
import configsReducer from './configsReducer';
import messagesReducer from './messagesReducer';
import systemNotif from './systemNotifications';
import loanMetaReducer from './loanMetaReducer';
import transactionsReducer from './transactionsReducer';
import merchantsReducer from './merchantsReducer';
import salaryAdvanceReducer from './salaryAdvanceReducer';
import sysConfigsReducer from './systemConfigsReducer';

export default combineReducers({
  notification,
  systemNotif,
  usersReducer,
  rolesReducer,
  resourcesReducer,
  permissionsReducer,
  dashboardReducer,
  faqsReducer,
  membersReducer,
  ticketsReducer,
  chargesReducer,
  branchesReducer,
  glReducer,
  billersReducer,
  loanConfigsReducer,
  transactionsReducer,
  loansReducer,
  pesalinkReducer,
  reportsReducer,
  externalBillersReducer,
  supportReducer,
  configsReducer,
  messagesReducer,
  loanMetaReducer,
  merchantsReducer,
  salaryAdvanceReducer,
  sysConfigsReducer
});
