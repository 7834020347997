export const START_LOADING_EXTERNALBILLERS = 'START_LOADING_EXTERNALBILLERS';
export const LOAD_EXTERNALBILLERS = 'LOAD_EXTERNALBILLERS';
export const END_LOADING_EXTERNALBILLERS = 'END_LOADING_EXTERNALBILLERS';

export const START_LOADING_EXTERNALBILLER_TYPES = 'START_LOADING_EXTERNALBILLER_TYPES';
export const END_LOADING_EXTERNALBILLER_TYPES = 'END_LOADING_EXTERNALBILLER_TYPES';
export const LOAD_EXTERNALBILLER_TYPES = 'LOAD_EXTERNALBILLER_TYPES';

export const START_ADD_EXTERNALBILLER = 'START_ADD_EXTERNALBILLER';
export const ADD_EXTERNALBILLER_SUCCESS = 'ADD_EXTERNALBILLER_SUCCESS';
export const END_ADD_EXTERNALBILLER = 'END_ADD_EXTERNALBILLER';

export const START_EDIT_EXTERNALBILLER = 'START_EDIT_EXTERNALBILLERS';
export const EDIT_EXTERNALBILLER_SUCCESS = 'EDIT_EXTERNALBILLER_SUCCESS';
export const EDIT_EXTERNALBILLER = 'EDIT_EXTERNALBILLER';
export const END_EDIT_EXTERNALBILLER = 'END_EDIT_EXTERNALBILLER';

export const START_DELETE_EXTERNALBILLER = 'START_DELETE_EXTERNALBILLER';
export const END_DELETE_EXTERNALBILLER = 'END_DELETE_EXTERNALBILLER';
export const DELETE_EXTERNALBILLER_SUCCESS = 'DELETE_EXTERNALBILLER_SUCCESS';
