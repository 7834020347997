import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_BRANCHES,
  LOAD_BRANCHES,
  END_LOADING_BRANCHES,
  START_EDIT_BRANCH,
  EDIT_BRANCH_SUCCESS,
  END_EDIT_BRANCH,
  START_ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  END_ADD_BRANCH
} from '../constants/branchesConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingBranches = () => ({
  type: START_LOADING_BRANCHES
});

const endLoadingBranches = () => ({
  type: END_LOADING_BRANCHES
});

const startAddBranch = () => ({
  type: START_ADD_BRANCH
});

const addBranchSuccess = () => ({
  type: ADD_BRANCH_SUCCESS
});

const endAddBranch = () => ({
  type: END_ADD_BRANCH
});

const startEditBranch = () => ({
  type: START_EDIT_BRANCH
});

const EditBranchSuccess = () => ({
  type: EDIT_BRANCH_SUCCESS
});

const endEditBranch = () => ({
  type: END_EDIT_BRANCH
});

const loadBranches = (payload) => ({
  type: LOAD_BRANCHES,
  payload
});

// get branches list
export const getBranches = ({ dispatch }) => {
  dispatch(startLoadingBranches());
  const url = `/core/v1/branches`;
  return secureapi
    .get(url)
    .then((data) => {
      dispatch(endLoadingBranches());
      if (!data.data.data) {
        dispatch(loadBranches(data?.data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadBranches(data?.data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingBranches());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create branch
export const addNewBranch = ({ values, dispatch }) => {
  dispatch(startAddBranch());
  const url = `/core/v1/branches/create`;
  const creatorId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, createdBy: creatorId };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddBranch());
      dispatch(addSuccessMessage(`Branch added successfully`));
      dispatch(addBranchSuccess());
      getBranches({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddBranch());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update branch
export const updateBranch = ({ values, dispatch, branchId }) => {
  dispatch(startEditBranch());
  const approverId = JSON.parse(localStorage.getItem('profile')).id;
  const data = { ...values, approvedBy: approverId };
  const url = `/core/v1/branches/update/${branchId}`;
  return secureapi
    .put(url, data)
    .then((data) => {
      dispatch(endEditBranch());
      dispatch(addSuccessMessage('Branch updated successfully'));
      dispatch(EditBranchSuccess());
      getBranches({ dispatch });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditBranch());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
