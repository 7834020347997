import {
  START_LOADING_SUPPORT,
  LOAD_SUPPORT,
  END_LOADING_SUPPORT,
  START_STATUS_SUPPORT,
  STATUS_SUPPORT_SUCCESS,
  END_STATUS_SUPPORT,
  START_ADD_SUPPORT,
  END_ADD_SUPPORT,
  ADD_SUPPORT_SUCCESS
} from '../constants/supportConstants';

const initialState = {
  isLoading: false,
  isAddSupportLoading: false,
  isAddSupportSuccess: false,
  isEditSupportLoading: false,
  isEditSupportSuccess: false,
  isStatusSupportSuccess: false,
  supportList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_SUPPORT:
      return { ...state, isAddSupportLoading: true };
    case ADD_SUPPORT_SUCCESS:
      return { ...state, isAddSupportSuccess: true };
    case END_ADD_SUPPORT:
      return { ...state, isAddSupportLoading: false };
    case START_STATUS_SUPPORT:
      return { ...state, isStatusSupportLoading: true };
    case END_STATUS_SUPPORT:
      return { ...state, isStatusSupportLoading: false };
    case STATUS_SUPPORT_SUCCESS:
      return { ...state, isStatusSupportSuccess: true };
    case START_LOADING_SUPPORT:
      return { ...state, isLoading: true };
    case END_LOADING_SUPPORT:
      return { ...state, isLoading: false };
    case LOAD_SUPPORT:
      return {
        ...state,
        supportList: action.payload.payload.ticket,
        count: action.payload.payload.ticketCount,
        page: action.payload.page,
        pageSize: action.payload.pageSize
      };
    default:
      return state;
  }
};

export default supportReducer;
