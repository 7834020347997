export const START_LOADING_TRANSACTIONS = 'START_LOADING_TRANSACTIONS';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const END_LOADING_TRANSACTIONS = 'END_LOADING_TRANSACTIONS';

export const START_LOADING_PAYMENTS = 'START_LOADING_PAYMENTS';
export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';
export const END_LOADING_PAYMENTS = 'END_LOADING_PAYMENTS';

export const START_LOADING_TRANSACTION = 'START_LOADING_TRANSACTION';
export const END_LOADING_TRANSACTION = 'END_LOADING_TRANSACTION';
export const LOAD_TRANSACTION = 'LOAD_TRANSACTION';
//
export const START_PROCESSING_TRANSACTION = 'START_PROCESSING_TRANSACTION';
export const PROCESSING_TRANSACTION_SUCCESS = 'PROCESSING_TRANSACTION_SUCCESS';
export const END_PROCESSING_TRANSACTION = 'END_PROCESSING_TRANSACTION';
