// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
// layouts

// components
import { useLocation, useSearchParams } from 'react-router-dom';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import NotificationMessage from '../components/notificationMessage';
import PasswordResetForm from '../components/authentication/login/PasswordResetForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled('div')(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center'
  // margin: theme.spacing(2, 0, 0, 2)
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  marginTop: 20,
  display: 'flex',
  height: '90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 5)
}));

// ----------------------------------------------------------------------

export default function PasswordReset() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('id');

  const location = useLocation();
  const { state } = location;

  const title = 'Password Reset';
  const isResetDefaultPassword = state && state.isResetDefaultPassword;

  return (
    <RootStyle title="Password Reset | Fortune Sacco">
      <MHidden width="mdDown">
        <SectionStyle>
          <img src="/static/login-background.svg" alt="reset" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm" sx={{ mr: 'unset' }}>
        <NotificationMessage />
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Fortune Web Portal {title}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your new password.</Typography>
          </Stack>
          <PasswordResetForm
            id={userId}
            token={token}
            isResetDefaultPassword={isResetDefaultPassword}
          />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
