import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
// ----------------------------------------------------------------------

const getIcon = ({ icon: Icon }) => Icon && <Icon size="20" />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    label: 'DASHBOARD',
    icon: getIcon({ icon: HomeOutlinedIcon })
  },
  {
    title: 'transaction-management',
    label: 'TRANSACTION MANAGEMENT',
    path: '/dashboard/transaction-management',
    icon: getIcon({ icon: CurrencyExchangeOutlinedIcon }),
    children: [
      {
        title: 'all-transactions',
        path: '/dashboard/transaction-management/all-transactions',
        icon: getIcon({ icon: ReceiptOutlinedIcon })
      },
      {
        title: 'pending-transactions',
        path: '/dashboard/transaction-management/pending-transactions',
        icon: getIcon({ icon: PendingOutlinedIcon })
      },
      {
        title: 'pending-resolution',
        path: '/dashboard/transaction-management/pending-resolution',
        icon: getIcon({ icon: HourglassTopIcon })
      }
    ]
  },
  {
    title: 'loans',
    label: 'LOANS MANAGEMENT',
    path: '/dashboard/loans',
    icon: getIcon({ icon: CreditScoreOutlinedIcon })
  },
  {
    title: 'member-management',
    path: '/dashboard/member-management',
    label: 'MEMBER MANAGEMENT',
    icon: getIcon({ icon: GroupOutlinedIcon })
  },
  {
    title: 'user',
    path: '/dashboard/user',
    label: 'USERS',
    icon: getIcon({ icon: PersonOutlineOutlinedIcon })
  },
  // {
  //   title: 'paybill-deposits',
  //   path: '/dashboard/merchants',
  //   label: 'PAYBILL DEPOSITS',
  //   icon: getIcon({ icon: ShoppingCartIcon })
  // },
  {
    title: 'access-management',
    path: '/dashboard/access-management',
    label: 'ACCESS MANAGEMENT',
    icon: getIcon({ icon: LockOpenOutlinedIcon })
  },
  {
    title: 'tickets',
    path: '/dashboard/tickets',
    label: 'CUSTOMER TICKETS',
    icon: getIcon({ icon: BugReportOutlinedIcon })
  },
  {
    title: 'reports',
    path: '/dashboard/reports',
    label: 'REPORTS',
    icon: getIcon({ icon: AssessmentOutlinedIcon }),
    children: [
      {
        title: 'basic-reports',
        path: '/dashboard/reports/basic',
        icon: getIcon({ icon: PercentOutlinedIcon })
      }
      // {
      //   title: 'advanced-reports',
      //   path: '/dashboard/reports/advanced',
      //   icon: getIcon({ icon: FunctionsOutlinedIcon })
      // }
    ]
  },
  {
    title: 'messages',
    path: '/dashboard/messages',
    label: 'MESSAGE MANAGEMENT',
    icon: getIcon({ icon: ForumOutlinedIcon })
  },
  {
    title: 'system-settings',
    path: '/dashboard/settings',
    label: 'SYSTEM SETTINGS',
    icon: getIcon({ icon: SettingsOutlinedIcon })
  },
  {
    title: 'support-tickets',
    path: '/dashboard/support-tickets',
    label: 'SYSTEM SUPPORT',
    icon: getIcon({ icon: SupportAgentOutlinedIcon })
  },
  {
    title: 'configs',
    path: '/dashboard/configs',
    label: 'CONFIGS',
    icon: getIcon({ icon: HandymanOutlinedIcon })
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // }
  {
    title: 'faq',
    path: '/dashboard/faq',
    label: 'FAQS',
    icon: getIcon({ icon: HelpOutlineOutlinedIcon })
  }
];

export default sidebarConfig;
