import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import ApproveUpdateMember from '../../member/approveUpdate';

PendingMemberMoreMenu.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object
};

export default function PendingMemberMoreMenu({ memberId, memberData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="MEMBER MANAGEMENT" allowedPermissions="canexecute">
          <ApproveUpdateMember memberId={memberId} memberData={memberData} />
        </AccessControl>
      </Menu>
    </>
  );
}
