import {
  START_LOADING_GL,
  LOAD_GL,
  END_LOADING_GL,
  START_EDIT_GL,
  EDIT_GL_SUCCESS,
  END_EDIT_GL,
  START_ADD_GL,
  ADD_GL_SUCCESS,
  END_ADD_GL
} from '../constants/glConstants';

const initialState = {
  isLoading: false,
  isLoadingGL: false,
  isAddGLSuccess: false,
  isAddGLLoading: false,
  isEditGLLoading: false,
  isEditGLSuccess: false,
  glList: [],
  sortOrder: 1,
  sortField: 'insertedAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const glReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_GL:
      return { ...state, isLoadingGL: true };
    case END_LOADING_GL:
      return { ...state, isLoadingGL: false };
    case START_ADD_GL:
      return { ...state, isAddGLLoading: true };
    case ADD_GL_SUCCESS:
      return { ...state, isAddGLSuccess: true };
    case END_ADD_GL:
      return { ...state, isAddGLLoading: false };
    case START_EDIT_GL:
      return { ...state, isEditGLLoading: true };
    case EDIT_GL_SUCCESS:
      return { ...state, isEditGLSuccess: true };
    case END_EDIT_GL:
      return { ...state, isEditGLLoading: false };
    case LOAD_GL:
      return { ...state, glList: action.payload };
    default:
      return state;
  }
};

export default glReducer;
