import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useEffect, useState } from 'react';
import { addNewPermission } from '../../redux/actions/permission';
import { secureapi } from '../../config/secureapi';

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // handle roles selection
  const [roleItems, setRoleItems] = useState([]);
  let results;
  async function getRolesList() {
    const response = await secureapi(`/roles/all`, {});
    results = response.data.data;
    setRoleItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  // handle resources selection
  const [resourceItems, setResourceItems] = useState([]);
  async function getResourcesList() {
    const response = await secureapi(`/resources/all`, {});
    results = response.data.data;
    setResourceItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  const CreatePermissionSchema = Yup.object().shape({
    roleId: Yup.string().required('Role is required'),
    resourceId: Yup.string().required('Resource is required'),
    canCreate: Yup.boolean(),
    canRead: Yup.boolean(),
    canUpdate: Yup.boolean(),
    canDelete: Yup.boolean(),
    canExecute: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      roleId: '',
      resourceId: '',
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
      canExecute: false
    },
    validationSchema: CreatePermissionSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewPermission({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    getRolesList();
    getResourcesList();
  }, []);
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Permission
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new permission</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('roleId')}
                  name="roleId"
                  label="Role"
                >
                  {roleItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="demo-simple-select-outlined-label">Resource</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('resourceId')}
                  name="resourceId"
                  label="Resource"
                >
                  {resourceItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl component="fieldset" variant="standard" margin="normal">
                <FormLabel component="legend">Permissions</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('canCreate')} name="canCreate" />}
                    label="Add New"
                  />
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('canRead')} name="canRead" />}
                    label="View"
                  />
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('canUpdate')} name="canUpdate" />}
                    label="Edit"
                  />
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('canDelete')} name="canDelete" />}
                    label="Delete"
                  />
                  {/* Can execute is used as can approve checker */}
                  <FormControlLabel
                    control={<Checkbox {...getFieldProps('canExecute')} name="canExecute" />}
                    label="Approve"
                  />
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
