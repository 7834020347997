import {
  ADD_MESSAGE,
  ADD_SUCCESS_MESSAGE,
  ADD_ERROR_MESSAGE,
  CLEAR_MESSAGE,
  ADD_WARNING_MESSAGE
} from '../constants/notification';

const initialState = {
  type: 'info',
  message: ''
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state, message: action.message };

    case ADD_SUCCESS_MESSAGE:
      return { ...state, type: 'success', message: action.message };

    case ADD_ERROR_MESSAGE:
      return { ...state, type: 'error', message: action.message };

    case ADD_WARNING_MESSAGE:
      return { ...state, type: 'warning', message: action.message };

    case CLEAR_MESSAGE:
      return { ...state, type: 'info', message: '' };

    default:
      return state;
  }
};

export default notification;
