import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_SYS_CONFIGS,
  LOAD_SYS_CONFIGS,
  END_LOADING_SYS_CONFIGS,
  START_EDIT_SYS_CONFIG,
  EDIT_SYS_CONFIG_SUCCESS,
  END_EDIT_SYS_CONFIG,
  START_ADDING_SYS_CONFIGS,
  END_ADDING_SYS_CONFIGS,
  ADD_SYS_CONFIG_SUCCESS
} from '../constants/systemConfigsConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingSysConfigs = () => ({
  type: START_LOADING_SYS_CONFIGS
});

const loadSysConfigs = (payload) => ({
  type: LOAD_SYS_CONFIGS,
  payload
});

const endLoadingSysConfigs = () => ({
  type: END_LOADING_SYS_CONFIGS
});

const startAddSysConfig = () => ({
  type: START_ADDING_SYS_CONFIGS
});

const addSysConfigSuccess = () => ({
  type: ADD_SYS_CONFIG_SUCCESS
});

const endAddSysConfig = () => ({
  type: END_ADDING_SYS_CONFIGS
});

const startEditSysConfig = () => ({
  type: START_EDIT_SYS_CONFIG
});

const editSysConfigSuccess = () => ({
  type: EDIT_SYS_CONFIG_SUCCESS
});

const endEditSysConfig = () => ({
  type: END_EDIT_SYS_CONFIG
});

// get all system configs
export const getSystemConfigs = async ({ dispatch }) => {
  dispatch(startLoadingSysConfigs());
  const url = `/configs/all`;
  await secureapi
    .get(url)
    .then((data) => {
      console.log('CONFIGS >>>> ', data);
      dispatch(endLoadingSysConfigs());
      if (!data.data) {
        dispatch(loadSysConfigs(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadSysConfigs(data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingSysConfigs());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// create a system config
export const addNewSysConfig = ({ values, dispatch }) => {
  dispatch(startAddSysConfig());
  const url = `/configs/create`;
  return secureapi
    .post(url, values)
    .then((data) => {
      dispatch(endAddSysConfig());
      dispatch(addSuccessMessage(`System config added successfully.`));
      dispatch(editSysConfigSuccess());
      getSystemConfigs({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddSysConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update a system config
export const updateSysConfig = ({ values, dispatch, sysConfigId }) => {
  dispatch(startEditSysConfig());
  const url = `/configs/update/${sysConfigId}`;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditSysConfig());
      dispatch(addSuccessMessage(`System config updated successfully.`));
      dispatch(editSysConfigSuccess());
      getSystemConfigs({
        dispatch
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditSysConfig());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
