import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import UpdatePermission from '../../permissions/Update';
import DeletePermission from '../../permissions/Delete';
import AccessControl from '../../AccessControl';

// ----------------------------------------------------------------------
PermissionMoreMenu.propTypes = {
  permissionId: PropTypes.string,
  permissionData: PropTypes.object
};

export default function PermissionMoreMenu({ permissionId, permissionData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="ACCESS MANAGEMENT" allowedPermissions="canupdate">
          <UpdatePermission permissionId={permissionId} permissionData={permissionData} />
        </AccessControl>
        <AccessControl resource="ACCESS MANAGEMENT" allowedPermissions="candelete">
          <DeletePermission permissionId={permissionId} permissionData={permissionData} />
        </AccessControl>
      </Menu>
    </>
  );
}
