import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export const AntTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  height: 36,
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main
  }
}));

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  height: 36,
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  // color: 'rgba(0, 0, 0, 0.85)',
  '&:hover': {
    color: theme.palette.secondary.main,
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.primary.light
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));

export const SecondaryTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(3),
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.primary.light,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.light
  }
}));

export const SecondaryTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  // color: 'rgba(0, 0, 0, 0.85)',
  '&:hover': {
    color: theme.palette.secondary.main,
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '5px'
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));
