import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import { useEffect, useState } from 'react';
import { updatePermission } from '../../redux/actions/permission';
import { secureapi } from '../../config/secureapi';

UpdateUser.propTypes = {
  permissionId: PropTypes.string,
  permissionData: PropTypes.object
};

export default function UpdateUser({ permissionId, permissionData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(permissionData.isapproved);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  // handle roles selection
  const [roleItems, setRoleItems] = useState([]);
  let results;
  async function getRolesList() {
    const response = await secureapi(`/roles/all`, {});
    results = response.data.data;
    setRoleItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  // handle resources selection
  const [resourceItems, setResourceItems] = useState([]);
  async function getResourcesList() {
    const response = await secureapi(`/resources/all`, {});
    results = response.data.data;
    setResourceItems(results.map(({ name, id }) => ({ label: name, value: id })));
  }
  const UpdateUserSchema = Yup.object().shape({
    roleId: Yup.string().required('Role is required'),
    resourceId: Yup.string().required('Resource is required'),
    canCreate: Yup.boolean(),
    canRead: Yup.boolean(),
    canUpdate: Yup.boolean(),
    canDelete: Yup.boolean(),
    canExecute: Yup.boolean(),
    isApproved: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      roleId: permissionData.roleid,
      resourceId: permissionData.resourceid,
      canCreate: permissionData.cancreate,
      canRead: permissionData.canread,
      canUpdate: permissionData.canupdate,
      canDelete: permissionData.candelete,
      canExecute: permissionData.canexecute,
      isApproved: permissionData.isapproved
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { resetForm }) => {
      values.isApproved = isChecked;
      const response = await updatePermission({ values, dispatch, permissionId });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });

  const { handleSubmit, getFieldProps, handleChange, values } = formik;
  useEffect(() => {
    getRolesList();
    getResourcesList();
  }, []);
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update system permission</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('roleId')}
                  name="roleId"
                  label="Role"
                >
                  {roleItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="demo-simple-select-outlined-label">Resource</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('resourceId')}
                  name="resourceId"
                  label="Resource"
                >
                  {resourceItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl component="fieldset" variant="standard" margin="normal">
                <FormLabel component="legend">Permissions</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.canCreate}
                        onChange={handleChange}
                        name="canCreate"
                      />
                    }
                    label="Add New"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={values.canRead} onChange={handleChange} name="canRead" />
                    }
                    label="View"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.canUpdate}
                        onChange={handleChange}
                        name="canUpdate"
                      />
                    }
                    label="Edit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.canDelete}
                        onChange={handleChange}
                        name="canDelete"
                      />
                    }
                    label="Delete"
                  />
                  {/* Can execute is used as can approve checker */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.canExecute}
                        onChange={handleChange}
                        name="canExecute"
                      />
                    }
                    label="Can Approve"
                  />
                </FormGroup>
              </FormControl>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheck}
                      name="isApproved"
                      color="primary"
                    />
                  }
                  label="Approve Permission"
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
