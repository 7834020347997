import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormControl, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { updateSysConfig } from '../../redux/actions/system-configs';

export default function UpdateSysConfig({ sysConfigId, sysConfigData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const user = localStorage.getItem('userId');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateSysConfigSchema = Yup.object().shape({
    key: Yup.string(),
    value: Yup.string(),
    description: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      key: sysConfigData?.key,
      value: sysConfigData?.value,
      description: sysConfigData?.description
    },
    validationSchema: UpdateSysConfigSchema,
    onSubmit: async (values, { resetForm }) => {
      values.createdBy = user;
      values.createdAt = new Date();
      values.type = '';

      const response = await updateSysConfig({ values, dispatch, sysConfigId });

      console.log('RESPONSE >>> ', response);

      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new system config</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  fullWidth
                  autoComplete="key"
                  type="text"
                  margin="normal"
                  label="Config Key"
                  {...getFieldProps('key')}
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                />
              </FormControl>
              <TextField
                fullWidth
                autoComplete="value"
                type="text"
                margin="normal"
                label="Config Value"
                {...getFieldProps('value')}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />
              <TextField
                fullWidth
                autoComplete="description"
                type="text"
                margin="normal"
                label="Config Description"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
