import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import EmptyState from '../EmptyState';
import { getAccounts, getTransactions } from '../../redux/actions/members';
import { fDate } from '../../utils/formatTime';
import { secureapi } from '../../config/secureapi';

UpdateAuditDetails.propTypes = {
  memberId: PropTypes.string,
  memberData: PropTypes.object,
  transactions: PropTypes.array
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem'
};

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxwidth: '40%',
  margin: 'auto'
}));

export default function UpdateAuditDetails({ memberId, memberData }) {
  const [open, setOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const dispatch = useDispatch();

  console.log('MEMBER DATA: ', memberData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let results;
  async function getMemberTransactions() {
    const response = await secureapi(`/core/v1/portal/transfers/transaction/${memberId}`, {});
    results = response.data.transfers;
    setTransactions(results);
  }

  useEffect(() => {
    getAccounts({ dispatch, memberId });
    getMemberTransactions();
  }, [dispatch, memberId]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Last Updated By
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Last Updated At
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Last Approved By
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Last Approved At
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={memberData?.customerId}>
              <TableCell align="left">
                {memberData?.lastupdatedby ? memberData.lastupdatedby : ''}
              </TableCell>
              <TableCell align="left">
                {memberData?.updatedat ? memberData.lastapprovedat : ''}
              </TableCell>
              <TableCell align="left">
                {memberData?.lastapprovedby ? memberData.lastapprovedby : ''}
              </TableCell>
              <TableCell align="left">
                {memberData?.lastapprovedat ? memberData.lastapprovedat : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
