import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Box
} from '@mui/material';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { approveAccountLinkage, getAccounts } from '../../redux/actions/members';

ApproveAccountLinkage.propTypes = {
  accountNumber: PropTypes.string,
  memberData: PropTypes.object,
  accountData: PropTypes.object
};

function ApproveAccountLinkage({ memberData, accountNumber, user, accountData }) {
  const [open, setOpen] = useState(false);
  console.log('member data >>> ', memberData);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccountApproval = async () => {
    const values = {
      customerId: accountData.customerId,
      accountNumber,
      updatedBy: user
    };
    const response = await approveAccountLinkage(dispatch, values);

    if (response.status === 200) {
      handleClose();
      getAccounts({ dispatch, memberId: memberData.customerId });
    }
  };

  return (
    <div>
      {user !== accountData?.lastUpdatedBy ? (
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={handleClickOpen}
        >
          <ListItemIcon>
            <DoneIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Approve Account" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CloseIcon sx={{ marginLeft: '0.4rem' }} width={20} height={24} />
          <Typography
            sx={{
              fontSize: '0.88rem',
              marginLeft: '0.8rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Cannot Approve!!
          </Typography>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve Account</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to approve the linkage into account <strong>{accountNumber}</strong> for{' '}
            <strong>
              {memberData?.firstname} {memberData?.lastname}
            </strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={handleAccountApproval}>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ApproveAccountLinkage;
