export const START_LOADING_ROLES = 'START_LOADING_ROLES';
export const LOAD_ROLES = 'LOAD_ROLES';
export const END_LOADING_ROLES = 'END_LOADING_ROLES';

export const START_ADD_ROLE = 'START_ADD_ROLE';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const END_ADD_ROLE = 'END_ADD_ROLE';

export const START_EDIT_ROLE = 'START_EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE = 'EDIT_ROLE';
export const END_EDIT_ROLE = 'END_EDIT_ROLE';

export const START_DELETE_ROLE = 'START_DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE = 'DELETE_ROLE';
export const END_DELETE_ROLE = 'END_DELETE_ROLE';
