import { useEffect, useState } from 'react';
// material
import { Box, Chip, IconButton, LinearProgress, Stack } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
//
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fDate } from '../../utils/formatTime';
import Scrollbar from '../Scrollbar';
import { getDownloadTransactionReports, getTransactionReports } from '../../redux/actions/reports';
import { fCurrency } from '../../utils/formatNumber';

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

const StyledTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  // height: 400,
  border: '1px solid',
  borderColor: `${theme.palette.primary.lighter} !important`,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

// ----------------------transaction types ---------------------
const tTypes = [
  'REGULAR_DEPOSIT',
  'TERM_DEPOSIT',
  'LOAN_REPAYMENT',
  'INTERNAL_BILL_PAYMENT',
  'EXTERNAL__BILL_PAYMENT',
  'REVERSAL',
  'AUTO_REVERSAL',
  'AUTO_REVERSAL_CHARGES',
  'CELLULANT',
  'MPESA_C2B',
  'MPESA_B2C',
  'MPESA_B2B',
  'MOBILE_LOAN',
  'CHARGES_TRANSFER_SACCO',
  'CHARGES_TRANSFER_EXCISE',
  'CHARGES_TRANSFER_MPESA_COMMISSION',
  'PESALINK_A2A',
  'LOAN',
  'BUY_FLOAT'
];
// ----------------------------------------------------------------------
export default function TransactionReports() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.reportsReducer.isLoading);
  const transactionsReportList = useSelector(
    (state) => state.reducer.reportsReducer.transactionsReportList
  );
  const transactionReportCount = useSelector(
    (state) => state.reducer.reportsReducer.transactionReportCount
  );
  const [createDateFrom, setCreateDateFrom] = useState(null);
  const [createDateTo, setCreateDateTo] = useState(null);
  const [updateDateFrom, setUpdateDateFrom] = useState(null);
  const [updateDateTo, setUpdateDateTo] = useState(null);
  const [downloadFilters, setDownloadFilters] = useState({});
  // --------------------change page--------------------
  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);
    getTransactionReports({
      dispatch,
      page: page + 1,
      pageSize: rowsPerPage,
      ...downloadFilters
    });
  };
  // ---------------change rows per page -------------
  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));

    getTransactionReports({
      dispatch,
      page: page + 1,
      pageSize: parseInt(newRowsPerPage, 10),
      ...downloadFilters
    });
  };
  // ---------------------------handle file download --------------------
  const handleReportDownload = async () => {
    await getDownloadTransactionReports({ dispatch, ...downloadFilters });
  };
  // ------------------ handle filter submit-------------------------------
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    const filterList = {};
    // eslint-disable-next-line array-callback-return
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === 't_created_at') {
        newFilter = { createDateFrom: item[0], createDateTo: item[1] };
      } else if (key === 't_updated_at') {
        newFilter = { updateDateFrom: item[0], updateDateTo: item[1] };
      } else {
        newFilter = { [key]: item[0] };
      }
      Object.assign(filterList, newFilter);
    });
    setDownloadFilters(filterList);
    console.log('FILTER LIST: ', filterList);
    getTransactionReports({
      dispatch,
      page: 1,
      pageSize: rowsPerPage,
      ...filterList
    });
  };
  const DownloadAllButton = () => (
    <>
      <IconButton
        color="primary"
        aria-label="Download all"
        component="label"
        onClick={handleReportDownload}
      >
        <CloudDownloadOutlinedIcon fontSize="medium" />
      </IconButton>
    </>
  );
  // createdAt: "2022-05-27T14:50:38.505Z"
  // customerId: "24844079-cda3-4b59-bd4b-1df31a708665"
  // id: "bc20f2d8-fd16-4154-bced-1a5774961be8"
  // transferId: "bc20f2d8-fd16-4154-bced-1a5774961be8"
  // transferOriginId: null
  // userTransferDescription: null
  // ----------------------------------------------------------------------
  const columns = [
    { name: 't_transfer_reference', label: 'Transfer Ref' },
    { name: 't_account_from', label: 'Account From', options: { filter: false } },
    {
      name: 't_account_to',
      label: 'Account To',
      options: { filter: false }
    },
    {
      name: 't_amount',
      label: 'Amount',
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) =>
          `Ksh ${fCurrency(transactionsReportList[dataIndex].t_amount)}`
      }
    },
    {
      name: 'phone_number',
      label: 'Customer Phone',
      options: { filter: false }
    },
    {
      name: 'first_name',
      label: 'Customer Name',
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) =>
          `${transactionsReportList[dataIndex].first_name} ${transactionsReportList[dataIndex].last_name}`
      }
    },
    {
      name: 'cus_branch',
      label: 'Customer Branch',
      options: { filter: false }
    },
    {
      name: 't_transfer_type',
      label: 'Transfer Type',
      options: {
        filter: true,
        filterType: 'dropdown',
        filterOptions: {
          names: tTypes,
          renderValue: (v) => v
        },
        customFilterListOptions: {
          render: (v) => v
        }
      }
    },
    {
      name: 't_transfer_description',
      label: 'Transfer Description',
      options: { filter: false }
    },
    {
      name: 't_transfer_status',
      label: 'Transfer Status',
      options: {
        filter: true,
        filterOptions: {
          renderValue: (v) => v
        },
        customFilterListOptions: {
          // eslint-disable-next-line consistent-return
          render: (v) => v
        },
        // display: 'excluded',
        filterType: 'dropdown',
        customBodyRenderLite: (dataIndex) =>
          transactionsReportList[dataIndex].t_transfer_status === 'SUCCESS' ? (
            <Chip label="Success" color="success" />
          ) : (
            <Chip label="Failed" color="error" />
          )
      }
    },
    {
      name: 't_created_at',
      label: 'Created At',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`Created From: ${v[0]}`, `Created To: ${v[1]}`];
            }
            if (v[0]) {
              return `Created From: ${v[0]}`;
            }
            if (v[1]) {
              return `Created To: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log('customFilterListOnDelete: ', filterList, filterPos, index);

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          names: [],
          logic(createdAt, filters) {
            if (filters[0] && filters[1]) {
              return createdAt < filters[0] || createdAt > filters[1];
            }
            if (filters[0]) {
              return createdAt < filters[0];
            }
            if (filters[1]) {
              return createdAt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created From:"
                  value={createDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setCreateDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created To:"
                  value={createDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setCreateDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          )
        },
        customBodyRenderLite: (dataIndex) => fDate(transactionsReportList[dataIndex].t_created_at)
      }
    },
    {
      name: 't_updated_at',
      label: 'Updated At',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`Updated From: ${v[0]}`, `Updated To: ${v[1]}`];
            }
            if (v[0]) {
              return `Updated From: ${v[0]}`;
            }
            if (v[1]) {
              return `Updated To: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log('customFilterListOnDelete: ', filterList, filterPos, index);

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          names: [],
          logic(updatedAt, filters) {
            if (filters[0] && filters[1]) {
              return updatedAt < filters[0] || updatedAt > filters[1];
            }
            if (filters[0]) {
              return updatedAt < filters[0];
            }
            if (filters[1]) {
              return updatedAt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated From:"
                  value={updateDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setUpdateDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated To:"
                  value={updateDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setUpdateDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          )
        },
        customBodyRenderLite: (dataIndex) => fDate(transactionsReportList[dataIndex].t_updated_at)
      }
    },
    {
      name: 'customer_receipt',
      label: 'External Receipt',
      options: { filter: false }
    },
    {
      name: 'pay_provider',
      label: 'External Payment Provider',
      options: { filter: false }
    },
    {
      name: 'pay_status',
      label: 'External Payment Status',
      options: { filter: false }
    },
    {
      name: 't_user_transfer_description',
      label: 'Customer Transfer Description',
      options: { filter: false }
    },
    {
      name: 't_transfer_error',
      label: 'Internal Transfer Error',
      options: { filter: false }
    }
  ];
  const options = {
    filter: true, // show the filter icon in the toolbar (true by default)
    filterType: 'textField',
    responsive: 'standard',
    serverSide: true,
    count: transactionReportCount,
    rowsPerPage,
    elevation: 4,
    rowsPerPageOptions: [15, 30, 60],
    confirmFilters: true,
    download: false,
    searchable: false,
    customToolbar: () => <DownloadAllButton />,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: '40px' }}>
        <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
          Apply Filters
        </Button>
      </Box>
    ),

    // callback that gets executed when filters are confirmed
    onFilterConfirm: () => {
      console.log('onFilterConfirm');
    },

    onFilterDialogOpen: () => {
      console.log('filter dialog opened');
    },
    onFilterDialogClose: () => {
      console.log('filter dialog closed');
    },
    onFilterChange: (column, filterList, type) => {
      if (type === 'chip') {
        console.log('updating filters via chip');
        // handleFilters(newFilters);
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState);
          break;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log('action not handled.');
      }
    }
  };
  const components = {
    icons: {
      PrintOutlinedIcon,
      FilterListOutlinedIcon
    }
  };
  // to get transaction list
  useEffect(() => {
    getTransactionReports({
      dispatch,
      page: 1,
      pageSize: rowsPerPage
    });
  }, [dispatch, rowsPerPage]);

  return (
    <Box style={{ marginTop: 60 }}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LinearProgressStyle />
      ) : transactionsReportList && transactionsReportList.length < 1 ? (
        <Scrollbar>
          <StyledTableContainer>
            <MUIDataTable
              title="Transaction Reports"
              data={transactionsReportList}
              columns={columns}
              options={options}
              components={components}
            />
          </StyledTableContainer>
        </Scrollbar>
      ) : (
        transactionsReportList && (
          <Scrollbar>
            <StyledTableContainer>
              <MUIDataTable
                title=""
                data={transactionsReportList}
                columns={columns}
                options={options}
              />
            </StyledTableContainer>
          </Scrollbar>
        )
      )}
    </Box>
  );
}
