import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';

import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Checkbox,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

import { TransactionListHead } from '../../_dashboard/transactions';

import EmptyState from '../../EmptyState';
import Scrollbar from '../../Scrollbar';
import { fDate } from '../../../utils/formatTime';
import { getPayments } from '../../../redux/actions/transactions';
import PendingResolutionMoreMenu from './pendingResolutionMoreMenu';
import PendingResolutionListToolbar from './pendingResolutionsListToolbar';
import SearchNotFound from '../../SearchNotFound';

const TABLE_HEAD = [
  { id: 'paymentMetadata.phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'customerReceipt', label: 'MPESA Code', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_transfer) =>
        _transfer.transferReference.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _transfer.accountFrom.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

function PendingResolution() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('customerReceipt');
  const [filterName, setFilterName] = useState('');
  const isLoading = useSelector((state) => state.reducer.transactionsReducer.isLoadingPayments);
  const payments = useSelector((state) => state.reducer.transactionsReducer.paymentsList);
  const totalPayments = useSelector((state) => state.reducer.transactionsReducer.count);

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = payments.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPayments({
      dispatch,
      provider: 'SAFARICOM_C2B_STK',
      status: 'SENT_EXTERNAL',
      page: newPage + 1,
      pageSize: rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getPayments({
      dispatch,
      provider: 'SAFARICOM_C2B_STK',
      status: 'SENT_EXTERNAL',
      page: page + 1,
      pageSize: parseInt(event.target.value, 10)
    });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredPayments = applySortFilter(payments, getComparator(order, orderBy), filterName);

  const isPaymentNotFound = filteredPayments?.length === 0;

  useEffect(() => {
    getPayments({
      dispatch,
      provider: 'SAFARICOM_C2B_STK',
      status: 'SENT_EXTERNAL',
      page: 1,
      pageSize: rowsPerPage
    });
  }, [dispatch]);

  return (
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          Pending Resolution
        </Typography>
        <PendingResolutionListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : payments && payments.length < 1 ? (
          <EmptyState message="No payments available" />
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TransactionListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={payments?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredPayments?.map((row) => {
                      const { id, paymentMetadata, amount, customerReceipt, updatedAt, createdAt } =
                        row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {paymentMetadata ? paymentMetadata.phoneNumber : 'Not Available'}
                          </TableCell>
                          <TableCell align="left">{amount}</TableCell>
                          <TableCell align="left">{customerReceipt}</TableCell>
                          <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                          <TableCell align="left">{updatedAt && fDate(updatedAt)}</TableCell>
                          <TableCell align="right">
                            <PendingResolutionMoreMenu paymentId={id} paymentData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isPaymentNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalPayments}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Box>
    </Box>
  );
}

export default PendingResolution;
