import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';
import { updateGl } from '../../redux/actions/gl';
import { getBranches } from '../../redux/actions/branches';

UpdateGL.propTypes = {
  glId: PropTypes.string,
  glData: PropTypes.object
};

export default function UpdateGL({ glId, glData }) {
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.reducer.branchesReducer.branchesList);
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(glData.isApproved);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const UpdateGlSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Account Number is required'),
    accountName: Yup.string().required('Account Name is required'),
    branchId: Yup.string().required('Branch Id is required'),
    isApproved: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: glData.accountnumber,
      accountName: glData.accountname,
      branchId: glData.branchid,
      isApproved: glData.isapproved
    },
    validationSchema: UpdateGlSchema,
    onSubmit: async (values, { resetForm }) => {
      values.isapproved = isChecked;
      const response = await updateGl({ values, dispatch, glId });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  React.useEffect(() => {
    getBranches({
      dispatch
    });
  }, []);

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update system GL Account</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="accountNumber"
                type="text"
                margin="normal"
                label="Account Number"
                {...getFieldProps('accountNumber')}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
              />
              <TextField
                fullWidth
                autoComplete="accountName"
                type="text"
                margin="normal"
                label="Account Name"
                {...getFieldProps('accountName')}
                error={Boolean(touched.accountName && errors.accountName)}
                helperText={touched.accountName && errors.accountName}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('branchId')}
                  name="branchId"
                  label="Branch"
                  error={Boolean(touched.branchId && errors.branchId)}
                  helperText={touched.branchId && errors.branchId}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheck}
                    name="isApproved"
                    color="primary"
                  />
                }
                label="Is Approved?"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
