import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import NotificationMessage from '../../components/notificationMessage';
import IdleTimeOutDialog from '../../components/idleTimeoutHandler';
import { IdleTimer } from '../../components/idleTimeoutHandler/IdleTimer';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  /*
   * This is the idle time handler
   * */
  const [showIdleDialog, setShowIdleDialog] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const idleTimeOut = 1000 * process.env.REACT_APP_TIMEOUT || 10000;
  const promptTimeout = 1000 * process.env.REACT_APP_PROMPT_TIMEOUT || 10000;

  let idleTimer = null;

  const onAction = () => {
    setIsTimedOut(false);
  };
  const onPrompt = () => {
    console.log('ON PROMPT LOG');
  };
  const onActive = () => {
    setIsTimedOut(false);
  };
  const onIdle = () => {
    if (isTimedOut) {
      localStorage.clear();
      navigate('/login', { replace: true });
    } else {
      setShowIdleDialog(true);
      idleTimer.reset();
      setIsTimedOut(true);
    }
  };
  const handleCloseDialog = () => {
    setShowIdleDialog(false);
  };
  const handleIdleLogout = () => {
    setShowIdleDialog(false);
    localStorage.clear();
    navigate('/login', { replace: true });
  };

  return (
    <RootStyle>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        timeout={idleTimeOut}
        promptBeforeIdle={promptTimeout}
        onPrompt={onPrompt}
        onIdle={onIdle}
        onAction={onAction}
        onActive={onActive}
      />
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <NotificationMessage />
      <IdleTimeOutDialog
        showDialog={showIdleDialog}
        handleClose={handleCloseDialog}
        handleLogout={handleIdleLogout}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
