import {
  END_LOADING_MERCHANTS,
  LOAD_MERCHANTS,
  START_LOADING_MERCHANTS
} from '../constants/merchantsConstants';
import { addErrorMessage, addWarningMessage } from './notification';
import { openapiMerchants } from '../../config/openapiMerchants';

const startLoadingMerchants = () => ({
  type: START_LOADING_MERCHANTS
});

const loadMerchants = (payload, searchValue) => ({
  type: LOAD_MERCHANTS,
  data: { payload, searchValue }
});

const endLoadingMerchants = () => ({
  type: END_LOADING_MERCHANTS
});

const getRequestParams = ({ page, pageSize, status, transferType, searchValue }) => {
  const params = {};

  if (status) {
    params.transferStatus = status;
  }
  if (transferType) {
    params.transferType = transferType;
  }
  if (searchValue) {
    params.searchValue = searchValue;
  }
  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }

  return params;
};

// fetch all merchants
export const getMerchants = ({ dispatch, status, page, pageSize, transferType, searchValue }) => {
  dispatch(startLoadingMerchants());
  const params = getRequestParams({
    status,
    page,
    pageSize,
    transferType,
    searchValue
  });
  const url = `transfers`;
  return openapiMerchants
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingMerchants());
      if (!data.data.transfers) {
        dispatch(loadMerchants(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadMerchants(data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingMerchants());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
