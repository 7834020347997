import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { approveUpdatePesalink } from '../../redux/actions/pesalink';

// to check the correct data types and data is passed
ApprovePesalinkUpdate.propTypes = {
  pesalinkId: PropTypes.string,
  pesalinkData: PropTypes.object
};

export default function ApprovePesalinkUpdate({ pesalinkId, pesalinkData }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdatePesalinkSchema = Yup.object().shape({
    bankName: Yup.string().required('Bank name is required'),
    bankAlias: Yup.string().required('Bank alias is required'),
    bankCode: Yup.number().required('Bank code is required')
  });
  const { bankName, bankAlias, bankCode } = pesalinkData.pendingUpdateJson;

  const formik = useFormik({
    initialValues: {
      bankName,
      bankAlias,
      bankCode,
      updatedBy: pesalinkData.updatedBy
    },
    validationSchema: UpdatePesalinkSchema,
    onSubmit: async (values) => {
      values.approvedUpdate = true;
      const response = await approveUpdatePesalink({ values, dispatch, pesalinkId });
      if (response.status === 200) {
        handleClose();
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckBoxIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Approve Update" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Approve Pesalink Update</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <Grid item lg={6}>
                  <Typography color="error">Previous Data</Typography>
                  <TextField
                    disabled
                    margin="normal"
                    id="prevType"
                    label="Bank Name"
                    variant="outlined"
                    defaultValue={pesalinkData.bankName}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevName"
                    label="Bank Alias"
                    variant="outlined"
                    defaultValue={pesalinkData.bankAlias}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    id="prevminAmountAllowed"
                    label="Bank Code"
                    variant="outlined"
                    defaultValue={pesalinkData.bankCode}
                  />
                </Grid>
                <Grid item lg={6}>
                  <Typography color="secondary">Data for approval</Typography>
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="bankName"
                    type="text"
                    margin="normal"
                    label="Bank Name"
                    {...getFieldProps('bankName')}
                    error={Boolean(touched.bankName && errors.bankName)}
                    helperText={touched.bankName && errors.bankName}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="bankAlias"
                    type="text"
                    margin="normal"
                    label="Bank Alias"
                    {...getFieldProps('bankAlias')}
                    error={Boolean(touched.bankAlias && errors.bankAlias)}
                    helperText={touched.bankAlias && errors.bankAlias}
                  />
                  <TextField
                    fullWidth
                    disabled
                    autoComplete="bankCode"
                    type="number"
                    margin="normal"
                    label="Bank Code"
                    {...getFieldProps('bankCode')}
                    error={Boolean(touched.bankCode && errors.bankCode)}
                    helperText={touched.bankCode && errors.bankCode}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Reject</Button>
              <Button type="submit" color="secondary">
                Approve
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
