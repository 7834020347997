import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import AccessControl from '../../AccessControl';
import ViewLoanConfig from '../../loanConfigs/view';
import DeleteLoanConfig from '../../loanConfigs/Delete';
import UpdateLoanConfig from '../../loanConfigs/update';
import ApproveUpdateLoanConfig from '../../loanConfigs/approveUpdate';

LoanMenu.propTypes = {
  loanId: PropTypes.string,
  loanData: PropTypes.object
};

export default function LoanMenu({ loanId, loanData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canupdate">
          <UpdateLoanConfig loanId={loanId} loanData={loanData} />
        </AccessControl>
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canread">
          <ViewLoanConfig loanId={loanId} loanData={loanData} />
        </AccessControl>
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="canapprove">
          {loanData.pendingUpdateJson ? (
            <ApproveUpdateLoanConfig loanId={loanId} loanData={loanData} />
          ) : (
            ''
          )}
        </AccessControl>
        <AccessControl resource="SYSTEM SETTINGS" allowedPermissions="candelete">
          <DeleteLoanConfig loanId={loanId} loanData={loanData} />
        </AccessControl>
      </Menu>
    </>
  );
}
