import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_LOGIN,
  END_LOADING_LOGIN,
  ADD_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  START_ADD_USER,
  END_ADD_USER,
  START_EDIT_USER,
  END_EDIT_USER,
  START_DELETE_USER,
  END_DELETE_USER,
  START_LOADING_USERS,
  LOAD_USERS,
  END_LOADING_USERS,
  RESET_PASSWORD_EMAIL_SENT,
  DELETE_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  START_LOADING_USER_PERMISSIONS,
  LOAD_USER_PERMISSIONS,
  END_LOADING_USER_PERMISSIONS,
  START_PASSWORD_RESET,
  END_PASSWORD_RESET
} from '../constants/userConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';
import { openapi } from '../../config/openapi';
import { getDashboardSummary } from './dashboard';
import { persistor } from '../index';

const startAddUser = () => ({
  type: START_ADD_USER
});

const endAddUser = () => ({
  type: END_ADD_USER
});

const startEditUser = () => ({
  type: START_EDIT_USER
});

const endEditUser = () => ({
  type: END_EDIT_USER
});

const startDeleteUser = () => ({
  type: START_DELETE_USER
});

const endDeleteUser = () => ({
  type: END_DELETE_USER
});

export const startLoadingLogin = () => ({
  type: START_LOADING_LOGIN
});

export const endLoadingLogin = () => ({
  type: END_LOADING_LOGIN
});

const addUserSuccess = () => ({
  type: ADD_USER_SUCCESS
});
const editUserSuccess = () => ({
  type: EDIT_USER_SUCCESS
});
const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS
});
const passResetEmailSent = () => ({
  type: RESET_PASSWORD_EMAIL_SENT
});
const startLoadingReset = () => ({
  type: START_PASSWORD_RESET
});
const endLoadingReset = () => ({
  type: END_PASSWORD_RESET
});
// const passResetSuccess = () => ({
//   type: RESET_PASSWORD_SUCCESS
// });
const startLoadingUserPermissions = () => ({
  type: START_LOADING_USER_PERMISSIONS
});
const loadUserPermissions = (payload, isSuperUser) => ({
  type: LOAD_USER_PERMISSIONS,
  payload,
  isSuperUser
});

const endLoadingUserPermissions = () => ({
  type: END_LOADING_USER_PERMISSIONS
});
const startLoadingUsers = () => ({
  type: START_LOADING_USERS
});

const loadUsers = (payload) => ({
  type: LOAD_USERS,
  payload
});

const endLoadingUsers = () => ({
  type: END_LOADING_USERS
});

const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

const getRequestParams = ({ isApproved, pendingUpdateApproval }) => {
  const params = {};

  if (isApproved) {
    params.isApproved = isApproved;
  }
  if (pendingUpdateApproval) {
    params.pendingUpdateApproval = pendingUpdateApproval;
  }
  return params;
};
// get users list
export const getUsers = ({ dispatch, isApproved, pendingUpdateApproval }) => {
  dispatch(startLoadingUsers());
  const params = getRequestParams({ isApproved, pendingUpdateApproval });
  const url = '/users/all';
  return secureapi
    .get(url, { params })
    .then((data) => {
      dispatch(endLoadingUsers());
      if (data.data.length < 1) {
        dispatch(loadUsers(data.data));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadUsers(data.data));
        dispatch(addSuccessMessage('Successful'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingUsers());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// login the user
export const loginUser = ({ values, dispatch }) => {
  dispatch(startLoadingLogin());
  const url = '/users/auth/login';
  const data = { ...values };
  return (
    openapi
      .post(url, { data })
      // eslint-disable-next-line consistent-return
      .then((res) => {
        dispatch(endLoadingLogin());
        console.log('USER: ', res.data);
        if (res.data.user) {
          localStorage.setItem('userId', res.data.user);
          return res.data;
        }
      })
      .catch(({ message }) => {
        dispatch(endLoadingLogin());
        // eslint-disable-next-line no-unused-expressions
        message.errorMessage
          ? dispatch(addErrorMessage(message.errorMessage))
          : dispatch(addErrorMessage(message));
      })
  );
};

// verify a user is logged in
export const loginVerifyUser = ({ values, dispatch }) => {
  const url = '/users/auth/verify-login';
  const userId = localStorage.getItem('userId');
  const data = { userId, otp: values.authorizationCode };

  return autoLoginUser({ url, data, dispatch });
};

export const updateDefaultPassword = ({ values, dispatch }) => {
  const url = '/users/reset-default-password';
  const userId = localStorage.getItem('userId');
  const data = { userId, password: values.password };

  return autoLoginUser({ url, data, dispatch, isUpdate: true });
};

const autoLoginUser = async ({ url, data, dispatch, isUpdate }) => {
  dispatch(startLoadingLogin());
  try {
    const res = await (isUpdate ? openapi.put(url, data) : openapi.post(url, data));
    dispatch(endLoadingLogin());

    if (res.data.token) {
      processLogin({ dispatch, res });
    }
    return res.data;
  } catch ({ message }) {
    console.log({ message });
    dispatch(endLoadingLogin());
    // eslint-disable-next-line no-unused-expressions
    message.errorMessage
      ? dispatch(addErrorMessage(message.errorMessage))
      : dispatch(addErrorMessage(message));
  }
};

const processLogin = ({ dispatch, res }) => {
  dispatch(loginSuccess(res.data));
  localStorage.setItem('token', res.data.token);
  const roleId = res.data.data.role ? res.data.data.role.id : '';
  const { isSuperUser } = res.data.data;
  getDashboardSummary({ dispatch });
  if (isSuperUser === true) {
    dispatch(loadUserPermissions([], isSuperUser));
  } else getPermissionsByRole({ dispatch, roleId });
  localStorage.setItem('profile', JSON.stringify(res.data.data));
  dispatch(addSuccessMessage('Login Successful!'));
};

// Logout the user
export const logoutUser = ({ dispatch, token }) => {
  dispatch(startLoadingLogin());
  const url = '/users/auth/logout';
  return (
    openapi
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      // eslint-disable-next-line consistent-return
      .then(() => {
        dispatch(logoutSuccess());
        dispatch(endLoadingLogin());
        localStorage.clear();
        persistor.purge().then(() => console.log('purged'));
        dispatch(addSuccessMessage('Logout Successful!'));
      })
      .catch(({ message }) => {
        dispatch(endLoadingLogin());
        // eslint-disable-next-line no-unused-expressions
        message.errorMessage
          ? dispatch(addErrorMessage(message.errorMessage))
          : dispatch(addErrorMessage(message));
      })
  );
};

// adds a new user
export const addNewUser = ({ values, dispatch }) => {
  dispatch(startAddUser());
  const url = '/users/create';
  const createdBy = JSON.parse(localStorage.getItem('profile')).id;
  if (values.roleId === '') values.roleId = null;
  const data = { ...values, isApproved: false, creatorId: createdBy };
  return secureapi
    .post(url, data)
    .then((data) => {
      dispatch(endAddUser());
      dispatch(addSuccessMessage(`User added successfully`));
      dispatch(addUserSuccess());
      getUsers({
        dispatch,
        isApproved: 'true'
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endAddUser());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing user
export const updateUser = ({ values, dispatch, id, activeUsers }) => {
  dispatch(startEditUser());
  values.updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/users/update/${id}`;
  console.log('VALUES: ', values);
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditUser());
      dispatch(addSuccessMessage(`User updated pending approval`));
      dispatch(editUserSuccess());
      if (activeUsers) {
        getUsers({
          dispatch,
          isApproved: 'true'
        });
      } else {
        getUsers({
          dispatch,
          isApproved: 'false'
        });
      }
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditUser());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing user
export const approveUpdateUser = ({ values, dispatch, id }) => {
  dispatch(startEditUser());
  const approvedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/users/approve/update/${id}`;
  values.lastApprovedBy = approvedBy;
  return secureapi
    .put(url, values)
    .then((data) => {
      dispatch(endEditUser());
      dispatch(addSuccessMessage(`User update approved successfully`));
      dispatch(editUserSuccess());
      getUsers({
        dispatch,
        pendingUpdateApproval: 'true'
      });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditUser());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
export const deleteUser = ({ dispatch, id }) => {
  dispatch(startDeleteUser());
  const deletedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `/users/delete/${deletedBy}/${id}`;
  return secureapi
    .delete(url)
    .then(() => {
      dispatch(endDeleteUser());
      dispatch(addSuccessMessage(`User Deleted successfully`));
      dispatch(deleteUserSuccess());
      getUsers({
        dispatch,
        isApproved: 'true'
      });
    })
    .catch(({ message }) => {
      dispatch(endDeleteUser());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// request reset password
export const requestPasswordReset = ({ id, dispatch }) => {
  dispatch(startLoadingReset());

  const url = `/users/request-reset-password/${id}`;

  return secureapi
    .get(url)
    .then((res) => {
      dispatch(endLoadingReset());
      if (res.status === 200) {
        dispatch(passResetEmailSent());
        dispatch(addSuccessMessage('Reset email sent successfully.'));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingReset());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
// reset password
export const resetPassword = ({ dispatch, values, userId }) => {
  dispatch(startLoadingReset());
  delete values.confirmPassword;
  const data = {
    ...values
  };
  const url = `/users/reset-password/${userId}`;
  return openapi
    .put(url, data)
    .then((res) => {
      dispatch(startLoadingReset());
      if (res.status === 201) {
        dispatch(addSuccessMessage('Password Updated Successfully.'));
      }
      return res;
    })
    .catch(({ message }) => {
      dispatch(endLoadingReset());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
// // change password
// export const changePassword =
//   (secureapi, currentPassword, newPassword, confirmPassword) => (dispatch) => {
//     dispatch(startLoading());
//     const data = {
//       currentPassword,
//       newPassword,
//       confirmPassword
//     };
//
//     const url = '/account/change-password';
//
//     return secureapi
//       .post(url, { data })
//       .then((res) => {
//         dispatch(endLoading());
//         if (res.status === 200) {
//           dispatch(addSuccessMessage('Your password has been changed'));
//         }
//       })
//       .catch(({ message }) => {
//         dispatch(endLoading());
//         // eslint-disable-next-line no-unused-expressions
//         message.errorMessage
//           ? dispatch(addErrorMessage(message.errorMessage))
//           : dispatch(addErrorMessage(message));
//       });
//   };
//
// // set new password
// export const newPassword = (openapi, resetToken, newPassword, confirmPassword) => (dispatch) => {
//   dispatch(startLoading());
//
//   const data = {
//     newPassword,
//     confirmPassword
//   };
//
//   const url = `/auth/reset-password/${resetToken}`;
//
//   return openapi
//     .post(url, { data })
//     .then((res) => {
//       dispatch(endLoading());
//       if (res.status === 200) {
//         dispatch(passResetSuccess());
//         dispatch(addSuccessMessage('Password reset is successful, please login'));
//       }
//     })
//     .catch(({ message }) => {
//       dispatch(endLoading());
//       // eslint-disable-next-line no-unused-expressions
//       message.errorMessage
//         ? dispatch(addErrorMessage(message.errorMessage))
//         : dispatch(addErrorMessage(message));
//     });
// };

// get user role permissions
export const getPermissionsByRole = ({ dispatch, roleId, isSuperUser }) => {
  dispatch(startLoadingUserPermissions());
  const url = `/permissions/role/${roleId}`;
  return secureapi
    .get(url)
    .then((res) => {
      dispatch(loadUserPermissions(res.data, isSuperUser));
      dispatch(endLoadingUserPermissions());
    })
    .catch(({ message }) => {
      dispatch(endLoadingUserPermissions());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
