import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function AppMpesaCellulantAmount() {
  const theme = useTheme();
  const successMpesaTransactionReport = useSelector(
    (state) => state.reducer.dashboardReducer.successMpesaTransactionReport
  );
  const failedMpesaTransactionReport = useSelector(
    (state) => state.reducer.dashboardReducer.failedMpesaTransactionReport
  );
  const Categories = successMpesaTransactionReport.map((txn) => txn.key);
  const successData = successMpesaTransactionReport.map((txn) => (txn.sum ? txn.sum : 0));
  const failedData = failedMpesaTransactionReport.map((txn) => (txn.sum ? txn.sum : 0));
  const seriesData = [
    { name: 'SUCCESS', data: successData },
    { name: 'FAILED', data: failedData }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      y: {
        formatter(val) {
          return `Ksh.${fCurrency(val)}`;
        }
      }
    },
    chart: {
      stacked: true
    },
    plotOptions: {
      bar: { horizontal: false, barHeight: '30%', borderRadius: 2 }
    },
    xaxis: {
      categories: Categories
    },
    colors: [theme.palette.info.main, theme.palette.error.main]
  });

  return (
    <Card>
      <CardHeader title="Mpesa & Cellulant Transaction Report By Amount" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={seriesData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
