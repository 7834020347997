import { useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { AntTabs, AntTab, TabPanel } from '../components/CustomTab';
//
import Roles from '../components/roles';
import Resources from '../components/resources';
import Permissions from '../components/permissions';
// ----------------------------------------------------------------------

export default function AccessManagement() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Access Management | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Access Management
          </Typography>
        </Stack>

        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Roles" />
          <AntTab label="Resources" />
          <AntTab label="Permissions" />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <Roles />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Resources />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Permissions />
        </TabPanel>
      </Container>
    </Page>
  );
}
