import { useRef, useState } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// material
import { Menu, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import UpdatePesalink from '../../pesalink/update';
import DeletePesalink from '../../pesalink/delete';
import ChangePesalinkStatus from '../../pesalink/changePesalinkStatus';
import ApprovePesalinkUpdate from '../../pesalink/approvePesalinkUpdate';
import AccessControl from '../../AccessControl';

// ----------------------------------------------------------------------
PesalinkMoreMenu.propTypes = {
  pesalinkId: PropTypes.string,
  pesalinkData: PropTypes.object
};

const sessionUser = JSON.parse(localStorage.getItem('profile'))?.id;

export default function PesalinkMoreMenu({ pesalinkId, pesalinkData }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AccessControl resource="PESALINK" allowedPermissions="canupdate">
          <UpdatePesalink pesalinkId={pesalinkId} pesalinkData={pesalinkData} />
          {pesalinkData.isApproved || pesalinkData.createdBy === sessionUser ? (
            ''
          ) : (
            <ChangePesalinkStatus pesalinkId={pesalinkId} pesalinkData={pesalinkData} />
          )}
          <DeletePesalink pesalinkId={pesalinkId} pesalinkData={pesalinkData} />
        </AccessControl>
        {pesalinkData.pendingUpdateJson !== null && pesalinkData.createdBy !== sessionUser ? (
          <AccessControl resource="PESALINK" allowedPermissions="canexecute">
            <ApprovePesalinkUpdate pesalinkId={pesalinkId} pesalinkData={pesalinkData} />
          </AccessControl>
        ) : (
          ''
        )}
      </Menu>
    </>
  );
}
