import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { addNewCharge, getChargeGroups } from '../../redux/actions/charges';

export default function FormDialog() {
  const dispatch = useDispatch();
  const chargeGroups = useSelector((state) => state.reducer.chargesReducer.chargeGroupList);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CreateRoleSchema = Yup.object().shape({
    chargeGroupId: Yup.string().required('Charge group is required'),
    amountFrom: Yup.string().required('Amount from is required'),
    amountTo: Yup.string().required('Amount to is required'),
    saccoCharge: Yup.string().required('Sacco charge is required'),
    exciseDuty: Yup.string().required('Excise duty is required'),
    providerCommission: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      chargeGroupId: '',
      amountFrom: '',
      amountTo: '',
      saccoCharge: '',
      exciseDuty: '',
      providerCommission: ''
    },
    validationSchema: CreateRoleSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await addNewCharge({ values, dispatch });
      if (response.status === 201) {
        resetForm();
        handleClose();
      }
    }
  });

  useEffect(() => {
    getChargeGroups({
      dispatch
    });
  }, []);

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<AddOutlinedIcon />}>
        New Charge
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Create a new Charge</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Charge Group</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps('chargeGroupId')}
                  name="chargeGroupId"
                  label="Charge Group"
                  error={Boolean(touched.chargeGroupId && errors.chargeGroupId)}
                  helperText={touched.chargeGroupId && errors.chargeGroupId}
                >
                  {chargeGroups.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                autoComplete="amountFrom"
                type="text"
                margin="normal"
                label="Amount From"
                {...getFieldProps('amountFrom')}
                error={Boolean(touched.amountFrom && errors.amountFrom)}
                helperText={touched.amountFrom && errors.amountFrom}
              />
              <TextField
                fullWidth
                autoComplete="amountTo"
                type="text"
                margin="normal"
                label="Amount To"
                {...getFieldProps('amountTo')}
                error={Boolean(touched.amountTo && errors.amountTo)}
                helperText={touched.amountTo && errors.amountTo}
              />
              <TextField
                fullWidth
                autoComplete="saccoCharge"
                type="text"
                margin="normal"
                label="Sacco Charge"
                {...getFieldProps('saccoCharge')}
                error={Boolean(touched.saccoCharge && errors.saccoCharge)}
                helperText={touched.saccoCharge && errors.saccoCharge}
              />
              <TextField
                fullWidth
                autoComplete="exciseDuty"
                type="text"
                margin="normal"
                label="Excise Duty"
                {...getFieldProps('exciseDuty')}
                error={Boolean(touched.exciseDuty && errors.exciseDuty)}
                helperText={touched.exciseDuty && errors.exciseDuty}
              />
              <TextField
                fullWidth
                autoComplete="providerCommission"
                type="text"
                margin="normal"
                label="Provider Commission"
                {...getFieldProps('providerCommission')}
                error={Boolean(touched.providerCommission && errors.providerCommission)}
                helperText={touched.providerCommission && errors.providerCommission}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
