import { secureapi } from '../../config/secureapi';
import {
  START_LOADING_ADVANCES,
  LOAD_ADVANCES,
  END_LOADING_ADVANCES,
  START_EDIT_ADVANCE,
  EDIT_ADVANCE_SUCCESS,
  END_EDIT_ADVANCE
} from '../constants/salaryAdvanceConstants';
import { addErrorMessage, addSuccessMessage, addWarningMessage } from './notification';

const startLoadingAdvances = () => ({
  type: START_LOADING_ADVANCES
});

const loadAdvances = (payload) => ({
  type: LOAD_ADVANCES,
  payload
});

const endLoadingAdvances = () => ({
  type: END_LOADING_ADVANCES
});

const startEditAdvance = () => ({
  type: START_EDIT_ADVANCE
});

const editAdvanceSuccess = () => ({
  type: EDIT_ADVANCE_SUCCESS
});

const endEditAdvance = () => ({
  type: END_EDIT_ADVANCE
});

const getRequestParams = ({ page, type, pageSize, status, searchValue }) => {
  const params = {};

  if (status) {
    params.status = status;
  }

  if (searchValue) {
    params.searchValue = searchValue;
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }

  if (type) {
    params.type = type;
  }

  return params;
};

// fetch all advance loans
export const getSalaryAdvances = ({ dispatch, status, page, pageSize, searchValue, type }) => {
  dispatch(startLoadingAdvances());
  const params = getRequestParams({
    status,
    page,
    pageSize,
    searchValue,
    type
  });
  const url = '/api/v1/mobile/loan';
  secureapi
    .get(url, { params })
    .then((data) => {
      console.log('ADVANCED SALARY LOANS >>> ', data?.data.response.data);
      dispatch(endLoadingAdvances());
      if (!data.data.response.data) {
        dispatch(loadAdvances(data.data.response && data.data.response));
        dispatch(addWarningMessage('No results found.'));
      } else {
        dispatch(loadAdvances(data.data.response && data.data.response));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingAdvances());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};

// update existing advance loan
export const updateAdvanceLoan = async ({ values, dispatch, loanId }) => {
  dispatch(startEditAdvance());
  const updatedBy = JSON.parse(localStorage.getItem('profile')).id;
  const url = `http://localhost:3001/advance_loans/${loanId}`;
  values.updatedBy = updatedBy;
  return secureapi
    .patch(url, values)
    .then((data) => {
      dispatch(endEditAdvance());
      dispatch(addSuccessMessage('Loan updated pending Approval'));
      dispatch(editAdvanceSuccess());
      getSalaryAdvances({ dispatch, page: 1, pageSize: 10, isSuspended: 'false' });
      return data;
    })
    .catch(({ message }) => {
      dispatch(endEditAdvance());
      // eslint-disable-next-line no-unused-expressions
      message.errorMessage
        ? dispatch(addErrorMessage(message.errorMessage))
        : dispatch(addErrorMessage(message));
    });
};
