import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

TimeoutDialog.propTypes = {
  handleClose: PropTypes.func,
  handleLogout: PropTypes.func,
  showDialog: PropTypes.bool
};

export default function TimeoutDialog({ showDialog, handleClose, handleLogout }) {
  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">You have been Idle!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will get logged out. You want to stay?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} variant="outlined" color="error">
            Logout
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
