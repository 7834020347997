import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { requestPasswordReset } from '../../redux/actions/user';

RequestPasswordResetForm.propTypes = {
  userData: PropTypes.object,
  id: PropTypes.string
};

export default function RequestPasswordResetForm({ id, userData }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ResetPasswordSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: userData.firstName,
      lastName: userData.lastName
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await requestPasswordReset({ dispatch, id });
      if (response.status === 200) {
        resetForm();
        handleClose();
      }
    }
  });
  const { handleSubmit } = formik;
  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Reset Password" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Request user's password reset</DialogTitle>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>
                User
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  {` ${sentenceCase(userData.firstName)} ${sentenceCase(userData.lastName)}'s`}
                </span>{' '}
                password will be reset.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="error">
                Reset
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
