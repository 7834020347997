import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fDate } from '../../utils/formatTime';

ViewLoan.propTypes = {
  loanData: PropTypes.object,
  updatedBy: PropTypes.string
};

const divContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
};

const div = {
  display: 'flex',
  marginLeft: '1rem',
  justifyContent: 'flexStart',
  alignItems: 'center',
  padding: '1rem 2rem',
  marginBottom: '2rem'
};

const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

export default function ViewLoan({ loanData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: 'text.secondary' }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={container}
        onBackdropClick
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ textAlign: 'center', textDecoration: 'underline' }}>
          View Loan
        </DialogTitle>
        <DialogContent>
          <div style={divContainer}>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Phone Number:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.recipientPhone}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Principal Amount:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.principalAmount}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Interest:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.interest}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Amount Paid:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.amountRepaid}
              </p>
            </Paper>
          </div>
          <div style={divContainer}>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Expected Repayment Date:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {fDate(loanData.expectedRepaymentDate)}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Last Repaid:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {fDate(loanData.lastRepaid)}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Overdue:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.isOverdue ? 'Yes' : 'No'}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Penalty Amount:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.penaltyAmount}
              </p>
            </Paper>
          </div>
          <div style={divContainer}>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Status:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.status}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Created At:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {fDate(loanData.createdAt)}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Updated By:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.updatedBy}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Updated At:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {fDate(loanData.updatedAt)}
              </p>
            </Paper>
          </div>
          <div style={divContainer}>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Deleted:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.isDeleted ? 'Yes' : 'No'}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Deleted By:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.isDeleted ? loanData.deletedBy : ''}
              </p>
            </Paper>
            <Paper elevation={8} style={div}>
              <Typography sx={{ fontWeight: 'bold' }}>Deleted At:</Typography>
              <p className="nationalId" style={{ paddingLeft: '1rem' }}>
                {loanData.deletedAt}
              </p>
            </Paper>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
