import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box
} from '@mui/material';

// components
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ConfigsListHead,
  ConfigsListToolbar,
  ConfigsMoreMenu
} from '../components/_dashboard/configs';

//
import EmptyState from '../components/EmptyState';
import { fDate } from '../utils/formatTime';
import { getConfigs } from '../redux/actions/configs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'configName', label: 'Config Name', alignRight: false },
  { id: 'value', label: 'Value', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

const LinearProgressStyle = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(30),
  maxWidth: '20%',
  margin: 'auto'
}));
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_config) => _config.configName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ConfigsManagement() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('configName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.reducer.configsReducer.isLoadingConfigs);
  const configs = useSelector((state) => state.reducer.configsReducer.configsList);
  const totalConfigs = useSelector((state) => state.reducer.configsReducer.count);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = configs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getConfigs({
      dispatch,
      page: newPage + 1,
      pageSize: rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getConfigs({
      dispatch,
      page: page + 1,
      pageSize: parseInt(event.target.value, 10)
    });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredConfigs = configs
    ? applySortFilter(configs, getComparator(order, orderBy), filterName)
    : 0;

  const isUserNotFound = filteredConfigs.length === 0;
  useEffect(() => {
    getConfigs({
      dispatch,
      page: 1,
      pageSize: rowsPerPage
    });
  }, []);

  return (
    <Page title="Configurations | Fortune Sacco">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Configurations Management
          </Typography>
        </Stack>

        <ConfigsListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <LinearProgressStyle />
        ) : configs && configs.length < 1 ? (
          <EmptyState message="No configs available" />
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ConfigsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={configs.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredConfigs.map((row) => {
                      const { id, configName, isActive, value, createdAt } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell align="left">{configName}</TableCell>
                          <TableCell align="left">
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {Object.keys(
                                  typeof value === 'string' ? JSON.parse(value) : value
                                ).map((key) => (
                                  <Typography
                                    key={key}
                                    sx={{ fontWeight: 'bold' }}
                                  >{`${key}: `}</Typography>
                                ))}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginLeft: '0.8rem'
                                }}
                              >
                                {Object.values(
                                  typeof value === 'string' ? JSON.parse(value) : value
                                ).map((val) => (
                                  <Typography key={val}>{val}</Typography>
                                ))}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            <Typography color={isActive ? 'secondary' : 'error'}>
                              {isActive ? 'Active' : 'Inactive'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{createdAt && fDate(createdAt)}</TableCell>
                          <TableCell align="right">
                            <ConfigsMoreMenu configId={id} configData={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalConfigs}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
