import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSupport } from '../../../redux/actions/support';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

SupportListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

const GROUP = [
  { value: 'QUESTION', label: 'QUESTION' },
  { value: 'PROBLEM', label: 'PROBLEM' },
  { value: 'REQUEST', label: 'REQUEST' }
];

export default function SupportListToolbar({ numSelected, filterName, onFilterName }) {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState('');
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    getSupport({ dispatch, ticketGroup: event.target.value, page: 1, pageSize: 10 });
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search by phone or name..."
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}
      <FormControl size="small" sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Group</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGroup}
          label="Item"
          onChange={handleGroupChange}
        >
          {GROUP.map((group) => (
            <MenuItem value={group.value} key={group.value}>
              {group.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>{/* <FilterListOutlinedIcon /> */}</IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
